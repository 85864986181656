import React, { useState, useEffect } from "react";
import { Table, Button, Checkbox, Container, Message } from "semantic-ui-react";
import { fetchActiveAspirant, submitAttendance } from "./api"; // Import the API function
import ScrollView from "../ScrollView";
import { getCurrentDate, toSentenceCase } from "../utils";
import LoaderComponent from "../LoaderComponent";

export default function TakingAttendance() {
  const [students, setStudents] = useState([]);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null); // New state for success message
  const [attendance, setAttendance] = useState({});
  const [loading, setLoading] = useState(true); // New state for loading

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetchActiveAspirant();
        const sortedStudents = response.sort((a, b) => {
          const rollNumberA = parseInt(a.roll_number.replace(/\D/g, ""), 10);
          const rollNumberB = parseInt(b.roll_number.replace(/\D/g, ""), 10);
          return rollNumberA - rollNumberB;
        });
        setStudents(sortedStudents);
        const initialAttendance = sortedStudents.reduce((acc, student) => {
          acc[student.id] = true;
          return acc;
        }, {});
        setAttendance(initialAttendance);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleToggle = (id) => {
    setAttendance((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const handleDismiss = (type) => {
    if (type === "error") {
      setError(null);
    } else if (type === "success") {
      setSuccess(null);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    setSuccess(null); // Reset success message on new submission
    setError(null); // Reset error message on new submission

    const presentIds = Object.keys(attendance).filter((id) => attendance[id]);
    const reqBody = {
      present: presentIds,
      date_of_attendance: new Date().toISOString().split("T")[0], // Format date as "YYYY-MM-DD"
    };
    try {
      const response = await submitAttendance(reqBody);
      if (response.ok) {
        console.log("Attendance submitted successfully");
        setSuccess("Attendance submitted successfully!"); // Set success message
      } else {
        const errorData = await response.json();
        console.error("Failed to submit attendance:", errorData);
        setError(errorData.date_of_attendance[0]);
      }
    } catch (error) {
      console.error("Error submitting attendance:", error);
      setError("An unexpected error occurred.");
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <LoaderComponent loadingText={"Loading..."} />;
  }

  return (
    <>
      <Container>
        <Container
          style={{ paddingRight: "0.75rem", paddingBottom: "0.5rem" }}
          textAlign="right"
        >
          Date: {getCurrentDate(true)}
        </Container>
        <ScrollView padding="0 0.75rem" maxHeight="60vh">
          <Table celled compact>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={2}>Roll Number</Table.HeaderCell>
                <Table.HeaderCell width={13}>Name</Table.HeaderCell>
                <Table.HeaderCell width={1}>Present</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {students.map((student) => (
                <Table.Row key={student.id}>
                  <Table.Cell>{student.roll_number}</Table.Cell>
                  <Table.Cell>{student.aspirant_name}</Table.Cell>
                  <Table.Cell>
                    <Checkbox
                      toggle
                      checked={attendance[student.id]}
                      onChange={() => handleToggle(student.id)}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </ScrollView>
        {error && (
          <Message
            error
            onDismiss={() => handleDismiss("error")}
            style={{ marginLeft: "0.75rem", marginRight: "0.75rem" }}
          >
            <Message.Header>Error</Message.Header>
            <p>{toSentenceCase(error)}</p>
          </Message>
        )}
        {success && (
          <Message
            success
            onDismiss={() => handleDismiss("success")}
            style={{ marginLeft: "0.75rem", marginRight: "0.75rem" }}
          >
            <Message.Header>Success</Message.Header>
            <p>{success}</p>
          </Message>
        )}
        <Button
          floated="right"
          style={{ marginTop: "1rem", marginLeft: "0.75rem" }}
          onClick={handleSubmit}
          primary
        >
          Submit Attendance
        </Button>
      </Container>
    </>
  );
}
