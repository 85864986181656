import React, { useState } from "react";
import { Button, Modal, Checkbox, Icon, Message } from "semantic-ui-react";
import { toggleCasesheet } from "./api";

const ToggleCasesheetBtn = ({ close, counselee_id, caseSheetId }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  const handleCheckboxChange = (e, { checked }) => {
    setCheckboxChecked(checked);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await toggleCasesheet(close, counselee_id, caseSheetId);
      setSuccess(true);
      setLoading(false);
      setTimeout(() => {
        window.location.reload();
      }, 2000); // Refresh the page after 2 seconds
    } catch (err) {
      setError("Failed to close the case sheet. Please try again.");
      setLoading(false);
    }
  };

  return (
    <>
      <Button color="red" onClick={handleOpen}>
        Close Case Sheet
      </Button>

      <Modal open={modalOpen} onClose={handleClose} size="small">
        <Modal.Header>Warning</Modal.Header>
        <Modal.Content>
          <p>
            After closing the case sheet, you will no longer be able to add data
            to it. Are you sure you want to proceed?
          </p>
          <Checkbox
            label="I understand and want to close the case sheet."
            onChange={handleCheckboxChange}
          />
          <br />
          {success && (
            <Message
              success
              header="Case Sheet Closed"
              content="The case sheet was successfully closed. The page will refresh shortly."
            />
          )}

          {error && <Message error header="Error" content={error} />}
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={handleClose} disabled={loading}>
            <Icon name="cancel" /> Cancel
          </Button>
          <Button
            color="red"
            onClick={handleSubmit}
            disabled={!checkboxChecked || loading}
            loading={loading}
          >
            <Icon name="check" /> Close Case Sheet
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default ToggleCasesheetBtn;
