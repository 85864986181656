import React, { useState, useEffect } from "react";
import { Table, Loader, Icon, Message } from "semantic-ui-react";
import { getOtInboxMessages, markNotificationAsRead } from "../api";
import { Link } from "react-router-dom";
import { formatDate, cropTitle, cropGp } from "../utils";

const HomePageNotification = () => {
  const [messages, setMessages] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const toURL = "/notifications";
  const toUrlState = (message) => ({ selectedMessage: message });

  const cardColors = [
    "#D1CCE6aa",
    "#F7D1C5aa",
    "#B7DFF9aa",
    "#F7D8DCaa",
    "#E8B5B8aa",
    "#EEDDF4aa",
    "#B8CDEBaa",
    "#F9C3A6aa",
    "#D4D4D4aa",
  ];

  useEffect(() => {
    const personId = localStorage.getItem("person_id");
    const fetchMessages = async (id) => {
      setLoading(true);
      try {
        const response = await getOtInboxMessages(id);
        const data = await response.json();
        setMessages(data.slice(0, 7)); // Limit to 6 notifications
      } catch (error) {
        console.log("Failed to fetch inbox list: ", error);
      } finally {
        setLoading(false);
      }
    };

    if (personId) {
      fetchMessages(personId);
    } else {
      console.log("State/State.person_id not available");
    }
  }, []);

  const handleCardClick = async (message) => {
    const personId = localStorage.getItem("person_id");
    if (!message.is_read) {
      try {
        await markNotificationAsRead(personId, message.note_id);
        setMessages((prevMessages) =>
          prevMessages.map((msg) =>
            msg.note_id === message.note_id ? { ...msg, is_read: true } : msg
          )
        );
      } catch (error) {
        console.log("Failed to mark notification as read: ", error);
      }
    }
  };

  return (
    <div>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "40vh",
          }}
        >
          <Loader active inline="centered" />
        </div>
      ) : messages.length > 0 ? (
        <Table fixed selectable unstackable singleLine>
          <Table.Body>
            {messages.map((message, index) => {
              const isRead = message.is_read;
              const textBoldClass =
                !isRead && "Inbox" ? "blackLink textBold" : "blackLink";
              return (
                <Table.Row
                  key={message.note_id}
                  onClick={() => handleCardClick(message)}
                  style={{
                    backgroundColor: cardColors[index % cardColors.length],
                    cursor: "pointer",
                  }}
                >
                  <Table.Cell
                    style={{ paddingTop: "0.2rem", paddingBottom: "0.2rem" }}
                    width={1}
                  >
                    <Link to={toURL} state={toUrlState(message)}>
                      {isRead ? (
                        <Icon name="envelope open" color="grey" />
                      ) : (
                        <Icon name="envelope" color="blue" />
                      )}
                    </Link>
                  </Table.Cell>
                  <Table.Cell
                    style={{ paddingTop: "0.2rem", paddingBottom: "0.2rem" }}
                  >
                    <Link to={toURL} state={toUrlState(message)}>
                      <div className={textBoldClass}>
                        {cropGp(message.group.name, false)}
                      </div>
                    </Link>
                  </Table.Cell>
                  {message.sender_name && (
                    <Table.Cell
                      style={{ paddingTop: "0.2rem", paddingBottom: "0.2rem" }}
                    >
                      <Link to={toURL} state={toUrlState(message)}>
                        <div className={textBoldClass}>
                          {cropGp(message.sender_name)}
                        </div>
                      </Link>
                    </Table.Cell>
                  )}
                  <Table.Cell
                    style={{ paddingTop: "0.2rem", paddingBottom: "0.2rem" }}
                  >
                    <Link to={toURL} state={toUrlState(message)}>
                      <div className={textBoldClass}>
                        {cropTitle(message.title)}
                      </div>
                    </Link>
                  </Table.Cell>
                  <Table.Cell
                    textAlign="right"
                    style={{ paddingTop: "0.2rem", paddingBottom: "0.2rem" }}
                  >
                    <Link to={toURL} state={toUrlState(message)}>
                      <div className={textBoldClass}>
                        {formatDate(message.time_of_sending)}
                      </div>
                    </Link>
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      ) : (
        <Message
          info
          header="No Notifications"
          content="There are no notifications available at this time."
        />
      )}
    </div>
  );
};

export default HomePageNotification;
