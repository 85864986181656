import React, { useState } from "react";
import NavbarWrapper from "../Components/Template/NavbarWrapper";
import { Header, Menu, Segment } from "semantic-ui-react";
import LastLoginLogs from "../Components/LastLoginLogs";
import { isUserAdmin } from "../Components/api";
import { useNavigate } from "react-router-dom";
import AdminNotes from "../Components/AdminNotes";

export default function Admin() {
  const [activeItem, setActiveItem] = useState("Usage Logs");
  const handleItemClick = (name) => setActiveItem(name);
  const navigate = useNavigate();
  !isUserAdmin() && navigate("/");
  const headerStyle = { fontFamily: "Times New Roman, Georgia, serif" };

  return (
    <NavbarWrapper containerFluid={true}>
      <Header as={"h1"} style={headerStyle}>
        Admin Panel
      </Header>
      <Menu pointing>
        <Menu.Item
          name="Usage Logs"
          active={activeItem === "Usage Logs"}
          onClick={() => handleItemClick("Usage Logs")}
        />
        <Menu.Item
          name="All Notifications"
          active={activeItem === "All Notifications"}
          onClick={() => handleItemClick("All Notifications")}
        />
      </Menu>

      {activeItem === "Usage Logs" && (
          <LastLoginLogs />
      )}
      {activeItem === "All Notifications" && (
          <AdminNotes />
      )}
    </NavbarWrapper>
  );
}
