import { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { fetchInstituteDetails } from "../api";
import LoaderComponent from "../LoaderComponent";
import DepartmentsList from "../departmentsList";
import {
  Container,
  Segment,
  Header,
  Divider,
  Icon,
  Image,
} from "semantic-ui-react";
import { formatResponseDataKey } from "../utils";
import FacultyList from "../FacultyList";
import { useAuth } from "../../Context/AuthContext";
import Navbar from "../Navbar";
import SearchModal from "../Modals/SearchModal";
import NavbarWithoutLogin from "../WithoutLogin/NavbarWithoutLogin";

export default function InstituteDetails(props) {
  const { state } = useLocation();
  const loggedIn = localStorage.getItem("isLoggedIn") === "true";
  const [instituteDetails, setDetails] = useState();
  const [isLoading, setLoading] = useState(false);

  const { instituteid } = useParams();
  const { logout } = useAuth();
  const [isModalOpen, setModalOpen] = useState(false);
  const closeModal = () => setModalOpen(false);

  useEffect(() => {
    // console.log(state);
    let instituteId = -1;
    if (state && state.instituteId) {
      instituteId = state.instituteId;
    } else if (instituteid) {
      instituteId = instituteid;
    }
    setLoading(true);
    // make API call to fetch institute data
    fetchInstituteDetails(instituteId)
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          console.log(data);
          setDetails(data);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error("Failed to fetch institute details: ", err);
        setLoading(false);
      });
  }, [instituteid, state]);

  const renderInstituteDetails = () => {
    if (instituteDetails) {
      return (
        <Segment basic>
          {instituteDetails["logo_photo"] ? (
            <div
              style={{
                // border: "1px solid brown",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Image
                src={
                  instituteDetails["logo_photo"]
                    ? instituteDetails["logo_photo"]
                    : "https://react.semantic-ui.com/images/wireframe/image.png"
                }
                // wrapped
                size="medium"
                // style={{
                //   border: "2px solid green",
                // }}
                // ui={false}
              />
            </div>
          ) : null}
          <Divider hidden />
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {Object.keys(instituteDetails).map((key) => {
              if (
                key === "id" ||
                key === "name" ||
                key === "parent" ||
                key === "departments" ||
                key === "logo_photo"
              )
                return null; // don't show id or courses data
              if (key === "faculties") {
                return (
                  <p key={key} style={{ paddingRight: "1rem" }}>
                    <strong>{formatResponseDataKey(key)}</strong> -{" "}
                    <span>
                      {instituteDetails[key] && instituteDetails[key]["name"]
                        ? instituteDetails[key]["name"]
                        : "Not Available "}
                    </span>
                  </p>
                );
              }
              // console.log(key, formatResponseDataKey(key), instituteDetails[key])
              return (
                <p key={key}>
                  <strong>{formatResponseDataKey(key)}</strong> -{" "}
                  <span>
                    {instituteDetails[key]
                      ? instituteDetails[key]
                      : "Not Available "}
                  </span>
                </p>
              );
            })}
          </div>
        </Segment>
      );
    } else return <h3>Institute data not found</h3>;
  };

  if (isLoading) {
    return <LoaderComponent loadingText="Loading institute details ... " />;
  }
  return (
    <>
      {loggedIn ? (
        <Navbar onLogout={logout} setModalOpen2={setModalOpen} />
      ) : (
        <NavbarWithoutLogin />
      )}
      <SearchModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <Container>
        <div style={{ paddingTop: "90px" }}>
          <Segment basic padded>
            <Header as="h1" className="heading-font">
              <Icon name="university" />
              <Header.Content>
                {instituteDetails && instituteDetails.name
                  ? instituteDetails.name + " details"
                  : "Institute details"}
              </Header.Content>
            </Header>
            <Divider />

            {renderInstituteDetails()}
            {instituteDetails &&
            instituteDetails.faculties &&
            instituteDetails.faculties.length > 0 ? (
              <FacultyList list={instituteDetails.faculties} />
            ) : null}
          </Segment>
        </div>
      </Container>
    </>
  );
}
