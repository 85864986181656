import {
    Form,
    Input,
    Dropdown,
    TextArea,
    Icon,
    Label,
    Search,
  } from "semantic-ui-react";
  
  import { fetchHostelRooms } from "./api";
  
  export default function   SearchForm({ formState, setFormState, cols }) {
    const handleChange = (e, { name, value }) => {
      if (name === "hostel") {
        handleHostelNameDropdown(e, { name, value });
      }
      if (e.target.type === "file") {
        setFormState((prevState) => {
          return {
            ...prevState,
            [name]: {
              ...prevState[name],
              value: e.target.files[0],
              isFilled: e.target.files[0] ? true : false,
              isDirty: true,
            },
          };
        });
      } else {
        setFormState((prevState) => {
          return {
            ...prevState,
            [name]: {
              ...prevState[name],
              value: value,
            },
          };
        });
      }
    };
  
    const handleHostelNameDropdown = (e, { name, value }) => {
      // Then we need to check if the dropdown is cleared or filled
      if (value === "") {
        // Means that the hostel dropdown is cleared, hence, we need to disable
        // the room number dropdown
        setFormState((prevState) => {
          return {
            ...prevState,
            room_number: {
              ...prevState.room_number,
              value: "",
              disabled: true,
            },
          };
        });
      } else {
        // We can enable the room number dropdown by filling the dropdown with values
        // First, get the hostel id for the selected hostel name
        const selectedHostelData = formState.hostel.options.filter(
          (option) => option.value === value
        );
        if (selectedHostelData && selectedHostelData.length > 0) {
          enableRoomNoDropdown(selectedHostelData[0].key);
        }
      }
    };
  
    const enableRoomNoDropdown = (hostelId) => {
      // First, fetch the rooms list from the given hostel id
      fetchHostelRooms(hostelId)
        .then((res) => res.json())
        .then((data) => {
          setFormState((prevState) => {
            return {
              ...prevState,
              room: {
                ...prevState.room,
                options: data.map((room) => {
                  return {
                    key: room.id,
                    text: room.room_number,
                    value: room.id,
                  };
                }),
                disabled: false,
              },
            };
          });
        })
        .catch((err) =>
          console.error(
            "Failed to fetch hostel rooms for selected hostel in dropdown ",
            err
          )
        );
    };
  
    const handleFileInputClear = (name) => {
      setFormState((prevState) => {
        return {
          ...prevState,
          [name]: {
            ...prevState[name],
            value: undefined,
            isFilled: false,
          },
        };
      });
    };
  
    const renderFormField = (key) => {
      switch (formState[key]["type"]) {
        case "dropdown":
          return (
            <Form.Field
              key={key}
              required={formState[key]["isMandatory"]}
              inline={formState[key]["isInline"]}
            >
              <label>
                {formState[key]["label"]}
                <span>
                  {formState[key]["actions"]
                    ? formState[key]["actions"].map((action) => {
                        return action["actionComponent"];
                      })
                    : null}
                </span>
              </label>
              {renderDropdownField(key)}
            </Form.Field>
          );
        case "multi-dropdown":
          return (
            <Form.Field
              key={key}
              required={formState[key]["isMandatory"]}
              inline={formState[key]["isInline"]}
            >
              <label>{formState[key]["label"]}</label>
              {renderDropdownField(key, true)}
            </Form.Field>
          );
        case "file":
          return (
            <Form.Field
              key={key}
              required={formState[key]["isMandatory"]}
              inline={formState[key]["isInline"]}
            >
              <label>{formState[key]["label"]}</label>
              {renderFileField(key)}
            </Form.Field>
          );
        case "textbox":
          return (
            <Form.Field
              key={key}
              required={formState[key]["isMandatory"]}
              inline={formState[key]["isInline"]}
            >
              <label>{formState[key]["label"]}</label>
              {renderTextboxField(key)}
            </Form.Field>
          );
        case "readable":
          return (
            <Form.Field
              key={key}
              required={formState[key]["isMandatory"]}
              inline={formState[key]["isInline"]}
            >
              <label>{formState[key]["label"]}</label>
              <Input
                fluid
                type={formState[key]["type"]}
                placeholder={formState[key]["label"]}
                onChange={() => {}}
                name={key}
                value={formState[key]["text"]}
              />
            </Form.Field>
          );
        case "checkbox":
          return (
            <Form.Checkbox
              key={key}
              required={formState[key]["isMandatory"]}
              label={formState[key]["text"]}
              onChange={(e, data) =>
                setFormState((prevState) => {
                  return {
                    ...prevState,
                    [key]: {
                      ...prevState[key],
                      value: data.checked,
                    },
                  };
                })
              }
              checked={formState[key]["value"]}
            ></Form.Checkbox>
          );
        case "search":
          return (
            <Form.Field
              key={key}
              required={formState[key]["isMandatory"]}
              inline={formState[key]["isInline"]}
            >
              <label>{formState[key]["label"]}</label>
              <Search
                fluid
                placeholder={formState[key]["placeholder"]}
                loading={formState[key]["loadingState"]}
                onResultSelect={formState[key]["onResultSelectFn"]}
                onSearchChange={formState[key]["onSearchChangeFn"]}
                results={formState[key]["results"]}
                value={formState[key]["textValue"]}
              />
            </Form.Field>
          );
        case "password":
          return (
            <Form.Field key={key} required={formState[key]["isMandatory"]}>
              <label>
                {formState[key]["label"]}{" "}
                <span>
                  {formState[key]["actions"]
                    ? formState[key]["actions"].map((action) => {
                        return action["actionComponent"];
                      })
                    : null}
                </span>
              </label>
              <Input
                fluid
                icon={
                  <Icon
                    name={formState[key]["renderValue"] ? "eye slash" : "eye"}
                    circular
                    link
                    onClick={() =>
                      setFormState((prevState) => {
                        return {
                          ...prevState,
                          [key]: {
                            ...prevState[key],
                            renderValue: !prevState[key]["renderValue"],
                          },
                        };
                      })
                    }
                  />
                }
                name={key}
                type={formState[key]["renderValue"] ? "text" : "password"}
                placeholder={formState[key]["label"]}
                onChange={handleChange}
                value={formState[key]["value"]}
              />
            </Form.Field>
          );
        case "phone":
          return (
            <Form.Field
              key={key}
              required={formState[key]["isMandatory"]}
              inline={formState[key]["isInline"]}
            >
              <label>{formState[key]["label"]} </label>
              <Input
                placeholder="Enter mobile number"
                label={{
                  basic: true,
                  content: formState[key]["ipLen"] + "/10",
                  color:
                    formState[key]["ipLen"] < 10
                      ? formState[key]["ipLen"] < 7
                        ? formState[key]["ipLen"] < 5
                          ? formState[key]["ipLen"] < 3
                            ? "red"
                            : "orange"
                          : "yellow"
                        : "olive"
                      : "green",
                }}
                labelPosition="right"
                value={formState[key]["value"]}
                onChange={(e, { value }) => {
                  if (isNaN(value.trim()) || value.length > 10) {
                  } else {
                    setFormState((prevState) => {
                      return {
                        ...prevState,
                        [key]: {
                          ...prevState[key],
                          value: value.trim(),
                          ipLen: value.trim().length,
                        },
                      };
                    });
                  }
                }}
              />
            </Form.Field>
          );
  
        default:
          return (
            <Form.Field key={key} required={formState[key]["isMandatory"]}>
              <label>{formState[key]["label"]}</label>
              {renderInputField(key)}
            </Form.Field>
          );
      }
    };
  
    const renderDropdownField = (key, multiSelect = false) => {
      if (formState[key]["isChild"]) {
        return (
          <Dropdown
            fluid
            multiple={multiSelect}
            search
            selection
            clearable
            disabled={formState[key]["disabled"]}
            options={formState[key]["options"]}
            placeholder={formState[key]["placeholder"]}
            onChange={handleChange}
            name={key}
            value={formState[key]["value"]}
          />
        );
      } else {
        return (
          <Dropdown
            fluid
            multiple={multiSelect}
            search
            selection
            clearable
            selectOnBlur={false}
            options={formState[key]["options"]}
            placeholder={formState[key]["placeholder"]}
            onChange={handleChange}
            name={key}
            value={formState[key]["value"]}
          />
        );
      }
    };
  
    const renderInputField = (key) => {
      return (
        <Input
          fluid={formState[key]["inline"]}
          type={formState[key]["type"]}
          placeholder={formState[key]["label"]}
          onChange={handleChange}
          name={key}
          value={formState[key]["value"]}
          maxLength={
            formState[key]["maxLen"] ? formState[key]["maxLen"] : "524288" //default value for max length
          }
        />
      );
    };
  
    const renderFileField = (key) => {
      return (
        <Input
          fluid
          type={formState[key]["type"]}
          placeholder={formState[key]["label"]}
          onChange={handleChange}
          name={key}
          key={formState[key]["isFilled"] ? Date.now() : ""}
        >
          <input
            type="file"
            name="uploadfile"
            id={key}
            style={{ display: "none" }}
          />
          <label htmlFor={key}>
            <Label size="large" color="teal">
              Upload
              <Icon name="upload" style={{ marginLeft: "0.5vw" }} />
            </Label>
          </label>
  
          {formState[key]["value"] && formState[key]["value"]["name"] ? (
            <>
              <span style={{ marginTop: "0.5vh", marginLeft: "0.5vw" }}>
                {formState[key]["link"] ? (
                  <a href={formState[key]["link"]} target="_blank">
                    {formState[key]["value"]["name"]}
                  </a>
                ) : (
                  formState[key]["value"]["name"]
                )}
              </span>
              <Icon
                name="x"
                circular={true}
                style={{ marginLeft: "0.5vw" }}
                onClick={() => handleFileInputClear(key)}
              />
            </>
          ) : (
            <span style={{ marginTop: "0.5vh", marginLeft: "0.5vw" }}>
              No File Chosen
            </span>
          )}
        </Input>
      );
    };
  
    const renderTextboxField = (key) => {
      return (
        <TextArea
          placeholder={formState[key]["label"]}
          onChange={handleChange}
          name={key}
          value={formState[key]["value"]}
        />
      );
    };
  
    const renderSearchFormWithSingleCol = () => {
      // Rendering the complete form in the single column, with the width as half of the screen width
  
      const inputKeys = Object.keys(formState);
  
      let searchFormCompTree = inputKeys.map((queryKey, idx) => {
        return (
          <Form.Group widths="equal" key={idx}>
            {renderFormField(queryKey)}
          </Form.Group>
        );
      });
  
      return searchFormCompTree;
    };
  
    const renderSearchFormWithTwoCols = () => {
      // Dividing the total input fields into 2 lists for rendering purposes
      // one will be rendered on the left side, and other in the same row right side
      const leftAlignedInputKeys = Object.keys(formState).filter(
        (el, idx) => idx % 2 === 0
      );
      const rtAlignedInputKeys = Object.keys(formState).filter(
        (el, idx) => idx % 2 === 1
      );
  
      let nextIndex = 0;
      let searchFormCompTree = rtAlignedInputKeys.map((queryKey, idx) => {
        const leftKey = leftAlignedInputKeys[idx];
        const rtKey = rtAlignedInputKeys[idx];
        nextIndex = idx + 1;
        return (
          <Form.Group widths="equal" key={idx}>
            {renderFormField(leftKey)}
            {renderFormField(rtKey)}
          </Form.Group>
        );
      });
  
      // Now, two cases are possible:
      // Case 2: Last 1 key is left (if only leftKey has any key left)
      // Case 3: Or no key is left
      if (leftAlignedInputKeys.length > nextIndex) {
        // means that leftKey has an element left
        const leftKey = leftAlignedInputKeys[nextIndex];
        searchFormCompTree.push(
          <Form.Group widths="two" key={nextIndex}>
            {renderFormField(leftKey)}
          </Form.Group>
        );
      }
  
      return searchFormCompTree;
    };
  
    const renderSearchFormWithThreeCols = () => {
      // Dividing the total input fields into 3 lists for rendering purposes
      // one will be rendered on the left side, one in the mid and other in the same row right side
      const leftAlignedInputKeys = Object.keys(formState).filter(
        (el, idx) => idx % 3 === 0
      );
      const midAlignedInputKeys = Object.keys(formState).filter(
        (el, idx) => idx % 3 === 1
      );
  
      const rtAlignedInputKeys = Object.keys(formState).filter(
        (el, idx) => idx % 3 === 2
      );
      let nextIndex = 0;
      let searchFormCompTree = rtAlignedInputKeys.map((queryKey, idx) => {
        const leftKey = leftAlignedInputKeys[idx];
        const midKey = midAlignedInputKeys[idx];
        const rtKey = rtAlignedInputKeys[idx];
        nextIndex = idx + 1;
        return (
          <Form.Group widths="equal" key={idx}>
            {renderFormField(leftKey)}
            {renderFormField(midKey)}
            {renderFormField(rtKey)}
          </Form.Group>
        );
      });
  
      // Now, three cases are possible:
      // Case 1: Only last 2 (if midKey & leftKey have a key left)
      // Case 2: Or last 1(if only leftKey has any key left)
      // Case 3: Or no key is left
      if (leftAlignedInputKeys.length > nextIndex) {
        // means that leftKey has an element left
        const leftKey = leftAlignedInputKeys[nextIndex];
        if (midAlignedInputKeys.length > nextIndex) {
          const midKey = midAlignedInputKeys[nextIndex];
          searchFormCompTree.push(
            <Form.Group widths="three" key={nextIndex}>
              {renderFormField(leftKey)}
              {renderFormField(midKey)}
            </Form.Group>
          );
        } else {
          searchFormCompTree.push(
            <Form.Group widths="three" key={nextIndex}>
              {renderFormField(leftKey)}
            </Form.Group>
          );
        }
      }
  
      return searchFormCompTree;
    };
  
    // render form fields on basis of 'cols'
    switch (cols) {
      case 1:
        return renderSearchFormWithSingleCol();
  
      case 2:
        return renderSearchFormWithTwoCols();
  
      default:
        return renderSearchFormWithThreeCols();
    }
  }
  