import React from "react";
import { Modal } from "semantic-ui-react";
import ProfileCard from "../Cards/ProfileCard";

export default function ShowProfileModal({ person_id, open, setOpen }) {
  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      centered={true}
      style={{ width: 'auto', maxWidth: 'fit-content' }} // Ensures the modal resizes based on ProfileCard
      closeIcon={true}
    >
      <Modal.Content style={{ padding: '0' }}> {/* Remove padding for tighter fit */}
        <ProfileCard person_id={person_id} />     
      </Modal.Content>
    </Modal>
  );
}
