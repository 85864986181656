import React, { useState } from "react";
import { useAuth } from "../Context/AuthContext";
import Navbar from "../Components/Navbar";
import { Breadcrumb, Container, Header, Menu, Segment } from "semantic-ui-react";
import "../Css/Inbox.css";

// import { Inbox } from "../Components/Notification/Inbox";
// import { Sent } from "../Components/Notification/Sent";
import { HostelAdmin, SrkInbox2 } from "../Components/SRK2/HostelAdmin";
import SearchModal from "../Components/Modals/SearchModal";
import { HostelWarden } from "../Components/SRK2/HostelWarden";
import { SalaryComplaintForm } from "../Components/Salary/SalaryComplaintForm";
import { Link } from "react-router-dom";
import { SalaryComplaintSent } from "../Components/Salary/SalaryComplaintSent";
import { AdminSalaryComplaintSent } from "../Components/Salary/AdminSalaryComplaintSent";
// import { CreateForm } from "../Components/Notification/CreateForm";

export default function SalaryComplaints() {
  const { logout } = useAuth();
  const handleItemClick = (name) => setActiveItem(name);
  const srk_fellow_id = localStorage.getItem("srk_fellow_id");
  const srk_admin_id = localStorage.getItem("srk_admin_id");
  const [isModalOpen, setModalOpen] = useState(false);
  const closeModal = () => setModalOpen(false);
  const complaint_salary_admin_id = localStorage.getItem(
    "complaint_salary_admin_id"
  );
  const complaint_salary_employee_id = localStorage.getItem(
    "complaint_salary_employee_id"
  );
    const [activeItem, setActiveItem] = useState(complaint_salary_employee_id ? "create":"all-complaints");
    // if(complaint_salary_employee_id){
    //   setActiveItem("create")
    // } else {
    //   setActiveItem("all-complaints")
    // }

  return (
    <>
      <Navbar onLogout={logout} setModalOpen2={setModalOpen} />
      <SearchModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <Container fluid style={{ paddingLeft: "10vw", paddingRight: "10vw" }}>
        <div style={{ paddingTop: "90px" }}>
          <Breadcrumb>
            <Breadcrumb.Section link as={Link} to="/">
              Home
            </Breadcrumb.Section>
            <Breadcrumb.Divider />
            <Breadcrumb.Section active>Salary Issues</Breadcrumb.Section>
          </Breadcrumb>
        <Header as={"h2"} style={{fontFamily: "Times New Roman, Georgia, serif"}}>Salary Issues</Header>
          <Menu
            pointing
            // style={srk_fellow_id ? { display: "flex" } : { display: "none" }}
          >
            {complaint_salary_employee_id && (
              <Menu.Item
                name="create"
                active={activeItem === "create"}
                onClick={() => handleItemClick("create")}
              />
            )}
            {complaint_salary_employee_id && (
              <Menu.Item
                name="sent messages"
                active={activeItem === "sent"}
                onClick={() => handleItemClick("sent")}
              />
            )}
            {complaint_salary_admin_id && (
              <Menu.Item
                name="all messages"
                active={activeItem === "all-complaints"}
                onClick={() => handleItemClick("all-complaints")}
              />
            )}
            {/* 
            {hostel_admin_id && (
              <Menu.Item
                name="all-reports"
                active={activeItem === "all-reports"}
                onClick={() => handleItemClick("all-reports")}
              />
            )} */}
          </Menu>

          <Segment>
            {complaint_salary_employee_id && activeItem === "sent" && (
              <SalaryComplaintSent complaint_salary_employee_id={complaint_salary_employee_id} />
            )}
            {complaint_salary_admin_id && activeItem === "all-complaints" && (
              <AdminSalaryComplaintSent complaint_salary_admin_id={complaint_salary_admin_id} />
            )}
            {/* {activeItem === "all-reports" && (
              <HostelAdmin srk_admin_id={hostel_admin_id} />
            )} */}

            {/* {authfac_id && activeItem === "sent" && <Sent authfac_id={authfac_id} />}  */}

            {complaint_salary_employee_id && activeItem === "create" && (
              <SalaryComplaintForm />
            )}
          </Segment>
        </div>
      </Container>
    </>
  );
}
