import React, { useState } from "react";
import { Button, Modal } from "semantic-ui-react";
import UserLoginLogs from "./UserLoginLogs";
import ScrollView from "../ScrollView";

const ShowUserLoginLogs = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button icon={"line graph"} disabled={open} primary onClick={() => setOpen(true)}>
      </Button>

      <Modal open={open} onClose={() => setOpen(false)} closeIcon>
        <Modal.Header>Analytics</Modal.Header>
        <Modal.Content>
          <ScrollView maxHeight="70vh" minHeight="70vh">
            <UserLoginLogs />
          </ScrollView>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setOpen(false)}>Close</Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default ShowUserLoginLogs;
