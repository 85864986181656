import React, { useState, useEffect } from "react";
import { Grid, Table, Icon, Input, Button, Container } from "semantic-ui-react";
import { fetchAdminSalaryComplaint, createComment } from "../api"; // Ensure createComment API function is implemented
import LoaderComponent from "../LoaderComponent";
import NotificationSegment from "../Segments/NotificationSegment";
import SrkMessageTable from "../Tables/SrkMessageTable";
import SrkMessageViewCard from "../Cards/SrkMessageViewCard";
import SrkFellowAnalysisCard from "../Cards/SrkFellowAnalysisCard";
import "../../Css/SrkInbox.css";
import SrkGodModeAnalysisModal from "../Modals/SrkGodModeAnalysisModal";
import AdminMessageTable from "./AdminMessageTable";
import SalaryMessageViewCard from "./SalaryMessageViewCard";
import CommentModal from "../CommentModal"; // Import the CommentModal component


export function AdminSalaryComplaintSent(props) {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [selectedMsgUsername, setSetelectedMsgUsername] = useState();
  const [sortColumn, setSortColumn] = useState("submissionDate");
  const [sortDirection, setSortDirection] = useState("descending");
  const [searchQuery, setSearchQuery] = useState("");
  const rowColors = [
    "#D1CCE6aa",
    "#F7D1C5aa",
    "#B7DFF9aa",
    "#F7D8DCaa",
    "#E8B5B8aa",
    "#EEDDF4aa",
    "#B8CDEBaa",
    "#F9C3A6aa",
    "#D4D4D4aa",
    // "#F7ECD7aa",
  ];
  const [showAnalysis, setShowAnalysis] = useState(false);
  const [showAddComments, setShowAddComments] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isCommentModalOpen, setCommentModalOpen] = useState(false);
  const [commentSubmitted, setCommentSubmitted] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const openCommentModal = () => setCommentModalOpen(true);
  const closeCommentModal = () => setCommentModalOpen(false);

  useEffect(() => {
    if (props && props.complaint_salary_admin_id) {
      setLoading(true);
      fetchAdminSalaryComplaint(props.complaint_salary_admin_id)
        .then((response) => response.json())
        .then((data) => {
          setData(
            data.sort(
              (a, b) => new Date(b.submissionDate) - new Date(a.submissionDate)
            )
          );
          setLoading(false);
        })
        .catch((error) => {
          console.log("Failed to fetch inbox list: ", error);
          setLoading(false);
        });
    } else {
      console.log("State/State.person_id not available");
    }
  }, [props]);

  const selectMessage = (message) => {
    setSelectedMessage(message);
  };

  const toggleCommentsView = () => {
    setShowAddComments(!showAddComments);
    openCommentModal();
  };

  const toggleAnalysisView = () => {
    setShowAnalysis(!showAnalysis);
  };

  const setAnalysisView = (value) => {
    setShowAnalysis(value);
  };

  const handleSort = (clickedColumn) => () => {
    if (sortColumn !== clickedColumn) {
      setSortColumn(clickedColumn);
      setSortDirection("ascending");
      setData(data.slice().sort((a, b) => a[clickedColumn] - b[clickedColumn]));
    } else {
      setSortDirection(
        sortDirection === "ascending" ? "descending" : "ascending"
      );
      setData(data.slice().reverse());
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleCommentSubmit = (comment) => {
    const commentator = localStorage.getItem("complaint_salary_admin_id");
    const payload = {
      complaint: selectedMessage,
      comment: comment,
      commentator: commentator,
    };
    createComment(payload)
      .then(() => {
        closeCommentModal();
        setCommentSubmitted(!commentSubmitted); // Toggle the commentSubmitted state to trigger a re-render
        // Add any additional actions here, like refreshing the data or showing a success message
      })
      .catch((error) => {
        console.log("Failed to submit comment: ", error);
      });
  };

  const filteredData = data.filter((item) =>
    item.sender_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Grid>
      {isLoading ? (
        <LoaderComponent loadingText={"Loading Notifications ..."} />
      ) : (
        <>
          <SrkGodModeAnalysisModal
            from={"Salary"}
            data={data}
            isModalOpen={isModalOpen}
            closeModal={closeModal}
          />
          {console.log(data)}
          <Grid.Column
            width={9}
            style={{ maxHeight: "80vh", overflowY: "auto", minHeight: "80vh" }}
          >
            <Grid columns={2} stackable>
              <Grid.Column width={15}>
                <Input
                  fluid
                  icon="search"
                  placeholder="Search by Sender's Name..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </Grid.Column>
              <Grid.Column
                width={1}
                style={{ paddingLeft: "0" }}
                textAlign="right"
              >
                <Button icon="line graph" color="blue" onClick={openModal} />
              </Grid.Column>
            </Grid>
            <Table selectable striped color="blue">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell
                    sorted={
                      sortColumn === "submissionDate" ? sortDirection : null
                    }
                    onClick={handleSort("submissionDate")}
                    style={{ cursor: "pointer" }}
                  >
                    Submission Date
                    {sortColumn === "submissionDate" && (
                      <Icon
                        name={
                          sortDirection === "ascending"
                            ? "sort up"
                            : "sort down"
                        }
                      />
                    )}
                  </Table.HeaderCell>
                  <Table.HeaderCell>Sender's Name</Table.HeaderCell>
                  <Table.HeaderCell>Content</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {filteredData.map((item, index) => (
                  <AdminMessageTable
                    key={item.note_id}
                    item={item}
                    selectMessage={selectMessage}
                    setSetelectedMsgUsername={setSetelectedMsgUsername}
                    cardBgColor={rowColors[index % rowColors.length]}
                    from="Inbox"
                    setAnalysisView={setAnalysisView}
                  />
                ))}
              </Table.Body>
            </Table>
          </Grid.Column>
          {selectedMessage ? (
            <Grid.Column
              width={7}
              style={{
                transition: "opacity 0.5s",
                opacity: 1,
                maxHeight: "80vh",
                overflowY: "auto",
              }}
            >
              <Container fluid textAlign="right">
                <Button.Group>
                  <Button icon onClick={toggleCommentsView} color="blue">
                    <Icon name="add" />
                    Add Comments
                  </Button>
                </Button.Group>
              </Container>

              {showAnalysis && (
                <SrkFellowAnalysisCard data={data} name={selectedMsgUsername} />
              )}

              <SalaryMessageViewCard
                id={selectedMessage}
                commentSubmitted={commentSubmitted}
              />
            </Grid.Column>
          ) : (
            <Grid.Column
              width={7}
              style={{
                transition: "opacity 0.5s",
                opacity: 1,
                maxHeight: "80vh",
                overflowY: "auto",
              }}
            >
              <NotificationSegment />
            </Grid.Column>
          )}
        </>
      )}
      <CommentModal
        open={isCommentModalOpen}
        onClose={closeCommentModal}
        onSubmit={handleCommentSubmit}
      />
    </Grid>
  );
}
