import React from 'react'
import { Modal } from 'semantic-ui-react'
import SearchComponent from '../Search'

export default function SearchModal(props) {
  return (
    <Modal
      open={props.isModalOpen}
      onClose={props.closeModal}
      size="large"
      closeIcon
      style={{minHeight:"80vh", maxHeight:"80vh"}}
    >
      <Modal.Content>
        <div style={{maxHeight:"80vh", overflowX:'hidden'}}>
       <SearchComponent />
        </div>
      </Modal.Content>
    </Modal>
  )
}
