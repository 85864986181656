/**
 * A component that displays a grid of statistics.
 *
 * @component
 * @example
 * // Usage example
 * <GenericStatistics
 *   stats={[
 *     { icon: "users", value: 100, label: "Total Users", color: "blue" },
 *     { icon: "chart line", value: 500, label: "Total Sales", color: "green" },
 *     { icon: "calendar alternate", value: 30, label: "Total Events", color: "orange" },
 *   ]}
 *   columns={3}
 *   stackable={true}
 *   rowStyle={{ marginBottom: "20px" }}
 *   columnStyle={{ textAlign: "center" }}
 * />
 */
import React, { memo } from "react";
import { Statistic, Grid } from "semantic-ui-react";

const GenericStatistics = ({
  stats = [],
  columns = 3,          // Default to 3 columns
  stackable = true,      // Make rows stackable on smaller screens
  rowStyle = {},         // Custom styles for the entire row
  columnStyle = {},      // Custom styles for individual columns
}) => {
  return (
    <Grid
      columns={columns}
      stackable={stackable}
      style={rowStyle}
    >
      {stats.map((stat, index) => (
        <Grid.Column key={index} style={columnStyle}>
          <Statistic color={stat.color || "blue"}>
            {stat.icon && (
              <Statistic.Label>
                <i className={`${stat.icon} icon`} />
              </Statistic.Label>
            )}
            <Statistic.Value>{stat.value || 0}</Statistic.Value>
            <Statistic.Label>{stat.label}</Statistic.Label>
          </Statistic>
        </Grid.Column>
      ))}
    </Grid>
  );
};

export default memo(GenericStatistics);
