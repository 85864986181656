import React, { useState, useEffect } from "react";
import {
  Form,
  Dropdown,
  Input,
  TextArea,
  Button,
  Popup,
  Icon,
  Transition,
  Message,
} from "semantic-ui-react";
import jsonData from "../../data/final_data.json";
import "../../Css/MultipartForm.css";

import { sendNotificationToAdHoc } from "../api";

// Helper function to merge arrays based on unique IDs
const mergeArraysById = (arr1, arr2) => {
  const merged = [...arr1];
  arr2.forEach((item) => {
    if (!merged.find((i) => i.key === item.key)) {
      merged.push(item);
    }
  });
  return merged;
};

const MultiPartForm = () => {
  const [formData, setFormData] = useState({
    sender: "",
    title: "",
    body: "",
    dept: [],
    program: [],
    course: [],
    year: [],
    exam_form: "all",
    enroll_form: "all",
    person_t: "all",
    institute: [],
    faculty: [],
    attachment: null,
    groups: [],
    program_type: "all",
  });

  const resetData = () => {
    setFormData({
      sender: "",
      title: "",
      body: "",
      dept: [],
      program: [],
      course: [],
      year: [],
      exam_form: "all",
      enroll_form: "all",
      person_t: "all",
      institute: [],
      faculty: [],
      attachment: null,
      groups: [],
      program_type: "all",
    });
  };
  const [institutes, setInstitutes] = useState([]);
  const [faculties, setFaculties] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [courses, setCourses] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState(null);

  const program_type = [
    { key: "ALL", text: "All", value: "all" },
    { key: "UG", text: "UG", value: "UG" },
    { key: "PG", text: "PG", value: "PG" },
  ];

  const admissionYears = [
    { key: "2019", text: "2019", value: "2019" },
    { key: "2020", text: "2020", value: "2020" },
    { key: "2021", text: "2021", value: "2021" },
    { key: "2022", text: "2022", value: "2022" },
    { key: "2023", text: "2023", value: "2023" },
    { key: "2024", text: "2024", value: "2024" },
  ];

  const roleOptions = [
    { key: "ALL", text: "All", value: "all" },
    { key: "STU", text: "Student", value: "STU" },
    { key: "FAC", text: "Faculty", value: "FAC" },
    { key: "OTH", text: "Staff", value: "OTH" },
  ];

  const yesNoOptions = [
    { key: "ALL", text: "All", value: "all" },
    { key: "YES", text: "Yes", value: "yes" },
    { key: "NO", text: "No", value: "no" },
  ];

  const specialFaculties = [
    6, // of Arts
    8, // Commerce
    10, // Education
    12, // Law
    13, // Performing Arts
    15, // SVDV
    17, // Social Science
    18, // Visual Arts
    20, // MMV
  ];

  useEffect(() => {
    const institutesList = jsonData.map((institute) => ({
      key: institute.id,
      text: institute.name,
      value: institute.id,
    }));
    setInstitutes(institutesList);
  }, []);

  const handleInstituteChange = (e, { value }) => {
    setFormData({
      ...formData,
      institute: value,
      faculty: [],
      dept: [],
      program: [],
      course: [],
    });
    const selectedInstitutes = jsonData.filter((inst) =>
      value.includes(inst.id)
    );
    const facultiesList = selectedInstitutes.flatMap((inst) =>
      inst.faculties
        ? inst.faculties.map((faculty) => ({
            key: faculty.id,
            text: faculty.name,
            value: faculty.id,
          }))
        : []
    );
    setFaculties((prev) => mergeArraysById(prev, facultiesList));

    const shouldSkipToDepartments = value.some((val) =>
      specialFaculties.includes(val)
    );

    if (shouldSkipToDepartments) {
      const departmentsList = selectedInstitutes.flatMap((inst) =>
        inst.departments
          ? inst.departments.map((dept) => ({
              key: dept.id,
              text: dept.name,
              value: dept.id,
            }))
          : []
      );
      setDepartments((prev) => mergeArraysById(prev, departmentsList));
    } else {
      setDepartments([]);
    }
  };

  const handleFacultyChange = (e, { value }) => {
    setFormData({
      ...formData,
      faculty: value,
      dept: [],
      program: [],
      course: [],
    });
    const selectedInstitutes = jsonData.filter((inst) =>
      formData.institute.includes(inst.id)
    );
    const selectedFaculties = selectedInstitutes.flatMap((inst) =>
      inst.faculties
        ? inst.faculties.filter((fac) => value.includes(fac.id))
        : []
    );
    const departmentsList = selectedFaculties.flatMap((faculty) =>
      faculty.departments
        ? faculty.departments.map((dept) => ({
            key: dept.id,
            text: dept.name,
            value: dept.id,
          }))
        : []
    );
    setDepartments((prev) => mergeArraysById(prev, departmentsList));
  };

  const handleDepartmentChange = (e, { value }) => {
    setFormData({ ...formData, dept: value, program: [], course: [] });

    const selectedInstitutes = jsonData.filter((inst) =>
      formData.institute.includes(inst.id)
    );

    let selectedFaculties = selectedInstitutes.flatMap((inst) =>
      inst.faculties
        ? inst.faculties.filter((fac) => formData.faculty.includes(fac.id))
        : []
    );
    // console.log(selectedFaculties);
    // Include institute IDs from specialFaculties in the faculty list
    selectedInstitutes.forEach((inst) => {
      if (specialFaculties.includes(inst.id)) {
        selectedFaculties.push(inst);
      }
    });

    const selectedDepartments = selectedFaculties.flatMap((faculty) =>
      faculty.departments
        ? faculty.departments.filter((dept) => value.includes(dept.id))
        : []
    );

    const programsList = selectedDepartments.flatMap((dept) =>
      dept.programs
        ? dept.programs.map((program) => ({
            key: program.id,
            text: program.name,
            value: program.id,
          }))
        : []
    );

    setPrograms((prev) => mergeArraysById(prev, programsList));
  };

  const handleProgramChange = (e, { value }) => {
    setFormData({ ...formData, program: value, course: [] });

    const selectedInstitutes = jsonData.filter((inst) =>
      formData.institute.includes(inst.id)
    );

    let selectedFaculties = selectedInstitutes.flatMap((inst) =>
      inst.faculties
        ? inst.faculties.filter((fac) => formData.faculty.includes(fac.id))
        : []
    );
    // Include institute IDs from specialFaculties in the faculty list
    selectedInstitutes.forEach((inst) => {
      if (specialFaculties.includes(inst.id)) {
        selectedFaculties.push(inst);
      }
    });

    const selectedDepartments = selectedFaculties.flatMap((faculty) =>
      faculty.departments
        ? faculty.departments.filter((dept) => formData.dept.includes(dept.id))
        : []
    );

    const selectedPrograms = selectedDepartments.flatMap((dept) =>
      dept.programs
        ? dept.programs.filter((prog) => value.includes(prog.id))
        : []
    );

    const coursesList = selectedPrograms.flatMap((program) =>
      program.courses
        ? program.courses.map((course) => ({
            key: course.id,
            text: course.name,
            value: course.id,
          }))
        : []
    );

    setCourses((prev) => mergeArraysById(prev, coursesList));
  };

  const handleInputChange = (e, { name, value }) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const attachment = e.target.files[0];
    if (
      attachment &&
      attachment.size <= 16 * 1024 * 1024 &&
      ["image/png", "image/jpeg", "application/pdf"].includes(attachment.type)
    ) {
      setFormData({ ...formData, attachment });
    } else {
      alert("File should be an image or document and less than 16MB.");
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    setSubmissionStatus(null);

    if (!formData.title.trim()) {
      alert("Title cannot be empty");
      setLoading(false);
      return;
    }

    if (!formData.body.trim()) {
      alert("Message cannot be empty");
      setLoading(false);
      return;
    }

    const ins = [];
    const authfac_id = localStorage.getItem("authfac_id");
    const adhoc_id = localStorage.getItem("adhoc_group");

    formData.institute.forEach((instId) => {
      const institute = jsonData.find((inst) => inst.id === instId);
      if (institute && institute.faculties) {
        const matchingFaculty = institute.faculties.find((fac) =>
          formData.faculty.includes(fac.id)
        );
        if (matchingFaculty) {
          ins.push(matchingFaculty.id);
        } else {
          ins.push(institute.id);
        }
      } else {
        ins.push(institute.id);
      }
    });

    const { institute, faculty, attachment, ...otherFormData } = formData;

    const submissionData = {
      ...otherFormData,
      ins,
      sender: authfac_id,
      groups: "[" + adhoc_id + "]",
    };

    const formPayload = new FormData();
    for (const key in submissionData) {
      if (submissionData.hasOwnProperty(key)) {
        if (Array.isArray(submissionData[key])) {
          formPayload.append(key, JSON.stringify(submissionData[key]));
        } else {
          formPayload.append(key, submissionData[key]);
        }
      }
    }

    if (attachment) {
      formPayload.append("attachment", attachment);
    }

    // console.log("Form Payload:", formPayload); // For Debuging only
    sendNotificationToAdHoc(formPayload)
      .then((response) => response.text())
      .then((text) => {
        console.log("Raw response text:", text);
        try {
          const data = JSON.parse(text);
          console.log("Notification sent successfully:", data);
          setSubmissionStatus("success");
          setFormData({
            sender: "",
            title: "",
            body: "",
            dept: [],
            program: [],
            course: [],
            year: [],
            exam_form: "All",
            enroll_form: "All",
            person_t: "all",
            adhoc_id: "",
            institute: [],
            faculty: [],
            attachment: null,
          });
        } catch (error) {
          console.error("Error parsing JSON response:", error);
          setSubmissionStatus("error");
        }
      })
      .catch((error) => {
        console.error("Error sending notification:", error);
        setSubmissionStatus("error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Form success error onSubmit={handleSubmit}>
        <Form.Field required>
          <label>Faculty/Student</label>
          <Dropdown
            placeholder="Select Role"
            fluid
            search
            selection
            options={roleOptions}
            value={formData.person_t}
            onChange={(e, { value }) =>
              setFormData({ ...formData, person_t: value })
            }
          />
        </Form.Field>

        <Form.Group widths="equal">
          <Form.Field required>
            <label>
              Institutes/Faculties
              <Popup
                size="small"
                content="Leave empty for sending notification to all Institutes/Faculties"
                trigger={
                  <Icon name="info circle" style={{ marginLeft: "5px" }} />
                }
              />
            </label>
            <Dropdown
              placeholder="All Institutes/Faculties"
              fluid
              search
              multiple
              selection
              options={institutes}
              value={formData.institute}
              onChange={handleInstituteChange}
            />
          </Form.Field>

          {formData.institute.some(
            (val) => !specialFaculties.includes(val)
          ) && (
            <Form.Field required>
              <label>
                Faculty
                <Popup
                  size="small"
                  content="Leave empty for sending notification to all Faculties"
                  trigger={
                    <Icon name="info circle" style={{ marginLeft: "5px" }} />
                  }
                />
              </label>
              <Dropdown
                placeholder="All Faculties"
                fluid
                search
                multiple
                selection
                options={faculties}
                value={formData.faculty}
                onChange={handleFacultyChange}
              />
            </Form.Field>
          )}

          {(formData.institute.some((val) => specialFaculties.includes(val)) ||
            formData.faculty.length > 0) && (
            <Form.Field required>
              <label>
                Departments
                <Popup
                  size="small"
                  content="Leave empty for sending notification to all Departments"
                  trigger={
                    <Icon name="info circle" style={{ marginLeft: "5px" }} />
                  }
                />
              </label>
              <Dropdown
                placeholder="All Departments"
                fluid
                search
                multiple
                selection
                options={departments}
                value={formData.dept}
                onChange={handleDepartmentChange}
              />
            </Form.Field>
          )}
        </Form.Group>

        <Form.Group widths="equal">
          {formData.dept.length > 0 && (
            <Form.Field required>
              <label>
                Programs
                <Popup
                  size="small"
                  content="Leave empty for sending notification to all Programs"
                  trigger={
                    <Icon name="info circle" style={{ marginLeft: "5px" }} />
                  }
                />
              </label>
              <Dropdown
                placeholder="All Programs"
                fluid
                search
                multiple
                selection
                options={programs}
                value={formData.program}
                onChange={handleProgramChange}
              />
            </Form.Field>
          )}

          {formData.program.length > 0 && (
            <Form.Field required>
              <label>
                Courses
                <Popup
                  size="small"
                  content="Leave empty for sending notification to all Courses"
                  trigger={
                    <Icon name="info circle" style={{ marginLeft: "5px" }} />
                  }
                />
              </label>
              <Dropdown
                placeholder="All Courses"
                fluid
                search
                multiple
                selection
                options={courses}
                value={formData.course}
                onChange={(e, { value }) =>
                  setFormData({ ...formData, course: value })
                }
              />
            </Form.Field>
          )}
        </Form.Group>

        <Button
          icon
          labelPosition="right"
          color="grey"
          size="mini"
          type="button"
          onClick={() => setShowMore(!showMore)}
          style={{ marginBottom: "1rem" }}
        >
          More Filters
          {!showMore ? (
            <Icon name="angle double down" />
          ) : (
            <Icon name="angle double up" />
          )}
        </Button>

        <Transition visible={showMore} animation="slide right" duration={500}>
          <div style={{ marginBottom: "1rem" }}>
            <Form.Group widths="equal">
              <Form.Field>
                <label>Program Type</label>
                <Dropdown
                  placeholder="Select Option"
                  fluid
                  search
                  selection
                  options={program_type}
                  value={formData.program_type}
                  onChange={(e, { value }) =>
                    setFormData({ ...formData, program_type: value })
                  }
                />
              </Form.Field>
              <Form.Field required>
                <label>
                  Admission Year
                  <Popup
                    size="small"
                    content="Leave empty for sending notification to all Admission Year"
                    trigger={
                      <Icon name="info circle" style={{ marginLeft: "5px" }} />
                    }
                  />
                </label>
                <Dropdown
                  placeholder="All Admission Year"
                  fluid
                  search
                  multiple
                  selection
                  options={admissionYears}
                  value={formData.year}
                  onChange={(e, { value }) =>
                    setFormData({ ...formData, year: value })
                  }
                />
              </Form.Field>
              <Form.Field>
                <label>Exam Form</label>
                <Dropdown
                  placeholder="Select Option"
                  fluid
                  search
                  selection
                  options={yesNoOptions}
                  value={formData.exam_form}
                  onChange={(e, { value }) =>
                    setFormData({ ...formData, exam_form: value })
                  }
                />
              </Form.Field>

              <Form.Field>
                <label>Enrollment Form</label>
                <Dropdown
                  placeholder="Select Option"
                  fluid
                  search
                  selection
                  options={yesNoOptions}
                  value={formData.enroll_form}
                  onChange={(e, { value }) =>
                    setFormData({ ...formData, enroll_form: value })
                  }
                />
              </Form.Field>
            </Form.Group>
          </div>
        </Transition>

        <Form.Field required>
          <label>Title</label>
          <Input
            placeholder="Enter Title"
            name="title"
            value={formData.title}
            onChange={handleInputChange}
          />
        </Form.Field>

        <Form.Field required>
          <label>Message</label>
          <TextArea
            placeholder="Enter Message"
            name="body"
            value={formData.body}
            onChange={handleInputChange}
          />
        </Form.Field>

        <Form.Field>
          <label>File Upload</label>
          <Input
            type="file"
            accept="image/*,application/pdf"
            onChange={handleFileChange}
          />
        </Form.Field>

        {submissionStatus === "success" && (
          <Message
            success
            header="Notification Sent"
            content="Your notification has been sent successfully."
          />
        )}
        {submissionStatus === "error" && (
          <Message
            error
            header="Failed to Send Notification"
            content="There was an error sending your notification. Please try again. If the error remanins please contact the admin"
          />
        )}
        <Button.Group>
          <Button onClick={resetData} type="button">
            Reset
          </Button>
          <Button type="submit" loading={loading} disabled={loading} primary>
            Submit
          </Button>
        </Button.Group>
      </Form>
    </>
  );
};

export default MultiPartForm;
