import React, { useState } from 'react'
import { Button, Popup } from 'semantic-ui-react';

export const MailLink = ({ data }) => {
    const [copied, setCopied] = useState(false);
    if (!data) return "";
    const copyToClipboard = () => {
      navigator.clipboard.writeText(data);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 1500);
    };
    return (
      <>
        <a href={"mailto:" + data} target="_blank" rel="noopener noreferrer">
          {data}
        </a>
        <Popup
          content="Copied!"
          on="click"
          open={copied}
          trigger={
            <Button
            floated='right'
              icon="copy"
              onClick={copyToClipboard}
              style={{ marginLeft: "8px", padding: "0.2rem" }}
            />
          }
          position="top center"
        />
      </>
    );
  };