import React, { useState, useEffect } from "react";
import { Button, Form, Icon, Message, Modal } from "semantic-ui-react";
import imageCompression from "browser-image-compression";
import { PDFDocument } from "pdf-lib";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css"; // Import styles for the PDF viewer
import { fetchCaseSheet, uploadFile } from "./api"; // Import the fetchCaseSheet and uploadFile functions from api.js
import { SERVER_URL } from "../api";

const FileUpload = ({ counselee_id, caseSheetId, uploadSection }) => {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [message, setMessage] = useState("");
  const [isModalOpenFU, setModalOpenFU] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [caseSheetData, setCaseSheetData] = useState(null);

  useEffect(() => {
    const fetchAttachment = async () => {
      try {
        const data = await fetchCaseSheet(caseSheetId);
        setCaseSheetData(data); // Set case sheet data
        if (data.attachment) {
          setPdfUrl(`${data.attachment}`);
        }
      } catch (error) {
        console.error("Error fetching attachment:", error);
      }
    };

    if (caseSheetId) {
      fetchAttachment();
    }
  }, [caseSheetId]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const compressImage = async (imageFile) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      return compressedFile;
    } catch (error) {
      console.error("Error compressing the image:", error);
      throw error;
    }
  };

  const convertImageToPdf = async (imageFile) => {
    const pdfDoc = await PDFDocument.create();
    const imageBytes = await imageFile.arrayBuffer();
    const imageExt = imageFile.type.split("/")[1];

    let image;
    if (imageExt === "jpeg" || imageExt === "jpg") {
      image = await pdfDoc.embedJpg(imageBytes);
    } else if (imageExt === "png") {
      image = await pdfDoc.embedPng(imageBytes);
    } else {
      throw new Error("Unsupported image format");
    }

    const page = pdfDoc.addPage();
    page.drawImage(image, {
      x: 0,
      y: 0,
      width: page.getWidth(),
      height: page.getHeight(),
    });

    const pdfBytes = await pdfDoc.save();
    return new Blob([pdfBytes], { type: "application/pdf" });
  };

  const handleFileUpload = async () => {
    if (!file) {
      setMessage("Please select an image to upload.");
      return;
    }

    setUploading(true);
    setMessage("");

    try {
      const compressedFile = await compressImage(file);

      let pdfBlob;
      if (caseSheetData && caseSheetData.attachment) {
        const response = await fetch(caseSheetData.attachment);
        const existingPdfBlob = await response.blob();

        const existingPdfDoc = await PDFDocument.load(
          await existingPdfBlob.arrayBuffer()
        );
        const newPdfDoc = await PDFDocument.create();

        const copiedPages = await newPdfDoc.copyPages(
          existingPdfDoc,
          existingPdfDoc.getPageIndices()
        );
        copiedPages.forEach((page) => newPdfDoc.addPage(page));

        const imageBytes = await compressedFile.arrayBuffer();
        const imageExt = compressedFile.type.split("/")[1];
        let image;
        if (imageExt === "jpeg" || imageExt === "jpg") {
          image = await newPdfDoc.embedJpg(imageBytes);
        } else if (imageExt === "png") {
          image = await newPdfDoc.embedPng(imageBytes);
        } else {
          throw new Error("Unsupported image format");
        }

        const page = newPdfDoc.addPage();
        page.drawImage(image, {
          x: 0,
          y: 0,
          width: page.getWidth(),
          height: page.getHeight(),
        });

        pdfBlob = new Blob([await newPdfDoc.save()], {
          type: "application/pdf",
        });
      } else {
        pdfBlob = await convertImageToPdf(compressedFile);
      }

      const formData = new FormData();
      formData.append("attachment", pdfBlob, "attachment.pdf");
      if (!caseSheetId) {
        formData.append("body", null);
        formData.append("counselee", counselee_id);
        formData.append("last_updated", new Date().toISOString().split("T")[0]);
      }
      const apiEndpoint = caseSheetId
        ? `${SERVER_URL}/pwbs/api/casesheet/${caseSheetId}/`
        : `${SERVER_URL}/pwbs/api/casesheet/`;

      const data = await uploadFile(apiEndpoint, formData, caseSheetId);

      setMessage("File uploaded successfully.");
      setPdfUrl(URL.createObjectURL(pdfBlob));
      if (!caseSheetId) {
        setCaseSheetData(data); // Update case sheet data with the new data
      }
      window.location.reload();
    } catch (error) {
      console.error("Error uploading file:", error);
      setMessage("Error uploading file. Please try again.");
    } finally {
      setUploading(false);
      setTimeout(() => setMessage(""), 3000); // Clear the message after 3 seconds
    }
  };

  const openModal = () => {
    setModalOpenFU(true);
  };

  const closeModal = () => {
    setModalOpenFU(false);
  };

  const handleDownload = () => {
    if (pdfUrl) {
      window.open(pdfUrl, "_blank");
    }
  };

  return (
    <div>
      {uploadSection && (
        <Form>
          <Form.Field>
            <label>Upload Session Attachment (if any)</label>
            <input type="file" accept="image/*" onChange={handleFileChange} />
          </Form.Field>
          <Button
            style={{ marginBottom: "0.5rem" }}
            primary
            onClick={handleFileUpload}
            loading={uploading}
            disabled={uploading}
          >
            <Icon name="upload" /> Upload
          </Button>
          {message && <Message>{message}</Message>}
        </Form>
      )}
      {pdfUrl && (
        <>
          <Button style={{ marginBottom: "0.5rem" }} onClick={openModal}>
            View Session Attachments
          </Button>
          <Button
            style={{ marginBottom: "0.5rem", marginLeft: "1rem" }}
            onClick={handleDownload}
            primary
          >
            <Icon name="download" /> Download
          </Button>
          <Modal open={isModalOpenFU} onClose={closeModal}>
            <Modal.Header>Attachment</Modal.Header>
            <Modal.Content>
              <div style={{ height: "500px" }}>
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                  <Viewer fileUrl={pdfUrl} />
                </Worker>
              </div>
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={closeModal}>Close</Button>
            </Modal.Actions>
          </Modal>
        </>
      )}
    </div>
  );
};

export default FileUpload;
