import React, { useEffect, useState } from "react";
import { Table, Grid, Header } from "semantic-ui-react";
import { fetchHolidays } from "../Components/api";
import { Link } from "react-router-dom";

const UpcomingHolidays = () => {
  const [normalHolidays, setNormalHolidays] = useState([]);
  const [restrictedHolidays, setRestrictedHolidays] = useState([]);
  const rowColors = [
    "#D1CCE6aa",
    "#F7D1C5aa",
    "#B7DFF9aa",
    "#F7D8DCaa",
    "#E8B5B8aa",
    "#EEDDF4aa",
    "#B8CDEBaa",
    "#F9C3A6aa",
    "#D4D4D4aa",
  ];

  useEffect(() => {
    fetchHolidays()
      .then((res) => res.json())
      .then((data) => {
        const today = new Date();

        const filterAndSortHolidays = (holidays, isRestricted) => {
          return holidays
            .filter((holiday) => new Date(holiday.from_date) >= today && holiday.is_restricted === isRestricted)
            .sort((a, b) => new Date(a.from_date) - new Date(b.from_date))
            .slice(0, 5);
        };

        setNormalHolidays(filterAndSortHolidays(data, false));
        setRestrictedHolidays(filterAndSortHolidays(data, true));
      })
      .catch((err) => {
        console.error("Error: cannot fetch holidays list: ", err);
      });
  }, []);

  const renderHolidayRows = (holidayList) => {
    return holidayList.map((holiday, index) => {
      const startDate = new Date(holiday.from_date);
      const endDate = holiday.till_date ? new Date(holiday.till_date) : startDate;
      const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      const startDay = daysOfWeek[startDate.getDay()];
      const endDay = endDate.getDate() === startDate.getDate() ? '' : `to ${daysOfWeek[endDate.getDay()]}`;
      const endDateText = holiday.till_date ? ` - ${endDate.toLocaleDateString('en-GB')}, ` : ", ";
      const startDateFormatted = startDate.toLocaleDateString('en-GB');
      const color = rowColors[index % rowColors.length]; // Alternate row colors based on index

      return (
        <Table.Row key={index} style={{ backgroundColor: color }}>
          <Table.Cell style={{ padding: "0.2rem" }}>
            {/* <Link to="/holidays" style={{ textDecoration: "none", color: "inherit" }}> */}
              {startDateFormatted}{endDateText}{startDay} {endDay}
            {/* </Link> */}
          </Table.Cell>
          <Table.Cell width={7} style={{ padding: "0.2rem" }} textAlign="right">
            {/* <Link to="/holidays" style={{ textDecoration: "none", color: "inherit" }}> */}
              {holiday.name_of_holiday}
            {/* </Link> */}
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  return (
    <Grid columns={2} stackable>
      <Grid.Row>
        <Grid.Column>
          <Header as="h3">Holidays</Header>
          <Table fixed unstackable singleLine>
            <Table.Body>
              {renderHolidayRows(normalHolidays)}
            </Table.Body>
          </Table>
        </Grid.Column>
        <Grid.Column>
          <Header as="h3">Restricted Holidays</Header>
          <Table fixed unstackable singleLine>
            <Table.Body>
              {renderHolidayRows(restrictedHolidays)}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default UpcomingHolidays;
