import React, { useState } from "react";
import { Icon, Card, Button, Modal } from "semantic-ui-react";
import { convertDateTimestamp, isImageFile, linkDecorator } from "../utils";
import ReactLinkify from "react-linkify";

export default function MessageViewCard(props) {
  const [open, setOpen] = useState(false);

  return (
    <Card color="green" fluid>
      <Card.Content>
        <Card.Header>
          <div
            style={{
              overflowWrap: "break-word", // ensures long words are broken and wrapped to the next line
              wordWrap: "break-word", // alternative/legacy property for word wrapping
              whiteSpace: "normal", // allows text to wrap as needed
            }}
          >
            {props.selectedMessage.title}
          </div>
          <span
            style={{
              float: "right",
              color: "black",
              fontSize: "1rem",
              whiteSpace: "nowrap", // ensures this text does not wrap, keeping it on one line
            }}
          >
            {convertDateTimestamp(props.selectedMessage.time_of_sending)}
            {props.type === "Sent" && (
              <Button
                style={{ marginLeft: "10px" }}
                id="btn-modal"
                icon="bars"
                size="mini"
                compact
                onClick={props.openModal}
              />
            )}
          </span>
        </Card.Header>

        {props.selectedMessage.group && (
          <Card.Content extra style={{ paddingTop: "0.25rem" }}>
            <strong>Group: </strong>
            {props.selectedMessage.group.name}
          </Card.Content>
        )}
        <Card.Description style={{ paddingTop: "0.25rem" }}>
          {/* <strong>Message:</strong>{" "} */}
          {props.selectedMessage.body.split("\n").map((line, index) => (
            <span key={index}>
              <ReactLinkify componentDecorator={linkDecorator}>
                {line}
              </ReactLinkify>
              <br />
            </span>
          ))}
        </Card.Description>
        {props.selectedMessage.attachment && (
          <Card.Content extra style={{ paddingTop: "0.5rem" }}>
            {isImageFile(props.selectedMessage.attachment) ? (
              <>
                <img
                  src={
                    "https://namaste.bhu.edu.in" +
                    props.selectedMessage.attachment
                  }
                  alt="Attachment"
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                    cursor: "pointer",
                  }}
                  onClick={() => setOpen(true)} // Open the modal
                />
                <Modal
                  open={open}
                  onClose={() => setOpen(false)} // Close the modal
                  size="large"
                  closeIcon
                >
                  <Modal.Content
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%", // Fill the entire modal height
                    }}
                  >
                    <img
                      src={
                        "https://namaste.bhu.edu.in" +
                        props.selectedMessage.attachment
                      }
                      alt="Attachment"
                      style={{ maxWidth: "100%", maxHeight: "80vh" }}
                    />
                  </Modal.Content>
                </Modal>
              </>
            ) : (
              <>
                <Icon name="attach" />
                <a
                  href={
                    "https://namaste.bhu.edu.in" +
                    props.selectedMessage.attachment
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Attachment
                </a>
              </>
            )}
          </Card.Content>
        )}
      </Card.Content>
      {props.selectedMessage.sender_name && (
        <Card.Content extra>
          <Icon name="user" />
          {props.selectedMessage.sender_name} -{" "}
          {props.selectedMessage.sender_designation}
        </Card.Content>
      )}
    </Card>
  );
}
