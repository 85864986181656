import { Message, Header, Table, Segment, Icon } from "semantic-ui-react";
import { capitalizeName } from "./utils";

export default function ResultsTable({
  results,
  setResults,
  tableHeaders,
  extraDetailsHdrTxt,
  extraDetailsComponent,
}) {
  const handleSortingIcon = (sortingOrder, colName) => {
    const existingResults = results.data;
    sortingOrder === "d"
      ? existingResults.sort((a, b) =>
          ("" + a[colName]).toUpperCase() > ("" + b[colName]).toUpperCase()
            ? -1
            : 1
        )
      : existingResults.sort((a, b) =>
          ("" + a[colName]).toUpperCase() > ("" + b[colName]).toUpperCase()
            ? 1
            : -1
        );
    setResults({ ...results, data: existingResults });
  };

  const { data, msg } = results;

  if (data && data.length > 0) {
    
    // Means that we can show the data in a table format
    return (
      <>
        <Header as="h4" textAlign="right">
          {data.length} records found
        </Header>
        <Segment basic style={{ maxHeight: "50vh", overflowY: "scroll" }}>
          <Table celled size="large">
            <Table.Header
              style={{
                position: "sticky",
                top: "-16px",
                backgroundColor: "#f9fafb",
              }}
            >
              <Table.Row>
                {tableHeaders.map((tblHdr) => {
                  return (
                    <Table.HeaderCell key={tblHdr.key} width={tblHdr.width}>
                      {tblHdr.label}
                      <div
                        style={{
                          display: "flex",
                          float: "right",
                          padding: "0",
                          margin: "0",
                        }}
                      >
                        <Icon
                          link
                          name="arrow up"
                          onClick={() => handleSortingIcon("a", tblHdr.key)} // 'a' refers to descending
                        />
                        <Icon
                          link
                          name="arrow down"
                          onClick={() => handleSortingIcon("d", tblHdr.key)} // 'd' refers to ascending
                        />
                      </div>
                      <span style={{ clear: "both" }}></span>
                    </Table.HeaderCell>
                  );
                })}
                {extraDetailsHdrTxt ? (
                  <Table.HeaderCell width={2}>
                    {extraDetailsHdrTxt}
                  </Table.HeaderCell>
                ) : null}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data.map((rowData) => {
                return (
                  <Table.Row key={rowData.id}>
                    {tableHeaders.map((tblHdr, idx) => {
                      if (tblHdr.key === "name"||tblHdr.key === "faculty_name") {
                        return (
                          <Table.Cell key={idx}>
                            {rowData[tblHdr.key]
                              ? capitalizeName(rowData[tblHdr.key])
                              : "Not Available "}
                          </Table.Cell>
                        );
                      }
                      return (
                        <Table.Cell key={idx}>
                          {rowData[tblHdr.key] ? rowData[tblHdr.key] : "Not Available "}
                        </Table.Cell>
                      );
                    })}
                    {extraDetailsHdrTxt ? (
                      <Table.Cell style={{ color: 'blue' }} >{extraDetailsComponent(rowData)}</Table.Cell>
                    ) : null}
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </Segment>
      </>
    );
  } else {
    // Need to show the message
    return (
      <Message negative>
        <Message.Header>{msg}</Message.Header>
      </Message>
    );
  }
}
