import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Icon,
  Input,
  Dropdown,
  Message,
  Loader,
  // Popup,
} from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { fetchCounselees } from "./api";

const searchOptions = [
  { key: "name", text: "Counselee Name", value: "name" },
  { key: "counsellor_name", text: "Counsellor Name", value: "counsellor_name" },
  { key: "code", text: "Code", value: "code" },
];

const CounseleesList = () => {
  const [counselees, setCounselees] = useState([]);
  const [filteredCounselees, setFilteredCounselees] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchBy, setSearchBy] = useState(searchOptions[0].value); // default to the first option
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchCounselees();
        // Sort data in descending order based on code
        const sortedData = data.sort((a, b) => b.code.localeCompare(a.code));
        setCounselees(sortedData);
        setFilteredCounselees(sortedData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching counselees:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const filteredData = counselees.filter((counselee) => {
      const valueToSearch = counselee[searchBy];
      return valueToSearch?.toLowerCase().includes(searchQuery.toLowerCase());
    });
    setFilteredCounselees(filteredData);
  }, [searchQuery, searchBy, counselees]);

  // const handleEdit = (id) => {
  //   navigate(`/psy/counseling/edit/${id}`);
  // };

  // const handleView = (id) => {
  //   navigate(`/psy/counseling/view/${id}`);
  // };

  const handleProfile = (id) => {
    navigate(`/psy/counseling/profile/${id}`);
  };

  return (
    <div>
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}
      >
        <Input
          icon="search"
          placeholder={`Search by ${searchBy}`}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{ flex: 1, marginRight: "10px" }}
        />
        <Dropdown
          selection
          options={searchOptions}
          value={searchBy}
          onChange={(e, { value }) => setSearchBy(value)}
        />
      </div>
      {loading ? (
        <Loader active inline="centered" />
      ) : (
        <>
          {filteredCounselees.length > 0 ? (
            <div style={{ maxHeight: "60vh", overflowY: "auto" }}>
            <Table compact>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.HeaderCell>Roll No</Table.HeaderCell>
                  <Table.HeaderCell>Code</Table.HeaderCell>
                  <Table.HeaderCell>Counsellor Name</Table.HeaderCell>
                  <Table.HeaderCell>Actions</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {filteredCounselees.map((counselee) => (
                  <Table.Row key={counselee.id}>
                    <Table.Cell>{counselee.name}</Table.Cell>
                    <Table.Cell>{counselee.roll_no}</Table.Cell>
                    <Table.Cell>{counselee.code}</Table.Cell>
                    <Table.Cell>{counselee.counsellor_name}</Table.Cell>
                    <Table.Cell>
                      {/* <Button icon onClick={() => handleEdit(counselee.id)}>
                        <Icon name='edit' />
                      </Button> */}
                      {/* <Button icon onClick={() => handleView(counselee.id)}>
                        <Icon name='eye' />
                      </Button> */}
                      <Button onClick={() => handleProfile(counselee.id)} color="blue">
                        <Icon name="user" />
                        Profile
                      </Button>
                      {/* <Popup
                        size="tiny"
                        content="Profile"
                        trigger={
                          <Button icon onClick={() => handleProfile(counselee.id)}>
                            <Icon name="user" /> 
                            Profile
                          </Button>
                        }
                      /> */}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
            </div>
          ) : (
            <Message warning content="No data available" />
          )}
        </>
      )}
    </div>
  );
};

export default CounseleesList;
