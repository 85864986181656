import { addAuthHeaders, SERVER_URL, postOptions } from "../api";

export const fetchAttendanceData = async () => {
  const headers = await addAuthHeaders();
  const response = await fetch(`${SERVER_URL}/cseop/api/attendance/`, {
    headers,
  });
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};

export const fetchActiveAspirant = async () => {
  const headers = await addAuthHeaders();
  const response = await fetch(`${SERVER_URL}/cseop/api/active-aspirants/`, {
    headers,
  });
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};

export const submitAttendance = async (reqBody) => {
  const requestData = { ...postOptions };
  requestData.headers = {
    ...requestData.headers,
    ...(await addAuthHeaders()),
  };
  requestData.body = JSON.stringify(reqBody);
  return fetch(`${SERVER_URL}/cseop/api/attendance/`, requestData);
};
