import React from "react";
import { Modal, Statistic } from "semantic-ui-react";
import { Chart } from "react-google-charts";

export default function SrkGodModeAnalysisModal(props) {
  const filteredData = props.data || [];

  const count = filteredData.length;
  const today = new Date();

  // Calculate the start and end of today
  const startOfToday = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );
  const endOfToday = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + 1
  );

  // Filter the data for reports received today
  const reportsToday = filteredData.filter((item) => {
    const reportDate = new Date(item.report_date);
    return reportDate >= startOfToday && reportDate < endOfToday;
  }).length;

  // Calculate start and end of the current week
  const startOfWeek = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - ((today.getDay() + 6) % 7)
  ); // Get the date of the previous Monday
  const endOfWeek = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + (6 - today.getDay())
  ); // Get the date of the following Sunday

  const reportsThisWeek = filteredData.filter((item) => {
    const reportDate = new Date(item.report_date);
    return reportDate >= startOfWeek && reportDate <= endOfWeek;
  }).length;

  // Calculate start and end of the current month
  const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

  const reportsThisMonth = filteredData.filter((item) => {
    const reportDate = new Date(item.report_date);
    return reportDate >= startOfMonth && reportDate <= endOfMonth;
  }).length;

  // Prepare data for the daily report count chart
  const dailyReportCounts = filteredData.reduce((acc, item) => {
    const reportDate = item.report_date
      ? new Date(item.report_date)
      : new Date(item.time_of_sending);
    const dateKey = reportDate.toDateString();
    acc[dateKey] = acc[dateKey] ? acc[dateKey] + 1 : 1;
    return acc;
  }, {});

  // Extract the dates and counts into an array and sort by date
  const sortedData = Object.entries(dailyReportCounts).sort(
    ([dateA], [dateB]) => new Date(dateA) - new Date(dateB)
  );

  // Prepare the chart data with sorted dates
  const chartData = props.from === "Salary" ? [["Date", "Messages submitted"]] : [["Date", "Reports submitted"]];
  sortedData.forEach(([date, count]) => {
    chartData.push([date, count]);
  });

  const textToDisplay = props.from === "Salary" ? "Messages" : "Reports";

  return (
    <Modal
      open={props.isModalOpen}
      onClose={props.closeModal}
      closeIcon
      size="fullscreen"
    >
      <Modal.Header>{textToDisplay} Statistics</Modal.Header>
      <Modal.Content>
        <Statistic.Group widths="four" size="small">
          <Statistic>
            <Statistic.Value>{count}</Statistic.Value>
            <Statistic.Label>Total {textToDisplay}</Statistic.Label>
          </Statistic>
          <Statistic>
            <Statistic.Value>{reportsToday}</Statistic.Value>
            <Statistic.Label>Today</Statistic.Label>
          </Statistic>
          <Statistic>
            <Statistic.Value>{reportsThisWeek}</Statistic.Value>
            <Statistic.Label>This Week</Statistic.Label>
          </Statistic>
          <Statistic>
            <Statistic.Value>{reportsThisMonth}</Statistic.Value>
            <Statistic.Label>This Month</Statistic.Label>
          </Statistic>
        </Statistic.Group>
        {chartData.length > 1 && (
          <Chart
            width={"100%"}
            height={"300px"}
            chartType="ColumnChart"
            loader={<div>Loading Chart</div>}
            data={chartData}
            options={{
              title: `${textToDisplay} submitted each day`,
              chartArea: { width: "70%" },
              hAxis: {
                title: "Date",
                minValue: 0,
              },
              vAxis: {
                title: `Number of ${textToDisplay}`,
              },
            }}
          />
        )}
      </Modal.Content>
    </Modal>
  );
}
