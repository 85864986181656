import { generateCToken } from "./utils-adv";

export const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const postOptions = {
  method: "POST",
  headers: {
    "Content-Type": "application/json",
  },
};

async function addAuthHeaders() {
  const personId = localStorage.getItem("person_id");
  if (!personId) {
    return {
      Authorization: process.env.REACT_APP_AUTH_TOKEN,
      ctoken: await generateCToken(
        process.env.REACT_APP_NO_PERSON,
        process.env.REACT_APP_CTOKEN_KEY
      ),
    };
  }
  return {
    Authorization: process.env.REACT_APP_AUTH_TOKEN,
    ctoken: await generateCToken(personId, process.env.REACT_APP_CTOKEN_KEY),
  };
}

function addLoginHeaders() {
  return {
    Authorization: process.env.REACT_APP_AUTH_TOKEN,
  };
}

const login = async (reqBody) => {
  const requestData = { ...postOptions };
  requestData.body = JSON.stringify(reqBody);
  requestData.headers = {
    ...requestData.headers,
    ...addLoginHeaders(),
  };
  return fetch(`${SERVER_URL}/api/login/`, requestData);
};

const loginSendOTP = async (phone_number) => {
  return fetch(
    `${SERVER_URL}/people/api/send-otp/?phone_number=${phone_number}`,
    {
      headers: addLoginHeaders(),
    }
  );
};

const loginVerifyOTP = async (phone_number, otp) => {
  return fetch(
    `${SERVER_URL}/people/api/verify-otp/?phone_number=${phone_number}&otp=${otp}`,
    {
      headers: addLoginHeaders(),
    }
  );
};

const fetchHostelRooms = async (hostel_id) => {
  return fetch(`${SERVER_URL}/hms/api/hostel/${hostel_id}/rooms/`, {
    headers: await addAuthHeaders(),
  });
};

const fetchDepartmentsList = async () => {
  const requestData = {
    method: "GET",
    headers: await addAuthHeaders(),
  };
  return fetch(`${SERVER_URL}/acads/api/departments/`, requestData);
};

const createProgramFromDept = async (reqBody, deptId) => {
  const requestData = { ...postOptions };
  requestData.headers = {
    ...requestData.headers,
    ...(await addAuthHeaders()),
  };
  requestData.body = reqBody;
  return fetch(
    `${SERVER_URL}/acads/api/department/${deptId}/program/create/`,
    requestData
  );
};

const createCourseFromDept = async (reqBody, deptId) => {
  const requestData = { ...postOptions };
  requestData.headers = {
    ...requestData.headers,
    ...(await addAuthHeaders()),
  };
  requestData.body = reqBody;
  return fetch(
    `${SERVER_URL}/acads/api/department/${deptId}/course/create/`,
    requestData
  );
};

const fetchProgramsListForDepartment = async (deptId) => {
  const requestData = {
    method: "GET",
    headers: await addAuthHeaders(),
  };
  return fetch(
    `${SERVER_URL}/acads/api/department/${deptId}/programs/`,
    requestData
  );
};

const fetchProgramDetailsFromDept = async (deptId, programId) => {
  const requestData = {
    method: "GET",
    headers: await addAuthHeaders(),
  };
  return fetch(
    `${SERVER_URL}/acads/api/department/${deptId}/program/${programId}/`,
    requestData
  );
};

const fetchCoursesFromDept = async (deptId) => {
  const requestData = {
    method: "GET",
    headers: await addAuthHeaders(),
  };
  return fetch(
    `${SERVER_URL}/acads/api/department/${deptId}/courses/`,
    requestData
  );
};

const fetchCoursesFromProgram = async (deptId, programId) => {
  const requestData = {
    method: "GET",
    headers: await addAuthHeaders(),
  };
  return fetch(
    `${SERVER_URL}/acads/api/department/${deptId}/program/${programId}/courses/`,
    requestData
  );
};

const fetchCourseDetailsFromDept = async (deptId, courseId) => {
  const requestData = {
    method: "GET",
    headers: await addAuthHeaders(),
  };
  return fetch(
    `${SERVER_URL}/acads/api/department/${deptId}/course/${courseId}/`,
    requestData
  );
};

const fetchCourseDetailsFromProgram = async (deptId, programId, courseId) => {
  const requestData = {
    method: "GET",
    headers: await addAuthHeaders(),
  };
  return fetch(
    `${SERVER_URL}/acads/api/department/${deptId}/program/${programId}/course/${courseId}/`,
    requestData
  );
};

const fetchInstituteList = async () => {
  return fetch(`${SERVER_URL}/acads/api/institutes/`, {
    headers: await addAuthHeaders(),
  });
};

const fetchInstituteDetails = async (institute_id) => {
  return fetch(`${SERVER_URL}/acads/api/institute/${institute_id}/`, {
    headers: await addAuthHeaders(),
  });
};

const fetchDepartmentList = async () => {
  return fetch(`${SERVER_URL}/acads/api/departments/`, {
    headers: await addAuthHeaders(),
  });
};

const fetchDepartmentDetails = async (deptId) => {
  return fetch(`${SERVER_URL}/acads/api/department/${deptId}/`, {
    headers: await addAuthHeaders(),
  });
};

const fetchFacultyList = async (deptId) => {
  return fetch(`${SERVER_URL}/people/api/faculty-list/${deptId}/`, {
    headers: await addAuthHeaders(),
  });
};

const fetchProgramsList = async () => {
  return fetch(`${SERVER_URL}/acads/api/programs/`, {
    headers: await addAuthHeaders(),
  });
};

const fetchProgramDetails = async (programId) => {
  return fetch(`${SERVER_URL}/acads/api/program/${programId}/`, {
    headers: await addAuthHeaders(),
  });
};

const fetchCoursesList = async () => {
  return fetch(`${SERVER_URL}/acads/api/courses/`, {
    headers: await addAuthHeaders(),
  });
};

const fetchCourseDetails = async (courseId) => {
  return fetch(`${SERVER_URL}/acads/api/course/${courseId}/`, {
    headers: await addAuthHeaders(),
  });
};

const fetchFacultiesList = async () => {
  return fetch(`${SERVER_URL}/acads/api/faculties/`, {
    headers: await addAuthHeaders(),
  });
};

const fetchFacultiesDetails = async (facultyId) => {
  return fetch(`${SERVER_URL}/acads/api/faculty/${facultyId}/`, {
    headers: await addAuthHeaders(),
  });
};

const groupSearch = async (queryset) => {
  const requestData = {
    method: "GET",
    headers: await addAuthHeaders(),
  };
  return fetch(
    `${SERVER_URL}/note/api/groups/search/?${queryset}`,
    requestData
  );
};

const fetchGroupDetails = async (groupId) => {
  return fetch(`${SERVER_URL}/note/api/group/${groupId}/`, {
    headers: await addAuthHeaders(),
  });
};

const fetchNotificationsList = async () => {
  return fetch(`${SERVER_URL}/note/api/notifications/`, {
    headers: await addAuthHeaders(),
  });
};

const fetchNotificationsListForGroup = async (groupId) => {
  const requestData = {
    method: "GET",
    headers: await addAuthHeaders(),
  };
  return fetch(
    `${SERVER_URL}/note/api/group/${groupId}/notifications/`,
    requestData
  );
};

const programSearch = async (queryset) => {
  return fetch(`${SERVER_URL}/acads/api/programs/search/?${queryset}`, {
    headers: await addAuthHeaders(),
  });
};

const courseSearch = async (queryset) => {
  return fetch(`${SERVER_URL}/acads/api/courses/search/?${queryset}`, {
    headers: await addAuthHeaders(),
  });
};

const fetchGroupsListFromNotifications = async () => {
  const requestData = {
    method: "GET",
    headers: await addAuthHeaders(),
  };
  return fetch(`${SERVER_URL}/note/api/groups/`, requestData);
};

const fetchAuthFacAndAdhoc = async (person_id) => {
  return fetch(`${SERVER_URL}/note/api/person-2-authfac/${person_id}/`, {
    headers: await addAuthHeaders(),
  });
};

const sendNotificationToAdHoc = async (formData) => {
  return fetch(`${SERVER_URL}/note/api/notification/create-adhoc/`, {
    method: "POST",
    headers: await addAuthHeaders(),
    body: formData,
  });
};

const fetchGroupsListForAuthFac = async (authFacId, headerOptions) => {
  return fetch(`${SERVER_URL}/note/api/authfac/${authFacId}/groups/`, {
    headers: await addAuthHeaders(),
  });
};

const fetchSectionForSalaryComplaints = async () => {
  return fetch(`${SERVER_URL}/salary/api/sections/`, {
    headers: await addAuthHeaders(),
  });
};

const createNotification = async (requestBody, headerOptions) => {
  const postHeaderOptions = {
    ...headerOptions,
    ...(await addAuthHeaders()),
  };
  return fetch(`${SERVER_URL}/note/api/notification/create/`, {
    method: "POST",
    headers: postHeaderOptions,
    body: requestBody,
  });
};

const createSalaryComplaint = async (requestBody, headerOptions) => {
  const postHeaderOptions = {
    ...headerOptions,
    ...(await addAuthHeaders()),
  };
  return fetch(`${SERVER_URL}/salary/api/complaint/create/`, {
    method: "POST",
    headers: postHeaderOptions,
    body: requestBody,
  });
};

const markNotificationAsRead = async (person_id, notification_id) => {
  const url = SERVER_URL + "/note/api/notification/mark-read/";
  const data = {
    person_id: person_id,
    notification_id: notification_id,
  };

  const fetchOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...(await addAuthHeaders()),
    },
    body: JSON.stringify(data),
  };
  fetch(url, fetchOptions)
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((data) => {
      // console.log('Success:', data); // Handling success
    })
    .catch((error) => {
      console.error("Error:", error); // Handling errors
    });
};

const facultySearch = async (name, department) => {
  const params = new URLSearchParams({ name, department }).toString();
  return fetch(`${SERVER_URL}/people/api/faculty/search/?${params}`, {
    headers: await addAuthHeaders(),
  });
};

const telePhoneSearch = async (name, department, designation, phoneNumber) => {
  const params = new URLSearchParams({
    name,
    department,
    designation,
    phone_number: phoneNumber,
  }).toString();
  return fetch(`${SERVER_URL}/teldir/api/contacts/search/?${params}`, {
    headers: await addAuthHeaders(),
  });
};

const fetchStudentIdFromPersonId = async (personId) => {
  const requestData = {
    method: "GET",
    headers: await addAuthHeaders(),
  };
  return fetch(
    `${SERVER_URL}/people/api/person-2-stu/${personId}/`,
    requestData
  );
};

const fetchFacultyIdFromPersonId = async (personId) => {
  const requestData = {
    method: "GET",
    headers: await addAuthHeaders(),
  };
  return fetch(
    `${SERVER_URL}/people/api/person-2-fac/${personId}/`,
    requestData
  );
};

const fetchGroupsListForFaculty = async () => {
  const requestData = {
    method: "GET",
    headers: await addAuthHeaders(),
  };
  return fetch(
    `${SERVER_URL}/note/api/faculty/${window.localStorage.getItem(
      "pTId"
    )}/groups/`,
    requestData
  );
};

const getInboxMessages = async (id) => {
  return fetch(`${SERVER_URL}/pn/api/peep/${id}/notes/`, {
    headers: await addAuthHeaders(),
  });
};

const getOtInboxMessages = async (id) => {
  return fetch(`${SERVER_URL}/pn/api/peep/${id}/otnotes/`, {
    headers: await addAuthHeaders(),
  });
};

const fetchGroupsListForStudent = async () => {
  const requestData = {
    method: "GET",
    headers: await addAuthHeaders(),
  };
  return fetch(
    `${SERVER_URL}/note/api/student/${window.localStorage.getItem(
      "pTId"
    )}/groups/`,
    requestData
  );
};

const fetchHolidays = async () => {
  const requestData = {
    method: "GET",
    headers: await addAuthHeaders(),
  };
  return fetch(`${SERVER_URL}/api/holidays/`, requestData);
};

const fetchSentNotification = async () => {
  const authfac_id = localStorage.getItem("authfac_id");
  const requestData = {
    method: "GET",
    headers: await addAuthHeaders(),
  };
  return fetch(
    `${SERVER_URL}/note/api/notifications-sent/${authfac_id}/`,
    requestData
  );
};

const fetchSentSalaryComplaint = async () => {
  const complaint_salary_employee_id = localStorage.getItem(
    "complaint_salary_employee_id"
  );
  const requestData = {
    method: "GET",
    headers: await addAuthHeaders(),
  };
  return fetch(
    `${SERVER_URL}/salary/api/complaints-sent/${complaint_salary_employee_id}/`,
    requestData
  );
};

const fetchAdminSalaryComplaint = async () => {
  const complaint_salary_admin_id = localStorage.getItem(
    "complaint_salary_admin_id"
  );
  const requestData = {
    method: "GET",
    headers: await addAuthHeaders(),
  };
  return fetch(
    `${SERVER_URL}/salary/api/admin-complaints/${complaint_salary_admin_id}/`,
    requestData
  );
};

const createComment = async (payload) => {
  return fetch(`${SERVER_URL}/salary/api/comment/create/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...(await addAuthHeaders()),
    },
    body: JSON.stringify(payload),
  });
};

const createCommentSRK = async (payload) => {
  return fetch(`${SERVER_URL}/srk/api/comment/create/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...(await addAuthHeaders()),
    },
    body: JSON.stringify(payload),
  });
};

const createCommentHostel = async (payload) => {
  return fetch(`${SERVER_URL}/hostel/api/comment/create/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...(await addAuthHeaders()),
    },
    body: JSON.stringify(payload),
  });
};

const returnSrkAdminMetaData = async (srk_admin_id) => {
  // const personId = localStorage.getItem("person_id");
  return await fetch(
    `https://namaste.bhu.edu.in/srk/api/admin-reports-meta/${srk_admin_id}/`,
    {
      headers: await addAuthHeaders(),
    }
  );
};

const fetchSrkAdminReports = async (srkid, page) => {
  // const personId = localStorage.getItem("person_id");
  return fetch(
    `https://namaste.bhu.edu.in/srk/api/test-reports/?page=${page}`,
    {
      headers: await addAuthHeaders(),
    }
  );
};

const fetchHostelAdminReports = async () => {
  const hostel_admin_id = localStorage.getItem("hostel_admin_id");
  const requestData = {
    method: "GET",
    headers: await addAuthHeaders(),
  };
  return fetch(
    `${SERVER_URL}/hostel/api/admin-reports/${hostel_admin_id}/`,
    requestData
  );
};

const fetchHostelSentReports = async () => {
  const hostel_warden_id = localStorage.getItem("hostel_warden_id");
  const requestData = {
    method: "GET",
    headers: await addAuthHeaders(),
  };
  return fetch(
    `${SERVER_URL}/hostel/api/reports-sent/${hostel_warden_id}/`,
    requestData
  );
};

const fetchSrkReportDetails = async (reportId) => {
  const requestData = {
    method: "GET",
    headers: await addAuthHeaders(),
  };
  return fetch(`${SERVER_URL}/srk/api/report/${reportId}/`, requestData);
};

const fetchSalaryComplaintReportDetails = async (reportId) => {
  const requestData = {
    method: "GET",
    headers: await addAuthHeaders(),
  };
  return fetch(`${SERVER_URL}/salary/api/complaint/${reportId}/`, requestData);
};

const fetchHostelReportDetails = async (reportId) => {
  const requestData = {
    method: "GET",
    headers: await addAuthHeaders(),
  };
  return fetch(`${SERVER_URL}/hostel/api/report/${reportId}/`, requestData);
};

const person2Fellow = async (personId) => {
  const requestData = {
    method: "GET",
    headers: await addAuthHeaders(),
  };
  return fetch(
    `${SERVER_URL}/srk/api/person-2-fellow/${personId}/`,
    requestData
  );
};

const person2Admin = async (personId) => {
  const requestData = {
    method: "GET",
    headers: await addAuthHeaders(),
  };
  return fetch(
    `${SERVER_URL}/srk/api/person-2-admin/${personId}/`,
    requestData
  );
};

const checkHostelUserRole = async () => {
  const personId = localStorage.getItem("person_id");
  if (!personId) {
    console.error("person_id not found in local storage.");
    return;
  }
  const wardenApiUrl = `https://namaste.bhu.edu.in/hostel/api/person-2-warden/${personId}/`;
  const adminApiUrl = `https://namaste.bhu.edu.in/hostel/api/person-2-admin/${personId}/`;

  try {
    const wardenResponse = await fetch(wardenApiUrl, {
      headers: await addAuthHeaders(),
    });
    if (!wardenResponse.ok) {
      throw new Error(
        `Failed to fetch warden_id: ${wardenResponse.statusText}`
      );
    }
    const wardenData = await wardenResponse.json();
    const wardenId = wardenData.warden_id;
    localStorage.setItem("hostel_warden_id", wardenId);
  } catch (error) {
    console.log(error);
  }
  try {
    const adminResponse = await fetch(adminApiUrl, {
      headers: await addAuthHeaders(),
    });
    if (!adminResponse.ok) {
      throw new Error(`Failed to fetch admin_id: ${adminResponse.statusText}`);
    }
    const adminData = await adminResponse.json();
    const adminId = adminData.admin_id;
    localStorage.setItem("hostel_admin_id", adminId);
  } catch (error) {
    console.log(error);
  }
};

const checkEmployeeRoleForComplaint = async () => {
  const personId = localStorage.getItem("person_id");
  if (!personId) {
    console.error("person_id not found in local storage.");
    return;
  }
  const wardenApiUrl = `https://namaste.bhu.edu.in/salary/api/person-2-employee/${personId}/`;
  const adminApiUrl = `https://namaste.bhu.edu.in/salary/api/person-2-admin/${personId}/`;

  try {
    const wardenResponse = await fetch(wardenApiUrl, {
      headers: await addAuthHeaders(),
    });
    if (!wardenResponse.ok) {
      throw new Error(
        `Failed to fetch warden_id: ${wardenResponse.statusText}`
      );
    }
    const wardenData = await wardenResponse.json();
    const wardenId = wardenData.employee_id;
    localStorage.setItem("complaint_salary_employee_id", wardenId);
  } catch (error) {
    console.log(error);
  }
  try {
    const adminResponse = await fetch(adminApiUrl, {
      headers: await addAuthHeaders(),
    });
    if (!adminResponse.ok) {
      throw new Error(`Failed to fetch admin_id: ${adminResponse.statusText}`);
    }
    const adminData = await adminResponse.json();
    const adminId = adminData.admin_id;
    localStorage.setItem("complaint_salary_admin_id", adminId);
  } catch (error) {
    console.log(error);
  }
};
const submitSuggestion = async (personId, suggestion) => {
  const headers = await addAuthHeaders();
  const response = await fetch(`${SERVER_URL}/api/suggestions/`, {
    method: "POST",
    headers,
    body: JSON.stringify({
      person: personId,
      suggestion_body: suggestion,
    }),
  });

  if (!response.ok) {
    throw new Error("Failed to submit the suggestion.");
  }

  return response.json();
};

const fetchUserData = async (personId) => {
  const headers = await addAuthHeaders();
  const response = await fetch(`${SERVER_URL}/people/api/person/${personId}/`, {
    headers,
  });

  if (!response.ok) {
    throw new Error("Failed to fetch user data.");
  }

  return response.json();
};
// const pwbsFeedback = async (formData) => {
//   const requestData = { ...postOptions };
//   requestData.headers = {
//     ...requestData.headers,
//     ...(await addAuthHeaders()),
//   };
//   // Ensure headers do not include 'Content-Type' as FormData will automatically set it
//   delete requestData.headers['Content-Type'];
//   requestData.body = formData;

//   return fetch(`${SERVER_URL}/your-api-endpoint/feedback/submit/`, requestData);
// };

export const fetchFacultyCourses = (person_id) => {
  return fetch(
    `https://namaste.bhu.edu.in/acads/api/faculty-course-map/?person_id=${person_id}`
  );
};

export const fetchCourses = () => {
  return fetch(`https://namaste.bhu.edu.in/acads/api/courses/`);
};

// Add this function
export const updateFacultyCourses = (payload) => {
  return fetch(`https://namaste.bhu.edu.in/acads/api/faculty-course-map/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};

const updateCourseDetails = async (courseId, formData) => {
  try {
    const response = await fetch(
      `${SERVER_URL}/acads/api/course/${courseId}/update/`,
      {
        method: "PATCH",
        headers: await addAuthHeaders(),
        body: formData,
      }
    );

    if (!response.ok) {
      console.log("Failed to update course details.");
    }

    return response.json();
  } catch (error) {
    console.log(error);
    throw new Error("An error occurred while updating course details.");
  }
};

const getCounsellorStatus = async (psy_counsellor_id) => {
  try {
    const response = await fetch(
      `${SERVER_URL}/pwbs/api/counsellors/${psy_counsellor_id}/status/`,
      {
        headers: await addAuthHeaders(),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to get counsellor status.");
    }

    return response.json();
  } catch (error) {
    console.log(error);
    throw new Error("An error occurred while getting counsellor status.");
  }
};

const getLastLoginLogs = async (page) => {
  const personId = localStorage.getItem("person_id");
  if (isUserAdmin(personId)) {
    return fetch(`${SERVER_URL}/pn/api/last-login/?page=${page}`, {
      headers: await addAuthHeaders(),
    });
  } else {
    return null;
  }
};

const isUserAdmin = () => {
  const personId = localStorage.getItem("person_id");
  if (!personId) {
    return false;
  }
  const adminIds = process.env.REACT_APP_USER_ADMIN;
  if (!adminIds) {
    return false;
  }
  const adminIdArray = adminIds.split(",").map((id) => id.trim());
  return adminIdArray.includes(String(personId)) ? true : false;
};

const isUserCSEOPAdmin = () => {
  const personId = localStorage.getItem("person_id");
  if (!personId) {
    return false;
  }
  const adminIds = process.env.REACT_APP_CSEOP_ADMIN;
  if (!adminIds) {
    return false;
  }
  const adminIdArray = adminIds.split(",").map((id) => id.trim());
  return adminIdArray.includes(String(personId)) ? true : false;
};

const getAllNotificationList = async (page) => {
  const personId = localStorage.getItem("person_id");
  if (isUserAdmin(personId)) {
    return fetch(`${SERVER_URL}/note/api/admin-notes/?page=${page}`, {
      headers: await addAuthHeaders(),
    });
  } else {
    return null;
  }
};

const getNotificationDetails = async (notificationId) => {
  const personId = localStorage.getItem("person_id");
  if (isUserAdmin(personId)) {
    return fetch(`${SERVER_URL}/note/api/notification/${notificationId}/`, {
      headers: await addAuthHeaders(),
    });
  } else {
    return null;
  }
}


export {
  login,
  loginSendOTP,
  loginVerifyOTP,
  fetchHostelRooms,
  fetchDepartmentsList,
  createProgramFromDept,
  createCourseFromDept,
  fetchProgramsList,
  fetchProgramsListForDepartment,
  fetchProgramDetailsFromDept,
  fetchCoursesFromDept,
  fetchCoursesFromProgram,
  fetchCourseDetailsFromDept,
  fetchCourseDetailsFromProgram,
  fetchInstituteList,
  fetchInstituteDetails,
  fetchDepartmentList,
  fetchDepartmentDetails,
  fetchProgramDetails,
  fetchCoursesList,
  fetchCourseDetails,
  groupSearch,
  fetchGroupDetails,
  fetchNotificationsList,
  fetchNotificationsListForGroup,
  programSearch,
  courseSearch,
  fetchGroupsListFromNotifications,
  fetchGroupsListForAuthFac,
  createNotification,
  fetchStudentIdFromPersonId,
  fetchFacultyIdFromPersonId,
  fetchGroupsListForFaculty,
  fetchGroupsListForStudent,
  fetchHolidays,
  fetchFacultiesList,
  getInboxMessages,
  fetchFacultiesDetails,
  fetchFacultyList,
  facultySearch,
  telePhoneSearch,
  fetchAuthFacAndAdhoc,
  sendNotificationToAdHoc,
  fetchSentNotification,
  fetchSentSalaryComplaint,
  fetchAdminSalaryComplaint,
  createComment,
  createCommentSRK,
  createCommentHostel,
  returnSrkAdminMetaData,
  fetchSrkAdminReports,
  fetchHostelAdminReports,
  fetchHostelSentReports,
  fetchSrkReportDetails,
  fetchSalaryComplaintReportDetails,
  fetchHostelReportDetails,
  person2Fellow,
  person2Admin,
  checkHostelUserRole,
  checkEmployeeRoleForComplaint,
  markNotificationAsRead,
  fetchSectionForSalaryComplaints,
  createSalaryComplaint,
  getOtInboxMessages,
  addAuthHeaders,
  submitSuggestion,
  fetchUserData,
  updateCourseDetails,
  getCounsellorStatus,
  getLastLoginLogs,
  isUserAdmin,
  getAllNotificationList,
  getNotificationDetails,
  isUserCSEOPAdmin,
};
