import React, { useEffect, useState } from "react";
import { Container, Header, Menu, Segment } from "semantic-ui-react";
import "../Css/Inbox.css";

import { Inbox } from "../Components/Notification/Inbox";
import { Sent } from "../Components/Notification/Sent";
import { CreateForm } from "../Components/Notification/CreateForm";
import MultiPartForm from "../Components/Forms/MultiPartForm";

import { useAuth } from "../Context/AuthContext";
import Navbar from "../Components/Navbar";
import SearchModal from "../Components/Modals/SearchModal";
import { useLocation } from "react-router-dom";

export default function Notifications() {
  const [activeItem, setActiveItem] = useState("inbox");
  const [activeCreateItem, setActiveCreateItem] = useState("createForm");
  const handleItemClick = (name) => setActiveItem(name);
  const handleCreateItemClick = (name) => setActiveCreateItem(name);
  const person_id = localStorage.getItem("person_id");
  const authfac_id = localStorage.getItem("authfac_id");
  const adhoc_id = localStorage.getItem("adhoc_group");
  let location = useLocation();
  const[selectedMessage, setSelectedMessage] = useState("")

  useEffect(() => {
    // console.log("location.state", location.state);

    location.state && setSelectedMessage(location.state.selectedMessage)
  }, [location]);

  const { logout } = useAuth();
  const [isModalOpen, setModalOpen] = useState(false);
  const closeModal = () => setModalOpen(false);

  return (
    <>
      <Navbar onLogout={logout} setModalOpen2={setModalOpen} />
      <SearchModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <Container fluid style={{ paddingLeft: "5vw", paddingRight: "5vw" }}>
        <div style={{ paddingTop: "90px" }}>
          <Header
            as={"h2"}
            style={{ fontFamily: "Times New Roman, Georgia, serif" }}
          >
            Notifications
          </Header>
          <Menu
            pointing
            style={authfac_id ? { display: "flex" } : { display: "none" }}
          >
            <Menu.Item
              name="inbox"
              active={activeItem === "inbox"}
              onClick={() => handleItemClick("inbox")}
            />
            {authfac_id && (
              <>
                <Menu.Item
                  name="sent"
                  active={activeItem === "sent"}
                  onClick={() => handleItemClick("sent")}
                />
                <Menu.Item
                  name="create"
                  active={activeItem === "create"}
                  onClick={() => handleItemClick("create")}
                />
              </>
            )}
          </Menu>

          <Segment>
            {/* {console.log("from notification", xyz)} */}
            {activeItem === "inbox" && <Inbox person_id={person_id} selectedMessage={selectedMessage}/>}
            {authfac_id && activeItem === "sent" && (
              <Sent authfac_id={authfac_id} />
            )}
            {authfac_id && activeItem === "create" && (
              <>
                <Menu pointing secondary>
                  <Menu.Item
                    name="Groups"
                    active={activeCreateItem === "createForm"}
                    onClick={() => handleCreateItemClick("createForm")}
                  />
                  {adhoc_id !== "null" && adhoc_id && (
                    <Menu.Item
                      name="Adhoc Groups"
                      active={activeCreateItem === "multiPartForm"}
                      onClick={() => handleCreateItemClick("multiPartForm")}
                    />
                  )}
                </Menu>
                {activeCreateItem === "createForm" && <CreateForm />}
                {adhoc_id !== "null" && adhoc_id && activeCreateItem === "multiPartForm" && (
                  <MultiPartForm />
                )}
              </>
            )}
          </Segment>
        </div>
      </Container>
    </>
  );
}
