import React from 'react'
import { Segment } from 'semantic-ui-react'

export default function NotificationSegment() {
    const style = {
        height: "100%",
        width: "100%",
        // border: "3px dashed black",
        display: "flex",
        justifyContent: "center", 
        alignItems: "center", 
        backgroundColor: "#f2f2f2", 
        color: "black",
        fontSize: "1.5rem", 
        borderRadius:"15px"
      };
  return (
    <Segment style={style}>
            Select a notification to view in more detail.
          </Segment>
  )
}
