import React, { useState, useEffect } from "react";
import {
  Form,
  Modal,
  Input,
  Icon,
  Message,
  Container,
  Header,
  Button,
  Loader,
  Label,
} from "semantic-ui-react";
import CryptoJS from "crypto-js";
import { useParams } from "react-router-dom";
// import Navbar from "../Navbar";
// import SearchModal from "../Modals/SearchModal";
// import { useAuth } from "../../Context/AuthContext";
import "./PsyForm.css"; // Make sure to create and import the CSS file
import FileUpload from "./FileUpload";
import { fetchCounseleeData } from "./api"; // Import the fetchCounseleeData function from api.js
import Watermark from "@uiw/react-watermark";

const DisplayData = () => {
  const { counselee_id } = useParams();
  const [loading, setLoading] = useState(true); // Add loading state
  const [encryptedData, setEncryptedData] = useState(null);
  const [caseSheetId, setCaseSheetId] = useState(null);
  const [apiData, setApiData] = useState({});
  const [passwordModalOpen, setPasswordModalOpen] = useState(false); // Initially false
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState(false);
  const [decryptedData, setDecryptedData] = useState(null);
  const [caseSheetCloseStatus, setCaseSheetCloseStatus] = useState(false);
  const [showData, setShowData] = useState(false); // New state to manage the visibility of data
  const userName = localStorage.getItem("name");
  const userId = localStorage.getItem("person_id");
  const userMob = localStorage.getItem("mob")
  // const { logout } = useAuth();
  // const [isModalOpen, setModalOpen] = useState(false);
  // const closeModal = () => setModalOpen(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchCounseleeData(counselee_id);
        if (data.case_sheets[0] && data.case_sheets[0].body) {
          setEncryptedData(data.case_sheets[0].body);
          setCaseSheetId(data.case_sheets[0].id); // Assuming the API response includes an ID for the case sheet
          setCaseSheetCloseStatus(data.case_sheets[0].close);
          setApiData({
            name: data.name || "",
            caseCode: data.case_sheets[0].case_code || "",
            studentCode: data.code || "",
          });
        } else {
          setEncryptedData(null);
        }
        setLoading(false); // Set loading to false after data is fetched
      } catch (error) {
        console.error("Error fetching counselee data:", error);
        setLoading(false); // Ensure loading is set to false in case of an error
      }
    };

    fetchData();
  }, [counselee_id]);

  const decryptData = (encryptedData, password) => {
    try {
      const bytes = CryptoJS.AES.decrypt(encryptedData, password);
      const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
      return JSON.parse(decryptedData);
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    const decryptedData = decryptData(encryptedData, password);
    if (decryptedData) {
      setDecryptedData(decryptedData);
      setPasswordModalOpen(false);
      setShowData(true); // Show the data after successful password entry
    } else {
      setPasswordErrorMessage(true);
      setTimeout(() => setPasswordErrorMessage(false), 3000);
    }
  };


  const handleCloseModal = () => {
    setPasswordModalOpen(false);
  };

  const handleViewDataClick = () => {
    setPasswordModalOpen(true); // Open the password modal on button click
  };

  const renderField = (label, field) => {
    const formattedText =
      decryptedData[field] &&
      decryptedData[field].split("\n").map((item, key) => {
        return (
          <span key={key}>
            {item}
            <br />
          </span>
        );
      });
    return decryptedData[field] ? (
      <Form.Field>
        <label>{label}</label>
        <Message className="message-box">
          <p>{formattedText}</p>
        </Message>
      </Form.Field>
    ) : null;
  };

  const renderArrayField = (label, arrayName, fields) => {
    return decryptedData[arrayName] && decryptedData[arrayName].length > 0 ? (
      <div>
        <Header as="h4">{label}</Header>
        {decryptedData[arrayName].map((item, index) => (
          <Message key={index} className="message-box">
            {fields.map((field) => (
              <p key={field.name}>
                {field.label}: {item[field.name]}
              </p>
            ))}
          </Message>
        ))}
      </div>
    ) : null;
  };

  const renderSection = (sectionTitle, fields) => {
    const hasData = fields.some(([label, field, type]) => {
      if (type === "array") {
        return decryptedData[field] && decryptedData[field].length > 0;
      }
      return decryptedData[field];
    });

    return hasData ? (
      <div>
        <Header style={{ paddingTop: "1rem" }} as="h3">
          {sectionTitle}
        </Header>
        {sectionTitle === "Session Notes" && (
          <FileUpload caseSheetId={caseSheetId} />
        )}
        {fields.map(([label, field, type, arrayFields]) =>
          type === "array"
            ? renderArrayField(label, field, arrayFields)
            : renderField(label, field)
        )}
      </div>
    ) : null;
  };

  return (
    <>
      {loading ? (
        <Container textAlign="center" style={{ paddingTop: "2rem" }}>
          <Loader active inline="centered" size="large">
            Loading...
          </Loader>
        </Container>
      ) : (
        <>
          {!encryptedData && (
            <Container textAlign="center" style={{ paddingTop: "2rem" }}>
              <Message warning>Casesheet not yet created</Message>
            </Container>
          )}
          {encryptedData &&
            !showData && ( // Show the button only if showData is false and encryptedData is not null
              <Container textAlign="center" style={{ paddingTop: "2rem" }}>
                <Button primary onClick={handleViewDataClick}>
                  <Icon name="eye" />
                  View Data
                </Button>
              </Container>
            )}
          {showData && decryptedData ? (
            // <Watermark
            //   content={[userName, userMob + userId]}
            //   multiple={true}
            //   fontColor="rgba(0,0,0,0.12)"
            //   // rotate={45}
            //   gapY={100}
            //   gapX={10}
            //   width={"100%"}
            //   height={10}
            //   fontSize={15}
            // >
            <>
                        {caseSheetCloseStatus && <Label ribbon color={"red"}> Closed </Label>}
              <Form>
                {renderSection("Case Information", [
                  ["Case Code", "caseCode"],
                  ["Student Code", "studentCode"],
                  ["Name", "name"],
                  ["Date of Birth", "dateOfBirth"],
                  ["Age", "age"],
                  ["Gender", "gender"],
                  ["Institute/Faculty/College/School/Other", "institute"],
                  ["Contact No. & Email", "contactNoEmail"],
                  ["Emergency Contact number", "emergencyContact"],
                ])}
                {renderSection("Section A - Present Status", [
                  ["Major Concern in Client’s Life", "majorConcern"],
                  ["Severity of Issue", "severity"],
                  [
                    "Presenting Symptoms",
                    "presentingSymptoms",
                    "array",
                    [
                      { name: "symptom", label: "Symptom" },
                      { name: "duration", label: "Duration" },
                    ],
                  ],
                  ["Tentative DSM V Classification", "dsmVClassification"],
                  ["Manifest Personality Traits", "personalityTraits"],
                  ["Body Status", "bodyStatus"],
                  ["Thinking Traits", "thinkingTraits"],
                  ["Emotional Traits", "emotionalTraits"],
                ])}
                {renderSection("Client History", [
                  ["Biological Features", "biologicalFeatures"],
                  ["Medical History", "medicalHistory"],
                  ["Psychiatric History", "psychiatricHistory"],
                  ["Family Psychiatric History", "familyPsychiatricHistory"],
                  ["Counseling History", "counselingHistory"],
                ])}
                {renderSection("Present Medical Status", [
                  ["Present Medical Status", "presentMedicalStatus"],
                  ["Biological", "biological"],
                  ["Psychiatric", "psychiatric"],
                  [
                    "Any Medicines Being Presently Taken",
                    "anyMedicinesBeingPresentlyTaken",
                  ],
                ])}
                {renderSection("Overall Social Status", [
                  ["Overall Social Status", "overallSocialStatus"],
                  ["Group/Peer Membership", "groupPeerMembership"],
                  ["Family Relationships", "familyRelationships"],
                  ["Support System", "supportSystem"],
                  ["Marital Status", "maritalStatus"],
                  [
                    "Participation in Extra curricular activities",
                    "extraCurricular",
                  ],
                  ["Hobbies & Interests", "hobbies"],
                  ["Major Strength and Coping Potential", "strengths"],
                ])}
                {renderSection("(If needed be) for Academic Counseling", [
                  [
                    "Education",
                    "education",
                    "array",
                    [
                      { name: "level", label: "Exam Level" },
                      { name: "marks", label: "Marks" },
                      {
                        name: "selfSatisfaction",
                        label: "Self Satisfaction with Marks",
                      },
                      {
                        name: "parentSatisfaction",
                        label: "Parents Satisfaction with Marks",
                      },
                    ],
                  ],
                  ["Self-Expectations and Goal", "selfExpectations"],
                  [
                    "Parental Expectations and Performance gap (if any)",
                    "parentalExpectations",
                  ],
                  [
                    "Evaluation of School and College Life",
                    "schoolCollegeEvaluation",
                  ],
                ])}
                {renderSection("Formulation of Case and Plan of Treatment", [
                  ["Case Plan", "casePlan"],
                ])}
                {renderSection("Session Notes", [
                  ["Session Date", "sessionDate"],
                  ["Presenting problems", "sessionProblem"],
                  ["Interventions used", "sessionInterventions"],
                  ["Changes in the client", "sessionChange"],
                ])}
                {renderSection("Closure Notes", [
                  ["Closure notes (when issue closed)", "closureNotes"],
                ])}
                {renderSection("Termination Notes", [
                  ["Name of Counsellor", "counselorName"],
                  [
                    "Termination notes (when student leaves)",
                    "terminationNotes",
                  ],
                  ["Name & Signature of Counsellor", "counselorSignature"],
                ])}
                {renderSection("Extra", [["Extra Notes", "extra"]])}
              </Form>
              </>
            // </Watermark>
          ) : (
            <Modal open={passwordModalOpen}>
              <Modal.Header>
                Enter Password to Decrypt Data
                <Button
                  floated="right"
                  icon="close"
                  onClick={handleCloseModal}
                />
              </Modal.Header>
              <Modal.Content>
                <Form onSubmit={handlePasswordSubmit}>
                  <Form.Field>
                    <label>Password</label>
                    <Input
                      type={passwordVisible ? "text" : "password"}
                      icon={
                        <Icon
                          name={passwordVisible ? "eye slash" : "eye"}
                          link
                          onClick={() => setPasswordVisible(!passwordVisible)}
                        />
                      }
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Form.Field>
                  <Button type="submit" primary>
                    Submit
                  </Button>
                </Form>
                {passwordErrorMessage && (
                  <Message
                    error
                    header="Error"
                    content="Invalid password. Please try again."
                  />
                )}
              </Modal.Content>
            </Modal>
          )}
        </>
      )}
    </>
  );
};

export default DisplayData;
