import React from "react";
import { Container, Segment } from "semantic-ui-react";

const PhoneSkin = ({ children }) => {
  const phoneStyle = {
    height: "80vh",
    minWidth:"275px",
    maxWidth: "375px",
    margin: "0 auto",
    border: "16px solid black",
    borderRadius: "36px",
    padding: "0px",
    boxShadow: "0 0 20px rgba(0,0,0,0.3)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <Container>
      <Segment style={phoneStyle}>{children}</Segment>
    </Container>
  );
};

export default PhoneSkin;
