import React, { useEffect, useState } from "react";
import { Accordion, Icon, Table } from "semantic-ui-react";
import { fetchHolidays } from "../Components/api";

const HolidayDropdowns = ({count}) => {
  const [holidays, setHolidays] = useState([]);
  const [restrictedHolidays, setRestrictedHolidays] = useState([]);
  const [activeIndex, setActiveIndex] = useState(1);
  const rowColors = [
    "#D1CCE6aa",
    "#F7D1C5aa",
    "#B7DFF9aa",
    "#F7D8DCaa",
    "#E8B5B8aa",
    "#EEDDF4aa",
    "#B8CDEBaa",
    "#F9C3A6aa",
    "#D4D4D4aa",
    // "#F7ECD7aa",
  ];
  useEffect(() => {
    fetchHolidays()
      .then((res) => res.json())
      .then((data) => {
        // Categorize holidays into two lists
        const holidaysList = [];
        const restrictedHolidaysList = [];
        data.forEach((holiday) => {
          if (holiday.is_restricted) {
            restrictedHolidaysList.push(holiday);
          } else {
            holidaysList.push(holiday);
          }
        });
        setHolidays(holidaysList);
        setRestrictedHolidays(restrictedHolidaysList);
      })
      .catch((err) => {
        console.error("Error: cannot fetch holidays list: ", err);
      });
  }, []);

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  const renderHolidayRows = (holidayList) => {
    return holidayList.map((holiday, index) => {
      const startDate = new Date(holiday.from_date);
      const endDate = holiday.till_date ? new Date(holiday.till_date) : startDate;
      const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      const startDay = daysOfWeek[startDate.getDay()];
      const endDay = endDate.getDate() === startDate.getDate() ? '' : `to ${daysOfWeek[endDate.getDay()]}`;
      const endDateText = holiday.till_date ? ` - ${endDate.toLocaleDateString('en-GB')}, ` : ", ";
      const startDateFormatted = startDate.toLocaleDateString('en-GB');
      const color = rowColors[index % rowColors.length]; // Alternate row colors based on index
  
      return (
        <Table.Row key={index} style={{backgroundColor: color}}>
          <Table.Cell style={{padding:"0.2rem"}}>{startDateFormatted}{endDateText}{startDay} {endDay}</Table.Cell>
          <Table.Cell width={7} style={{padding:"0.2rem"}}  textAlign="right">{holiday.name_of_holiday}</Table.Cell>
        </Table.Row>
      );
    });
  };
  
  

  return (
    <div>
      <Accordion fluid styled>
        <Accordion.Title
          active={activeIndex === 0}
          index={0}
          onClick={handleClick}
        >
          <Icon name="dropdown" />
          Restricted Holidays
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 0}>
          <Table>
            <Table.Body>
              {renderHolidayRows(restrictedHolidays)}
            </Table.Body>
          </Table>
        </Accordion.Content>
      </Accordion>
      <Accordion fluid styled>
        <Accordion.Title
          active={activeIndex === 1}
          index={1}
          onClick={handleClick}
        >
          <Icon name="dropdown" />
          Holidays
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 1}>
          <Table>
            <Table.Body>
              {renderHolidayRows(holidays)}
            </Table.Body>
          </Table>
        </Accordion.Content>
      </Accordion>
    </div>
  );
};

export default HolidayDropdowns;
