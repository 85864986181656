import React, { useState } from "react";
import { useAuth } from "../Context/AuthContext";
import Navbar from "../Components/Navbar";
import { Container, Header } from "semantic-ui-react";
import "../Css/Inbox.css";

import SearchModal from "../Components/Modals/SearchModal";
import FacultyCourseMap from "../Components/Modals/FacultyCourseMap";

export default function MyCourse() {
  const { logout } = useAuth();
  const [isModalOpen, setModalOpen] = useState(false);
  const closeModal = () => setModalOpen(false);

  const person_id = localStorage.getItem("person_id");
  return (
    <>
      <Navbar onLogout={logout} setModalOpen2={setModalOpen} />
      <SearchModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <Container fluid style={{ paddingLeft: "10vw", paddingRight: "10vw" }}>
        <div style={{ paddingTop: "90px" }}>
      <Header as={"h2"} style={{ fontFamily: "Times New Roman, Georgia, serif" }}>My Courses</Header>
          <FacultyCourseMap person_id={person_id} />
        </div>
      </Container>
    </>
  );
}
