import React, { useEffect, useState } from "react";
import { userLoginLogs } from "./api";
import { convertDateTimestamp } from "../utils";
import GenericTable from "../Tables/GenericTable";
import GenericStatistics from "../Statistics/GenericStatistics";

const UserLoginLogs = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  // Map of data keys to custom labels
  const headerLabels = {
    client_name: "Name",
    ip: "IP Address",
    login_time: "Login Time",
  };

  // Map of data keys to functions to transform their values before rendering
  const valueTransformers = {
    login_time: convertDateTimestamp,
  };

  const rowColors = [
    "#FFFFFFaa",
    '#D6EEEEaa'
    // "#F7ECD7aa",
  ];

  const statsData = [
    { value: data.summary?.total_casesheets, label: "Total Casesheet", color: "green" },
    { value: data.summary?.total_casesheets - data.summary?.closed_casesheets, label: "Active Casesheets", color: "blue" },
    { value: data.summary?.closed_casesheets, label: "Closed Casesheets", color: "yellow" },
  ];

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await userLoginLogs();
        const result = await response.json();
        setData(result);
        // console.log(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, []);

  return (
    <>
      <GenericStatistics
        stats={statsData}
        columns={3}
        stackable={true}
        rowStyle={{ margin: "0.75rem 0" }}
        columnStyle={{ textAlign: "center" }}
      />
      <GenericTable
        headerLabels={headerLabels}
        valueTransformers={valueTransformers}
        data={data.login_logs}
        loading={loading}
        loadingText="Loading Logs..."
        rowColors={rowColors}
        showSearch={true}
        searchFilters={{ client_name: "Name", login_time: "Login Time" }}
      />
    </>
  );
};

export default UserLoginLogs;
