import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "./Components/Login";
import Home from "./Components/Home";
import { useAuth } from "./Context/AuthContext";
import Notifications from "./pages/Notifications";
import ProgramDetails from "./Components/ProgramDetails";
import CoursesList from "./Components/CoursesList";
import CourseDetails from "./Components/CourseDetails";
import InstituteDetails from "./Components/Institutes/InstituteDetails";
import DepartmentDetails from "./Components/DepartmentDetails";
import DepartmentsList from "./Components/departmentsList";
import FacultyDetails from "./Components/FacultyDetails";
import PersonDetails from "./Components/PersonDetail";
import FacultySearch from "./Components/FacultySearch";
import TelePhoneSearch from "./pages/TelePhoneSearch";

import Srk from "./pages/Srk";
import HolidayCalendarPage from "./pages/HolidayCalendarPage";
import HelpAndContact from "./pages/HelpAndContact";
import Warden from "./pages/Warden";
import InstituteList from "./Components/Institutes/InstituteList";
import FacultyList from "./Components/FacultyList";
import ProgramsList from "./Components/ProgramsList";
import SalaryComplaints from "./pages/SalaryComplaints";
import SecureForm from "./Components/Psy/SecureForm";
import PsyCounseling from "./pages/PsyCounseling";
import DisplayData from "./Components/Psy/DisplayData";
import CounseleeProfile from "./Components/Psy/CounseleeProfile";
import UploadImageToPdf from "./Components/Psy/UploadImages";
import UploadImages from "./Components/Psy/UploadImages";
import UploadAndMergePDF from "./Components/Psy/FileUpload";
import FileUpload from "./Components/Psy/FileUpload";
import withNetworkStatus from "./Components/NoInternet/withNetworkStatus";
import WeatherComponent from "./Components/Weather/WeatherComponent";
import FacultyCourseMap from "./Components/Modals/FacultyCourseMap";
import MyCourse from "./pages/MyCourse";
import HomeWithoutLogin from "./Components/WithoutLogin/HomeWithoutLogin";
import UserLoginLogs from "./Components/Psy/UserLoginLogs";
import LastLoginLogs from "./Components/LastLoginLogs";
import Admin from "./pages/Admin";
import AdminNotes from "./Components/AdminNotes";
import GenericMessageView from "./Components/Cards/GenericMessageView";
import ProfileCard from "./Components/Cards/ProfileCard";
import ShowProfileModel from "./Components/Modals/ShowProfileModel";
import ShowAttendance from "./Components/Attendance/ShowAttendance";
import TakingAttendance from "./Components/Attendance/TakingAttendance";
import CseopAttendance from "./pages/CseopAttendance";

function App() {
  const { loggedInCtx } = useAuth();
  const loggedIn = localStorage.getItem("isLoggedIn") === "true";
  const srkMember =
    localStorage.getItem("srk_admin_id") ||
    localStorage.getItem("srk_fellow_id");
  const hostelAdminMember =
    localStorage.getItem("hostel_admin_id") ||
    localStorage.getItem("hostel_warden_id");
  const complaintSalaryMember =
    localStorage.getItem("complaint_salary_employee_id") ||
    localStorage.getItem("complaint_salary_admin_id");
  const psyCounselingMember =
    localStorage.getItem("psy_counsellor_id") ||
    localStorage.getItem("psy_admin_id");
  return (
    <Router>
      <Routes>
        {/* <Route path="/login-namaste" element={<Login />} /> */}
        <Route
          path="/login-namaste"
          element={loggedIn ? <Navigate to="/" /> : <Login />}
        />

        <Route
          path="/notifications"
          element={
            loggedIn ? <Notifications /> : <Navigate to="/login-namaste" />
          }
        />
        <Route
          path="/contact"
          element={
            loggedIn ? <HelpAndContact /> : <Navigate to="/login-namaste" />
          }
        />
        <Route path="/" element={loggedIn ? <Home /> : <HomeWithoutLogin />} />

        {srkMember && (
          <Route
            path="/srk"
            element={loggedIn ? <Srk /> : <Navigate to="/login-namaste" />}
          />
        )}
        {hostelAdminMember && (
          <Route
            path="/wardens"
            element={loggedIn ? <Warden /> : <Navigate to="/login-namaste" />}
          />
        )}
        {complaintSalaryMember && (
          <Route
            path="/issues/salary"
            element={
              loggedIn ? <SalaryComplaints /> : <Navigate to="/login-namaste" />
            }
          />
        )}

        {psyCounselingMember && (
          <Route
            path="/psy/counseling"
            element={
              loggedIn ? <PsyCounseling /> : <Navigate to="/login-namaste" />
            }
          />
        )}

        {/* <Route
          path="/test/test"
          element={
            loggedIn ? <CseopAttendance /> : <Navigate to="/login-namaste" />
          }
        /> */}

        <Route
          path="/namaste-admin"
          element={loggedIn ? <Admin /> : <Navigate to="/login-namaste" />}
        />

        <Route
          path="/cseop/attendance"
          element = {loggedIn ? <CseopAttendance /> : <Navigate to="/login-namaste" />}
          />

        <Route
          path="/person/:personid"
          element={
            loggedIn ? <PersonDetails /> : <Navigate to="/login-namaste" />
          }
        />
        <Route
          path="/faculty/search"
          element={
            loggedIn ? <FacultySearch /> : <Navigate to="/login-namaste" />
          }
        />
        <Route
          path="/directory"
          element={
            loggedIn ? <TelePhoneSearch /> : <Navigate to="/login-namaste" />
          }
        />
        <Route
          path="/psy/counseling/edit/:counselee_id"
          element={loggedIn ? <SecureForm /> : <Navigate to="/login-namaste" />}
        />

        <Route
          path="/psy/counseling/view/:counselee_id"
          element={
            loggedIn ? <DisplayData /> : <Navigate to="/login-namaste" />
          }
        />

        <Route
          path="/psy/counseling/profile/:counselee_id"
          element={
            loggedIn ? <CounseleeProfile /> : <Navigate to="/login-namaste" />
          }
        />
        {/* <Route
          path="/sf"
          element={loggedIn ? <SecureForm /> : <Navigate to="/login-namaste" />}
        /> */}
        <Route
          path="/mycourses"
          element={loggedIn ? <MyCourse /> : <Navigate to="/login-namaste" />}
        />

        <Route
          path="*"
          element={loggedIn ? <Navigate to="/" /> : <HomeWithoutLogin />}
        />

        {/* Without login exposed pages */}
        <Route
          path="/programs"
          element={<ProgramsList loggedIn={loggedIn} />}
        />
        <Route
          path="/program/:programid"
          element={<ProgramDetails loggedIn={loggedIn} />}
        />
        <Route path="/courses" element={<CoursesList loggedIn={loggedIn} />} />
        <Route
          path="/course/:courseid"
          element={<CourseDetails loggedIn={loggedIn} />}
        />
        <Route
          path="/departments"
          element={<DepartmentsList loggedIn={loggedIn} />}
        />
        <Route
          path="/department/:departmentid"
          element={<DepartmentDetails loggedIn={loggedIn} />}
        />
        <Route
          path="/faculties"
          element={<FacultyList loggedIn={loggedIn} />}
        />
        <Route
          path="/faculty/:facultyid"
          element={<FacultyDetails loggedIn={loggedIn} />}
        />
        <Route
          path="/institutes"
          element={<InstituteList loggedIn={loggedIn} />}
        />
        <Route
          path="/institute/:instituteid"
          element={<InstituteDetails loggedIn={loggedIn} />}
        />
        <Route
          path="/holidays"
          element={<HolidayCalendarPage loggedIn={loggedIn} />}
        />
      </Routes>
    </Router>
  );
}

export default withNetworkStatus(App);
