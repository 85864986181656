import React, { useState } from "react";
import { useAuth } from "../Context/AuthContext";
import Navbar from "../Components/Navbar";
import { Container, Header, Menu, Segment } from "semantic-ui-react";
import "../Css/Inbox.css";

// import { Inbox } from "../Components/Notification/Inbox";
// import { Sent } from "../Components/Notification/Sent";
import { SrkInbox } from "../Components/SRK/SrkInbox";
import SearchModal from "../Components/Modals/SearchModal";
// import { CreateForm } from "../Components/Notification/CreateForm";

export default function Srk() {
  const { logout } = useAuth();
  const [activeItem, setActiveItem] = useState("inbox");
  const handleItemClick = (name) => setActiveItem(name);
  const srk_fellow_id = localStorage.getItem("srk_fellow_id");
  const srk_admin_id = localStorage.getItem("srk_admin_id");
  const [isModalOpen, setModalOpen] = useState(false);
  const closeModal = () => setModalOpen(false);

  return (
    <>
      <Navbar onLogout={logout} setModalOpen2={setModalOpen} />
      <SearchModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <Container fluid style={{ paddingLeft: "10vw", paddingRight: "10vw" }}>
        <div style={{ paddingTop: "90px" }}>
          <Header as={"h2"} style={{fontFamily: "Times New Roman, Georgia, serif"}}>Sarvepalli Radhakrishnan Internship</Header>
          <Menu
            pointing
            style={srk_fellow_id ? { display: "flex" } : { display: "none" }}
          >
            <Menu.Item
              name="All Reports"
              active={activeItem === "inbox"}
              onClick={() => handleItemClick("inbox")}
            />
            {srk_fellow_id && (
              <>
                <Menu.Item
                  name="sent"
                  active={activeItem === "sent"}
                  onClick={() => handleItemClick("sent")}
                />
                <Menu.Item
                  name="create"
                  active={activeItem === "create"}
                  onClick={() => handleItemClick("create")}
                />
              </>
            )}
          </Menu>

          <Segment>
            {activeItem === "inbox" && <SrkInbox srk_admin_id={srk_admin_id} />}

            {/* {authfac_id && activeItem === "sent" && <Sent authfac_id={authfac_id} />}  */}

            {/* {authfac_id && activeItem === "create" && <CreateForm />} */}
          </Segment>
        </div>
      </Container>
    </>
  );
}
