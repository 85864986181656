import React, { memo, useEffect, useState } from "react";
import { Icon, Card, Modal } from "semantic-ui-react";
import {
  convertDateTimestamp,
  formatDate,
  isImageFile,
  linkDecorator,
} from "../utils";
import ReactLinkify from "react-linkify";
import {
  fetchSalaryComplaintReportDetails,
  fetchSrkReportDetails,
} from "../api";
import LoaderComponent from "../LoaderComponent";

function SalaryMessageViewCard(props) {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      // console.log(props);
      if (props && props.id) {
        setLoading(true);
        try {
          const response = await fetchSalaryComplaintReportDetails(props.id);
          const fetchedData = await response.json();
          // console.log(fetchedData);
          setData(fetchedData);
          //   console.log(fetchedData);
        } catch (error) {
          console.error("Failed to fetch complaints report:", error);
        } finally {
          setLoading(false);
        }
      } else {
        console.log("complaints not available");
      }
    };
    loadData();
  }, [props]);

  return isLoading ? (
    <LoaderComponent loadingText={"Loading ..."} />
  ) : (
    <>
      <Card color="green" fluid>
        <Card.Content>
          <Card.Header>
            <div
              style={{
                overflowWrap: "break-word", // ensures long words are broken and wrapped to the next line
                wordWrap: "break-word", // alternative/legacy property for word wrapping
                whiteSpace: "normal", // allows text to wrap as needed
              }}
            >
              {data.title}
            </div>
            <span
              style={{
                float: "right",
                color: "black",
                fontSize: "1rem",
                // whiteSpace: "nowrap",
              }}
            >
              Date: {convertDateTimestamp(data.time_of_sending)}
            </span>
            {/* <br />
          <span
            style={{
              float: "right",
              color: "black",
              fontSize: "1rem",
              // whiteSpace: "nowrap",
            }}
          >
            Report Date: {formatDate(data.report_date)}
          </span> */}
          </Card.Header>

          <Card.Description style={{ paddingTop: "0.25rem" }}>
            {/* <strong>Message:</strong>{" "} */}
            {data.body &&
              data.body.split("\n").map((line, index) => (
                <span key={index} style={{color:"black"}}>
                  <ReactLinkify componentDecorator={linkDecorator}>
                    {line}
                  </ReactLinkify>
                  <br />
                </span>
              ))}
          </Card.Description>

          {data.attachment && (
            <Card.Content extra style={{ paddingTop: "0.5rem" }}>
              {isImageFile(data.attachment) ? (
                <>
                  <img
                    src={data.attachment}
                    alt="Attachment"
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                      cursor: "pointer",
                    }}
                    onClick={() => setOpen(true)} // Open the modal
                  />
                  <Modal
                    open={open}
                    onClose={() => setOpen(false)} // Close the modal
                    size="large"
                    closeIcon
                  >
                    <Modal.Content
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%", // Fill the entire modal height
                      }}
                    >
                      <img
                        src={data.attachment}
                        alt="Attachment"
                        style={{ maxWidth: "100%", maxHeight: "80vh" }}
                      />
                    </Modal.Content>
                  </Modal>
                </>
              ) : (
                <>
                  <Icon name="attach" />
                  <a
                    href={data.attachment}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Attachment
                  </a>
                </>
              )}
            </Card.Content>
          )}
        </Card.Content>
        {data.sender_name && (
          <Card.Content extra>
            <Icon name="user" />
            {data.sender_name}
          </Card.Content>
        )}
      </Card>
      {data.complaint_comments && data.complaint_comments.length > 0 && (
        <Card color="blue" fluid>
          <Card.Header>
            <div style={{ padding: "1rem", fontWeight: "bold" }}>Comments</div>
          </Card.Header>
          <Card.Content>
            {data.complaint_comments
              .sort(
                (a, b) =>
                  new Date(b.time_of_sending) - new Date(a.time_of_sending)
              )
              .map((comment, index) => (
                <Card
                  color="blue"
                  fluid
                  key={index}
                  // style={{ marginBottom: "1rem" }}
                >
                  <Card.Content>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <Icon name="user"/> {comment.commentator_name}
                      </div>
                      <div>
                        <strong>Date:</strong>{" "}
                        {convertDateTimestamp(comment.time_of_sending)}
                      </div>
                    </div>
                    <div style={{}}>
                      <strong>Comment:</strong> {comment.comment}
                    </div>
                  </Card.Content>
                </Card>
              ))}
          </Card.Content>
        </Card>
      )}
    </>
  );
}

export default memo(SalaryMessageViewCard);
