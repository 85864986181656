import React, { useState } from "react";
import {
  Grid,
  Form,
  TextArea,
  Button,
  Message,
  Container,
} from "semantic-ui-react";
import { useAuth } from "../Context/AuthContext";
import Navbar from "../Components/Navbar";
import SearchModal from "../Components/Modals/SearchModal";
import { submitSuggestion } from "../Components/api";

const HelpAndContact = () => {
  const [suggestion, setSuggestion] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);
  const closeModal = () => setModalOpen(false);
  const { logout } = useAuth();

  const handleSubmit = async () => {
    const personId = localStorage.getItem("person_id");

    try {
      await submitSuggestion(personId, suggestion);
      setSuccessMessage("Your suggestion has been successfully submitted!");
      setSuggestion("");
    } catch (error) {
      console.error("Error submitting suggestion:", error);
      setSuccessMessage(
        "Failed to submit the suggestion. Please try again later."
      );
    }
  };

  return (
    <>
      <Navbar onLogout={logout} setModalOpen2={setModalOpen} />
      <SearchModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <Container>
        <Grid verticalAlign="middle" style={{ height: "100vh" }} stackable>
          <Grid.Row>
            <Grid.Column width={16}>
              <h3>Contact</h3>
              <p>
                For any problems, comments and feedback, please contact us at:
                <a href="mailto:isystems@bhu.ac.in">isystems@bhu.ac.in</a>
              </p>
              <h3>
                We would appreciate if you would give us your suggestions or
                feedback below.
              </h3>
              <Form success={!!successMessage}>
                <Form.Field
                  control={TextArea}
                  placeholder="Note that your suggestions here are linked with your profile and we may contact you for clarification if necessary."
                  value={suggestion}
                  onChange={(e) => setSuggestion(e.target.value)}
                />
                <Button primary onClick={handleSubmit}>
                  Send
                </Button>
                {successMessage && <Message success content={successMessage} />}
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </>
  );
};

export default HelpAndContact;
