import React, { useState, useEffect } from "react";
import { Container, Form, Button, Segment, Icon, Accordion } from "semantic-ui-react";
import { Link } from "react-router-dom";
import ResultsTable from "../Components/ResultsTable";
import SearchForm from "../Components/SearchForm";
import { programSearch, courseSearch, fetchInstituteList, fetchFacultiesList, fetchDepartmentList } from "../Components/api";
import LoaderComponent from "../Components/LoaderComponent";

export default function SearchComponent() {
  const tableHeaders = [
    {
      key: "name",
      label: "Name",
      width: 4,
    },
    {
      key: "department_name",
      label: "Department",
      width: 5,
    },
    {
      key: "program_type",
      label: "Program Type",
      width: 5,
    },
  ];

  const tableHeadersIns = [
    {
      key: "name",
      label: "Name",
      width: 4,
    },
  ];

  const tableHeadersForCourse = [
    {
      key: "name",
      label: "Name",
      width: 7,
    },
    {
      key: "course_type",
      label: "Course Type",
      width: 4,
    },
    {
      key: "credits",
      label: "Credits",
      width: 2,
    },
  ];

  const extraDetailsHdrTxt = "Program Details";
  const extraDetailsHdrTxtCourse = "Course Details";

  const [formState, setFormState] = useState({
    name: { label: "Program, course, Institute Name", value: "", type: "text" },
  });

  const [isRequestMade, setLoadingRequest] = useState(false);

  const [isResponseRecieved, showResponse] = useState(false);
  const [isResponseRecievedForCourse, showResponseForCourse] = useState(false);
  const [isResponseRecievedForInstitutes, showResponseForInstitutes] = useState(false);
  const [isResponseRecievedForFaculties, showResponseForFaculties] = useState(false);
  const [isResponseRecievedForDepartments, showResponseForDepartments] = useState(false);

  const [results, setResults] = useState({ data: [], msg: "" });
  const [resultsForCourse, setResultsforCourse] = useState({ data: [], msg: "" });
  const [resultsForInstitutes, setResultsForInstitutes] = useState({ data: [], msg: "" });
  const [resultsForFaculties, setResultsForFaculties] = useState({ data: [], msg: "" });
  const [resultsForDepartments, setResultsForDepartments] = useState({ data: [], msg: "" });

  const [institutesList, setList] = useState([]);
  const [facultyList, setFacltyList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [activeIndex, setActiveIndex] = useState(-1);

  const [noResultFound, setNoResultFound] = useState(false);

  const handleAccordionClick = (index) => {
    setActiveIndex(activeIndex === index ? -1 : index);
  };

  useEffect(() => {
    fetchInstituteList()
      .then((res) => res.json())
      .then((data) => {
        if (data && data.length > 0) {
          setList(data);
        }
      })
      .catch((err) => {
        console.error("Failed to fetch institutes list: ", err);
      });
  }, []);

  useEffect(() => {
    fetchDepartmentList()
      .then((res) => res.json())
      .then((data) => {
        if (data && data.length > 0) {
          setDepartmentList(data);
        }
      })
      .catch((err) => {
        console.error("Failed to fetch department list: ", err);
      });
  }, []);

  useEffect(() => {
    fetchFacultiesList()
      .then((res) => res.json())
      .then((data) => {
        if (data && data.length > 0) {
          setFacltyList(data);
        }
      })
      .catch((err) => {
        console.error("Failed to fetch faculties list: ", err);
      });
  }, []);

  const handleSubmit = () => {
    showResponse(false);
    showResponseForCourse(false);
    showResponseForInstitutes(false);
    showResponseForFaculties(false);
    showResponseForDepartments(false);

    let isFormDirty = false;
    const queryset = Object.keys(formState)
      .map((queryKey) => {
        if (formState[queryKey]["value"]) {
          isFormDirty = true;
        }
        return `${queryKey}=${formState[queryKey]["value"]}`;
      })
      .join("&");

    if (!isFormDirty) {
      setResults({ data: [], msg: "Enter some data in the form" });
      return;
    }

    setLoadingRequest(true);
    setNoResultFound(false);

    programSearch(queryset)
      .then((res) => res.json())
      .then((data) => {
        setLoadingRequest(false);
        if (data && data.length > 0) {
          setResults({ data, msg: `${data.length} programs found` });
          showResponse(true);
        } else {
          setResults({ data: [], msg: "No programs found" });
        }
      })
      .catch((err) => {
        console.error("Failed to search for program(s): ", err);
        setLoadingRequest(false);
        setResults({
          data: [],
          msg: "Something went wrong while fetching programs data",
        });
      });

    const searchKeyword = formState.name.value.toLowerCase().trim();

    const filteredInstitutes = institutesList.filter(
      (institute) =>
        (institute.name && institute.name.toLowerCase().includes(searchKeyword)) ||
        (institute.department_name && institute.department_name.toLowerCase().includes(searchKeyword))
    );
    setResultsForInstitutes({
      data: filteredInstitutes,
      msg: `${filteredInstitutes.length} institutes found`,
    });
    filteredInstitutes.length ? showResponseForInstitutes(true) : showResponseForInstitutes(false);

    const filteredFaculties = facultyList.filter(
      (faculty) =>
        (faculty.name && faculty.name.toLowerCase().includes(searchKeyword)) ||
        (faculty.department_name && faculty.department_name.toLowerCase().includes(searchKeyword))
    );
    setResultsForFaculties({
      data: filteredFaculties,
      msg: `${filteredFaculties.length} faculties found`,
    });
    filteredFaculties.length ? showResponseForFaculties(true) : showResponseForFaculties(false);

    const filteredDepartments = departmentList.filter(
      (department) =>
        (department.name && department.name.toLowerCase().includes(searchKeyword)) ||
        (department.department_name && department.department_name.toLowerCase().includes(searchKeyword))
    );
    setResultsForDepartments({
      data: filteredDepartments,
      msg: `${filteredDepartments.length} departments found`,
    });
    filteredDepartments.length ? showResponseForDepartments(true) : showResponseForDepartments(false);

    courseSearch(queryset)
      .then((res) => res.json())
      .then((data) => {
        setLoadingRequest(false);
        if (data && data.length > 0) {
          setResultsforCourse({ data, msg: `${data.length} courses found` });
          showResponseForCourse(true);
        } else {
          setResultsforCourse({ data: [], msg: "No courses found" });
        }

        const allResultsEmpty =
          data.length === 0 &&
          filteredInstitutes.length === 0 &&
          filteredFaculties.length === 0 &&
          filteredDepartments.length === 0;

        if (allResultsEmpty) {
          setNoResultFound(true);
        }
      })
      .catch((err) => {
        console.error("Failed to search for course(s): ", err);
        setLoadingRequest(false);
        setResultsforCourse({
          data: [],
          msg: "Something went wrong while fetching courses data",
        });
      });
  };

  const renderMoreDetailsComponent = (data) => {
    return (
      <Link
        to={"/program/" + data.id + "/"}
        state={{ programId: data.id }}
        style={{ color: "blue !important", textDecoration: "underline", cursor: "pointer" }}
      >
        Click here
      </Link>
    );
  };

  const renderMoreDetailsComponentForCourse = (data) => {
    return (
      <Link
        to={`/course/${data.id}/`}
        state={{ course: data.id }}
        style={{ color: "blue !important", textDecoration: "underline", cursor: "pointer" }}
      >
        Click here
      </Link>
    );
  };

  const renderMoreDetailsComponentForInstitute = (data) => {
    return (
      <Link
        to={"/institute/" + data.id + "/"}
        state={{ instituteId: data.id }}
        style={{ color: "blue !important", textDecoration: "underline", cursor: "pointer" }}
      >
        Click here
      </Link>
    );
  };

  const renderMoreDetailsComponentForFaculty = (data) => {
    return (
      <Link
        to={"/faculty/" + data.id + "/"}
        state={{ facultyid: data.id }}
        style={{ color: "blue !important", textDecoration: "underline", cursor: "pointer" }}
      >
        Click here
      </Link>
    );
  };

  const renderMoreDetailsComponentForDepartment = (data) => {
    return (
      <Link
        to={"/department/" + data.id + "/"}
        state={{ departmentId: data.id }}
        style={{ color: "blue !important", textDecoration: "underline", cursor: "pointer" }}
      >
        Click here
      </Link>
    );
  };

  if (isRequestMade) {
    return <LoaderComponent loadingText="Loading results..." />;
  }

  return (
    <Container>
      <Segment basic padded>
        <Form autoComplete="off" style={{ marginBottom: "2rem" }}>
          <SearchForm formState={formState} setFormState={setFormState} cols={1} />
          <Button
            className="heading-font"
            primary
            style={{ border: "none" }}
            onClick={handleSubmit}
          >
            <Icon name="search" /> Search
          </Button>
        </Form>
        {noResultFound && (
          <Segment basic>
            <h3>No result found</h3>
          </Segment>
        )}
        {isResponseRecieved && (
          <Accordion fluid styled>
            <Accordion.Title active={activeIndex === 0} onClick={() => handleAccordionClick(0)}>
              <Icon name="dropdown" />
              Programs ({results.data.length})
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
              <ResultsTable
                results={results}
                setResults={setResults}
                tableHeaders={tableHeaders}
                extraDetailsHdrTxt={extraDetailsHdrTxt}
                extraDetailsComponent={renderMoreDetailsComponent}
              />
            </Accordion.Content>
          </Accordion>
        )}
        {isResponseRecievedForCourse && (
          <Accordion fluid styled>
            <Accordion.Title active={activeIndex === 1} onClick={() => handleAccordionClick(1)}>
              <Icon name="dropdown" />
              Courses ({resultsForCourse.data.length})
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 1}>
              <ResultsTable
                results={resultsForCourse}
                setResults={setResultsforCourse}
                tableHeaders={tableHeadersForCourse}
                extraDetailsHdrTxt={extraDetailsHdrTxtCourse}
                extraDetailsComponent={renderMoreDetailsComponentForCourse}
              />
            </Accordion.Content>
          </Accordion>
        )}
        {isResponseRecievedForInstitutes && (
          <Accordion fluid styled>
            <Accordion.Title active={activeIndex === 2} onClick={() => handleAccordionClick(2)}>
              <Icon name="dropdown" />
              Institutes ({resultsForInstitutes.data.length})
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 2}>
              <ResultsTable
                results={resultsForInstitutes}
                setResults={setResultsForInstitutes}
                tableHeaders={tableHeadersIns}
                extraDetailsHdrTxt="Institute Details"
                extraDetailsComponent={renderMoreDetailsComponentForInstitute}
              />
            </Accordion.Content>
          </Accordion>
        )}
        {isResponseRecievedForFaculties && (
          <Accordion fluid styled>
            <Accordion.Title active={activeIndex === 3} onClick={() => handleAccordionClick(3)}>
              <Icon name="dropdown" />
              Faculties ({resultsForFaculties.data.length})
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 3}>
              <ResultsTable
                results={resultsForFaculties}
                setResults={setResultsForFaculties}
                tableHeaders={tableHeadersIns}
                extraDetailsHdrTxt="Faculty Details"
                extraDetailsComponent={renderMoreDetailsComponentForFaculty}
              />
            </Accordion.Content>
          </Accordion>
        )}
        {isResponseRecievedForDepartments && (
          <Accordion fluid styled>
            <Accordion.Title active={activeIndex === 4} onClick={() => handleAccordionClick(4)}>
              <Icon name="dropdown" />
              Departments ({resultsForDepartments.data.length})
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 4}>
              <ResultsTable
                results={resultsForDepartments}
                setResults={setResultsForDepartments}
                tableHeaders={tableHeadersIns}
                extraDetailsHdrTxt="Department Detail"
                extraDetailsComponent={renderMoreDetailsComponentForDepartment}
              />
            </Accordion.Content>
          </Accordion>
        )}
      </Segment>
    </Container>
  );
}
