import React, { useState } from "react";
import Navbar from "../Components/Navbar";
import { useAuth } from "../Context/AuthContext";
import { Container, Grid } from "semantic-ui-react";
import LoaderComponent from "../Components/LoaderComponent";
import Holidays from "../Components/HolidayList";
import HolidayDropdowns from "../Components/HolidayDropdowns";
import SearchModal from "../Components/Modals/SearchModal";
import NavbarWithoutLogin from "../Components/WithoutLogin/NavbarWithoutLogin";

export default function HolidayCalendarPage(props) {
  const loggedIn = localStorage.getItem("isLoggedIn") === "true";
  const { logout } = useAuth();
  const [isLoading, setLoading] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const closeModal = () => setModalOpen(false);

  return (
    <>
      {loggedIn ? (
        <Navbar onLogout={logout} setModalOpen2={setModalOpen} />
      ) : (
        <NavbarWithoutLogin />
      )}
      <Container
        fluid
        style={{ paddingLeft: "5vw", paddingRight: "5vw", paddingTop: "90px" }}
      >
        <SearchModal isModalOpen={isModalOpen} closeModal={closeModal} />
        <Grid stackable>
          {isLoading ? (
            <LoaderComponent loadingText={"Loading Notifications ..."} />
          ) : (
            <>
              <Grid.Column
                width={12}
                style={{
                  // maxHeight: "80vh",
                  overflowY: "auto",
                  minHeight: "80vh",
                }}
              >
                <Holidays />
                {/* grid one */}
              </Grid.Column>
              <Grid.Column
                width={4}
                style={{
                  transition: "opacity 0.5s",
                  opacity: 1,
                  maxHeight: "80vh",
                  overflowY: "auto",
                }}
              >
                <HolidayDropdowns />
                {/* grid two */}
              </Grid.Column>
            </>
          )}
        </Grid>
      </Container>
    </>
  );
}
