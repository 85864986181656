import React from "react";
import { Icon, Table } from "semantic-ui-react";
import { formatDate, cropTitle } from "../utils";

function SrkMessageTable({ item, selectedMessageId, selectMessage, setSetelectedMsgUsername, setAnalysisView, cardBgColor }) {
  const handleRowClick = () => {
    selectMessage(item.id);
    setSetelectedMsgUsername(item.sender_name);
    setAnalysisView(false);
  };

  return (
    <Table.Row
      key={item.id}
      onClick={handleRowClick}
      style={{
        backgroundColor: selectedMessageId === item.id ? "blue" : cardBgColor || "",
        cursor: "pointer",
      }}
    >
      <Table.Cell style={{ paddingTop: "0.2rem", paddingBottom: "0.2rem" }}>
        <div className={"blackLink"}>
          {formatDate(item.time_of_sending)}
        </div>
      </Table.Cell>
      <Table.Cell style={{ paddingTop: "0.2rem", paddingBottom: "0.2rem" }}>
        <div className={"blackLink"}>
          {item.sender_name}
        </div>
      </Table.Cell>
      <Table.Cell style={{ paddingTop: "0.2rem", paddingBottom: "0.2rem" }}>
        <div className={"blackLink"}>
          {item.body ? cropTitle(item.body) : item.title ? cropTitle(item.title) : "-"} {item.attachment && <Icon name="paperclip"></Icon>}
        </div>
      </Table.Cell>
      <Table.Cell textAlign="right" style={{ paddingTop: "0.2rem", paddingBottom: "0.2rem" }}>
        <div className={"blackLink"}>
          {formatDate(item.report_date)}
        </div>
      </Table.Cell>
    </Table.Row>
  );
}

export default SrkMessageTable;
