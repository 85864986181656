import React, { useState } from "react";
import { Button, Card, Grid, Icon, Modal } from "semantic-ui-react";
import GenericStatistics from "../Statistics/GenericStatistics";
import LoaderComponent from "../LoaderComponent";
import { convertDateTimestamp, isImageFile, linkDecorator } from "../utils";
import ReactLinkify from "react-linkify";
import ScrollView from "../ScrollView";

export default function GenericMessageView({
  title = "",
  body = "",
  sender_name = "",
  time_of_sending,
  groups = [],
  attachment = "",
  sender_designation = "",
  notification_status,
  sendToCount = 0,
  readByCount = 0,
  deliveredToCount = 0,
  loading = true,
  loadingText = "Loading...",
}) {
  const [open, setOpen] = useState(false);
  if (loading) {
    return <LoaderComponent loadingText={loadingText} dimmer={true} />;
  }

  const wordWrapStyle = {
    overflowWrap: "break-word", // ensures long words are broken and wrapped to the next line
    wordWrap: "break-word", // alternative/legacy property for word wrapping
    whiteSpace: "normal", // allows text to wrap as needed
  };
  const statsData = [
    { value: sendToCount, label: "Sent To", color: "blue" },
    { value: deliveredToCount, label: "Delivered To", color: "blue" },
    { value: readByCount, label: "Read By", color: "blue" },
  ];
  return (
    <Grid columns={2} stackable>
      <Grid.Row stretched verticalAlign="middle">
        <Grid.Column width={2}>
          <GenericStatistics
            stats={statsData}
            columns={1}
            stackable={true}
            rowStyle={{ margin: "0.75rem 0" }}
            columnStyle={{ textAlign: "center" }}
          />
        </Grid.Column>
        <Grid.Column width={14}>
          <ScrollView maxHeight="60vh" padding="1rem">
            <Card color="green" fluid style={{margin:""}}>
              <Card.Content>
                <Card.Header> {title}</Card.Header>
                {/* <div style={wordWrapStyle}>{title}</div> */}
                <Card.Meta textAlign="right">
                  {" "}
                  {convertDateTimestamp(time_of_sending, false, true)}{" "}
                </Card.Meta>
                <Card.Meta>{groups.join(", ")}</Card.Meta>
                <Card.Description>
                  {body.split("\n").map((line, index) => (
                    <span key={index}>
                      <ReactLinkify componentDecorator={linkDecorator}>
                        {line}
                      </ReactLinkify>
                      <br />
                    </span>
                  ))}
                </Card.Description>
              </Card.Content>
              {attachment && (
                <Card.Content extra style={{ paddingTop: "0.5rem" }}>
                  {isImageFile(attachment) ? (
                    <>
                      <img
                        src={attachment}
                        alt="Attachment"
                        style={{
                          maxWidth: "100%",
                          height: "60vh",
                          cursor: "pointer",
                        }}
                        onClick={() => setOpen(true)} // Open the modal
                      />
                      <Modal
                        open={open}
                        onClose={() => setOpen(false)} // Close the modal
                        size="large"
                        closeIcon
                      >
                        <Modal.Content
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%", // Fill the entire modal height
                          }}
                        >
                          <img
                            src={attachment}
                            alt="Attachment"
                            style={{ maxWidth: "100%", maxHeight: "80vh" }}
                          />
                        </Modal.Content>
                      </Modal>
                    </>
                  ) : (
                    <>
                      <Icon name="attach" />
                      <a
                        href={attachment}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Attachment
                      </a>
                    </>
                  )}
                </Card.Content>
              )}
              <Card.Content extra>
                <Icon name="user" />
                {sender_name} - {sender_designation}
              </Card.Content>
            </Card>
          </ScrollView>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
