import React, { useState } from "react";
import {
  Segment,
  Header,
  Grid,
  Image,
  Icon,
} from "semantic-ui-react";
import { facultySearch } from "./api";
import PhoneSkin from "../Components/PhoneSkin";
import CustomForm from "../Components/CustomForm";
import LoaderComponent from "../Components/LoaderComponent";
import GenericTable from "./Tables/GenericTable";
import ScrollView from "./ScrollView";
import { crop20, cropTitle } from "./utils";
import { MailLink } from "./MailLink";
import NavbarWrapper from "./Template/NavbarWrapper";

export default function TelePhoneSearch() {
  const [formState, setFormState] = useState({
    name: { label: "Faculty Name", value: "", type: "text" },
    department: { label: "Department", value: "", type: "text" },
  });

  const [isRequestMade, setLoadingRequest] = useState(false);
  const [isResponseReceived, showResponse] = useState(false);
  const [results, setResults] = useState({ data: [], msg: "" });
  const defaultImage = require("../Resources/person.png");

  const headerLabels = {
    profile_photo: "",
    faculty_name: "Name",
    department_name: "Department",
    designation: "Designation",
    phone_no: "Phone Number",
    email: "Email",
    profile_link: "Profile Link",
  };

  const returnImage = (data) => {
    return (
      <Image
        src={data ? "https://namaste.bhu.edu.in" + data : defaultImage}
        size="mini"
        circular
        centered
        style={{ margin: "auto", height: "25px", width: "25px" }} // Ensures centering within the Segment
      />
    );
  };

  const linkify = (data) => {
    if (!data) return "";
    return (
      <a href={data} target="_blank" rel="noopener noreferrer">
        <Icon name="user" /> Link
      </a>
    );
  };

  const returnMailLink = (data) => {
    return <MailLink data={data} />;
  };

  const valueTransformers = {
    faculty_name: cropTitle,
    department_name: cropTitle,
    designation: crop20,
    profile_photo: returnImage,
    profile_link: linkify,
    email: returnMailLink,
  };

  const handleSubmit = () => {
    showResponse(false);
    const { name, department } = formState;

    if (!name.value && !department.value) {
      setResults({ data: [], msg: "Enter some data in the form" });
      showResponse(true);
      return;
    }

    setLoadingRequest(true);
    facultySearch(name.value, department.value)
      .then((res) => res.json())
      .then((data) => {
        setLoadingRequest(false);
        if (data && data.length > 0) {
          setResults({ data, msg: `${data.length} faculties found` });
        } else {
          setResults({ data, msg: "No faculties found" });
        }
        showResponse(true);
      })
      .catch((err) => {
        console.error("Failed to search for faculty(s): ", err);
        setLoadingRequest(false);
        setResults({
          data: [],
          msg: "Something went wrong while fetching faculty data",
        });
        showResponse(true);
      });
  };

  return (
    <>
      <NavbarWrapper containerFluid={true} paddingLeft="2vw" paddingRight="2vw">
        {isRequestMade ? (
          <LoaderComponent loadingText="Loading results..." />
        ) : (
          <Grid stackable columns={2}>
            <Grid.Column width={4}>
              <PhoneSkin>
                <Segment basic padded>
                  <Header
                    as={"h2"}
                    style={{ fontFamily: "Times New Roman, Georgia, serif" }}
                  >
                    Faculty Search
                  </Header>
                  <CustomForm
                    formState={formState}
                    setFormState={setFormState}
                    onSubmit={handleSubmit}
                  />
                </Segment>
              </PhoneSkin>
            </Grid.Column>
            <Grid.Column width={12}>
              {isResponseReceived && (
                <>
                  <ScrollView maxHeight="80vh">
                    <GenericTable
                      headerLabels={headerLabels}
                      data={results.data}
                      valueTransformers={valueTransformers}
                      errorMessage="Please use different search criteria"
                    />
                  </ScrollView>
                </>
              )}
            </Grid.Column>
          </Grid>
        )}
      </NavbarWrapper>
    </>
  );
}
