import React from "react";
import { useNavigate } from "react-router-dom";
import { Form, Message, Input, Icon, Modal, Button } from "semantic-ui-react";

function passwordCheckingModel(passwordModalOpen, handlePasswordSubmit, passwordVisible, setPasswordVisible, password, setPassword, passwordErrorMessage, handleClose) {
    return (
        <Modal open={passwordModalOpen} dimmer="blurring">
            <Modal.Header>Enter Password to View Data</Modal.Header>
            <Modal.Content>
                <Form onSubmit={handlePasswordSubmit} autoComplete="off">
                    <Form.Field>
                        <label>Password</label>
                        <Input
                            type={passwordVisible ? "text" : "password"}
                            icon={
                                <Icon
                                    name={passwordVisible ? "eye slash" : "eye"}
                                    link
                                    onClick={() => setPasswordVisible(!passwordVisible)}
                                />
                            }
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </Form.Field>
                </Form>
                {passwordErrorMessage && (
                    <Message error header="Error" content="Incorrect Password Please Try Again" />
                )}
            </Modal.Content>
            <Modal.Actions>
                <Button type="submit" primary onClick={handlePasswordSubmit}>
                    Open
                </Button>
                <Button onClick={handleClose}>
                    Close
                </Button>
            </Modal.Actions>
        </Modal>
    );
}

export default passwordCheckingModel;
