import React, { useState } from "react";
import { Modal, Button, Form, TextArea } from "semantic-ui-react";

const CommentModal = ({ open, onClose, onSubmit }) => {
  const [comment, setComment] = useState("");

  const handleSubmit = () => {
    onSubmit(comment);
    setComment("");
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Header>Add Comment</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <TextArea
              placeholder="Enter your comment"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="blue" onClick={handleSubmit}>
          Save
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default CommentModal;
