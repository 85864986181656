import React, { useState, useEffect } from "react";
import {
  Grid,
  Table,
  Button,
  Container,
  Modal,
  Input,
  Checkbox,
  Header,
  Loader,
  Message,
  Card,
  TextArea,
  Form,
  Dimmer,
} from "semantic-ui-react";
import {
  fetchFacultyCourses,
  fetchCourses,
  updateFacultyCourses,
  fetchCourseDetails,
  updateCourseDetails,
} from "../api";
import LoaderComponent from "../LoaderComponent";
import "../../Css/SrkInbox.css"; // Import the CSS

export default function FacultyCourseMap({ person_id }) {
  const [facultyCourses, setFacultyCourses] = useState([]);
  const [allCourses, setAllCourses] = useState([]);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalLoading, setModalLoading] = useState(false); // Loader state for the modal
  const [isUpdatingCourses, setIsUpdatingCourses] = useState(false); // Loader for updating courses
  const [isSyllabusModalOpen, setIsSyllabusModalOpen] = useState(false); // State for syllabus modal
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCourseDetails, setSelectedCourseDetails] = useState(null); // State to hold course details
  const [isFetchingCourseDetails, setIsFetchingCourseDetails] = useState(false); // Loader for fetching course details
  const [syllabusText, setSyllabusText] = useState(""); // State for syllabus text area

  const rowColors = [
    "#D1CCE6aa",
    "#F7D1C5aa",
    "#B7DFF9aa",
    "#F7D8DCaa",
    "#E8B5B8aa",
    "#EEDDF4aa",
    "#B8CDEBaa",
    "#F9C3A6aa",
    "#D4D4D4aa",
  ];

  useEffect(() => {
    if (person_id) {
      fetchFacultyCoursesData();
    }
  }, [person_id]);

  const fetchCoursesData = async () => {
    setModalLoading(true); // Start loading when modal opens
    try {
      const response = await fetchCourses();
      const result = await response.json();
      setAllCourses(result);
    } catch (error) {
      console.error("Error fetching all courses:", error);
    } finally {
      setModalLoading(false); // Stop loading after fetching courses
    }
  };

  const fetchFacultyCoursesData = async () => {
    setLoading(true);
    try {
      const response = await fetchFacultyCourses(person_id);
      const result = await response.json();
      setFacultyCourses(result.courses);
      setSelectedCourses(result.courses.map((course) => course.id)); // Pre-select courses
    } catch (error) {
      console.error("Error fetching faculty courses:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCourseSelection = (courseId) => {
    setSelectedCourses((prevSelected) =>
      prevSelected.includes(courseId)
        ? prevSelected.filter((id) => id !== courseId)
        : [...prevSelected, courseId]
    );
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleUpdateCourses = async () => {
    setIsUpdatingCourses(true); // Start loader while updating courses
    try {
      const payload = {
        person_id: person_id,
        courses: selectedCourses,
      };
      await updateFacultyCourses(payload);
      setModalOpen(false);
      setSearchQuery(""); // Clear search input after update
      fetchFacultyCoursesData(); // Refresh the faculty courses list
    } catch (error) {
      console.error("Error updating courses:", error);
    } finally {
      setIsUpdatingCourses(false); // Stop loading after update
      if (selectedCourseDetails) {
        await handleCourseClick(selectedCourseDetails.id); // Refresh course details if one is selected
      }
    }
  };

  const handleOpenModal = () => {
    setModalOpen(true);
    fetchCoursesData(); // Fetch courses when the modal opens
  };

  const handleCourseClick = async (courseId) => {
    setIsFetchingCourseDetails(true); // Start loader when fetching course details
    try {
      const response = await fetchCourseDetails(courseId);
      const result = await response.json();
      setSelectedCourseDetails(result); // Store course details
      setSyllabusText(result.syllabus || ""); // Set syllabus text area with existing syllabus or blank
    } catch (error) {
      console.error("Error fetching course details:", error);
    } finally {
      setIsFetchingCourseDetails(false); // Stop loader
    }
  };

  const handleSyllabusUpdate = async () => {
    setIsFetchingCourseDetails(true); // Start loader while updating syllabus
    try {
      const formData = new FormData();
      // formData.append("credits", selectedCourseDetails.credits); // Assuming credits is part of the course details
      formData.append("syllabus", syllabusText);
      // You can append more fields if necessary, e.g., course type, etc.
      await updateCourseDetails(selectedCourseDetails.id, formData); // Sending course ID and form data to the API
      console.log("Syllabus updated successfully");
      setIsSyllabusModalOpen(false); // Close the modal after successful update
      await handleCourseClick(selectedCourseDetails.id); // Refresh course details after update
    } catch (error) {
      console.error("Error updating syllabus:", error);
    } finally {
      setIsFetchingCourseDetails(false); // Stop loader
    }
  };

  // Function to handle new lines in syllabus
  const formatSyllabus = (syllabus) => {
    return { __html: syllabus.replace(/\n/g, "<br/>") };
  };

  // Filter and sort courses so selected courses appear first and search by both course code and name
  const filteredCourses = allCourses
    .filter(
      (course) =>
        course.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        course.code.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .sort((a, b) => {
      const aSelected = selectedCourses.includes(a.id);
      const bSelected = selectedCourses.includes(b.id);
      return aSelected === bSelected ? 0 : aSelected ? -1 : 1;
    });

  return (
    <Card fluid style={{ minHeight: "70vh" }}>
      <Card.Content>
        <Grid className="srk-inbox-grid">
          {isLoading ? (
            <LoaderComponent loadingText={"Loading Courses..."} />
          ) : (
            <>
              <Grid.Column
                width={facultyCourses.length > 0 ? 9 : 16} // Adjust width based on course selection
                className="srk-inbox-column"
                style={{ maxHeight: "80vh", overflowY: "auto" }}
              >
                <Container textAlign="right" style={{ marginBottom: "10px" }}>
                  <Button color="blue" onClick={handleOpenModal}>
                    Add/Update Courses
                  </Button>
                </Container>
                {facultyCourses.length > 0 ? (
                  <Table selectable striped color="blue">
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Course Code</Table.HeaderCell>
                        <Table.HeaderCell>Course Name</Table.HeaderCell>
                        <Table.HeaderCell>Program</Table.HeaderCell>
                        <Table.HeaderCell>Semester</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {facultyCourses.map((course, index) => (
                        <Table.Row
                          key={course.id}
                          style={{
                            backgroundColor:
                              rowColors[index % rowColors.length],
                            cursor: "pointer",
                          }}
                          onClick={() => handleCourseClick(course.id)} // Handle click to fetch course details
                        >
                          <Table.Cell
                            style={{
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                            }}
                          >
                            <div className="blackLink">{course.code}</div>
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                            }}
                          >
                            <div className="blackLink">{course.name}</div>
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                            }}
                          >
                            <div className="blackLink">{course.program}</div>
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              paddingTop: "0.2rem",
                              paddingBottom: "0.2rem",
                            }}
                          >
                            <div className="blackLink">
                              {course.for_semester}
                            </div>
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                ) : (
                  <Message warning>
                    <Message.Header>No Course Selected</Message.Header>
                    <p>Please add a course to view.</p>
                  </Message>
                )}
              </Grid.Column>

              {facultyCourses.length > 0 && (
                <Grid.Column
                  width={7}
                  className="srk-inbox-column"
                  style={{
                    maxHeight: "80vh",
                    overflowY: "auto",
                    position: "relative",
                  }}
                >
                  <Container textAlign="right" style={{ marginBottom: "10px" }}>
                    {selectedCourseDetails && (
                      <Button
                        color="blue"
                        onClick={() => setIsSyllabusModalOpen(true)}
                      >
                        Add/Update Syllabus
                      </Button>
                    )}
                  </Container>

                  {isFetchingCourseDetails ? (
                    <LoaderComponent
                      loadingText={"Fetching Course Details..."}
                    />
                  ) : selectedCourseDetails ? (
                    <Card fluid>
                      <Card.Content>
                        <div>
                          <Header as="h4">{selectedCourseDetails.name}</Header>
                          <p>
                            <strong>Course Code:</strong>{" "}
                            {selectedCourseDetails.code}
                          </p>
                          <p>
                            <strong>Program:</strong>{" "}
                            {selectedCourseDetails.program_name}
                          </p>
                          <p>
                            <strong>Credits:</strong>{" "}
                            {selectedCourseDetails.credits}
                          </p>
                          <p>
                            <strong>Course Type:</strong>{" "}
                            {selectedCourseDetails.course_type}
                          </p>
                          <p>
                            <strong>Syllabus:</strong>
                          </p>
                          <div
                            dangerouslySetInnerHTML={formatSyllabus(
                              selectedCourseDetails.syllabus
                            )}
                          />
                        </div>
                      </Card.Content>
                    </Card>
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "70vh",
                        zIndex: 1001,
                      }}
                    >
                      <Message info>
                        <Message.Header>
                          Select a course to view the syllabus
                        </Message.Header>
                      </Message>
                    </div>
                  )}
                </Grid.Column>
              )}
              <Modal
                open={isModalOpen}
                onClose={() => setModalOpen(false)}
                size="small" // Fixed size for the modal
              >
                <Modal.Header>Add or Update Courses</Modal.Header>
                <Modal.Content>
                  {isModalLoading || isUpdatingCourses ? (
                    <Dimmer active inverted>
                      <LoaderComponent loadingText={"Loading..."} />
                    </Dimmer>
                  ) : (
                    <>
                      <Input
                        fluid
                        icon="search"
                        placeholder="Search for a course..."
                        value={searchQuery}
                        onChange={handleSearchChange}
                        disabled={isModalLoading} // Disable search input while loading
                      />
                      <div
                        style={{
                          maxHeight: "60vh",
                          overflowY: "auto",
                          marginTop: "10px",
                        }}
                      >
                        {filteredCourses.length > 0 ? (
                          filteredCourses.map((course) => (
                            <Checkbox
                              key={course.id}
                              label={`${course.code} - ${course.name}`} // Format: "course code - course name"
                              checked={selectedCourses.includes(course.id)} // Checked if pre-selected
                              onChange={() => handleCourseSelection(course.id)}
                              className="srk-inbox-checkbox" // Apply CSS class from SrkInbox
                              style={{ display: "block", margin: "5px 0" }}
                              disabled={isModalLoading} // Disable checkboxes while loading
                            />
                          ))
                        ) : (
                          <Message warning>
                            <Message.Header>No Course Found</Message.Header>
                            <p>
                              Please check the name or try a different search
                              term.
                            </p>
                          </Message>
                        )}
                      </div>
                    </>
                  )}
                </Modal.Content>
                <Modal.Actions>
                  <Button
                    onClick={handleUpdateCourses}
                    color="blue"
                    disabled={isModalLoading || isUpdatingCourses}
                  >
                    {isUpdatingCourses ? (
                      <Loader active inline size="tiny" />
                    ) : (
                      "Update"
                    )}
                  </Button>
                  {/* <Button
                    onClick={handleUpdateCourses}
                    color="blue"
                    disabled={isModalLoading || isUpdatingCourses}
                  >
                    Update
                  </Button> */}
                  <Button
                    onClick={() => setModalOpen(false)}
                    disabled={isModalLoading || isUpdatingCourses}
                  >
                    Cancel
                  </Button>
                </Modal.Actions>
              </Modal>

              <Modal
                open={isSyllabusModalOpen}
                onClose={() => setIsSyllabusModalOpen(false)}
                size="large"
              >
                <Modal.Header>Add/Update Syllabus</Modal.Header>
                <Modal.Content>
                  <Form>
                    <TextArea
                      placeholder="Enter syllabus here..."
                      value={syllabusText}
                      onChange={(e) => setSyllabusText(e.target.value)}
                      style={{ minHeight: "50vh" }}
                    />
                  </Form>
                </Modal.Content>
                <Modal.Actions>
                  <Button
                    onClick={handleSyllabusUpdate}
                    color="blue"
                    disabled={isFetchingCourseDetails}
                  >
                    {isFetchingCourseDetails ? (
                      <Loader active inline size="tiny" />
                    ) : (
                      "Update"
                    )}
                  </Button>
                  <Button
                    onClick={() => setIsSyllabusModalOpen(false)}
                    disabled={isFetchingCourseDetails}
                  >
                    Cancel
                  </Button>

                  {/* <Button onClick={handleSyllabusUpdate} color="blue">
                    Update
                  </Button> */}
                  {/* <Button onClick={() => setIsSyllabusModalOpen(false)}>
                    Cancel
                  </Button> */}
                </Modal.Actions>
              </Modal>
            </>
          )}
        </Grid>
      </Card.Content>
    </Card>
  );
}
