import React, { memo, useState, useEffect } from "react";
import { Table, Input, Dropdown, Message, Pagination } from "semantic-ui-react";
import LoaderComponent from "../LoaderComponent";

const GenericTable = ({
  headerLabels,
  valueTransformers = {},
  data = [],
  loading = false,
  loadingText = "Loading...",
  rowColors = ["#fff"],
  showSearch = false,
  searchFilters = {},
  totalPages = 1,
  activePage = 1,
  onPageChange = () => {},
  onRowClick = () => {},
  hiddenColumns = [], // Add hiddenColumns prop
  selectable = false,
  errorMessage = "Please try again after sometime.",
}) => {
  const headers = Object.keys(headerLabels).filter(
    (key) => !hiddenColumns.includes(key)
  );
  const searchKeys = Object.keys(searchFilters);
  const [searchValue, setSearchValue] = useState("");
  const [selectedSearchKey, setSelectedSearchKey] = useState(
    searchKeys[0] || ""
  );

  useEffect(() => {
    if (searchKeys.length > 0 && !searchKeys.includes(selectedSearchKey)) {
      setSelectedSearchKey(searchKeys[0]);
    }
  }, [searchKeys, selectedSearchKey]);

  if (loading) {
    return <LoaderComponent loadingText={loadingText} dimmer={false} />;
  }

  const cursorCss = {cursor: "pointer"};

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleDropdownChange = (e, { value }) => {
    setSelectedSearchKey(value);
  };

  const filteredData =
    searchKeys.length > 0
      ? data.filter((item) => {
          let cellValue = item[selectedSearchKey];

          if (valueTransformers[selectedSearchKey]) {
            cellValue = valueTransformers[selectedSearchKey](cellValue);
          }

          return cellValue
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase());
        })
      : data;

  return (
    <>
      {showSearch && searchKeys.length > 0 && (
        <div
          style={{
            display: "flex",
            marginBottom: "1rem",
            paddingLeft: "0.5rem",
            paddingRight: "0.5rem",
          }}
        >
          {searchKeys.length > 1 && (
            <Dropdown
              placeholder="Select Filter"
              selection
              options={searchKeys.map((key) => ({
                key: key,
                value: key,
                text: searchFilters[key],
              }))}
              value={selectedSearchKey}
              onChange={handleDropdownChange}
              style={{ marginRight: "1rem", flexShrink: 0 }}
            />
          )}
          <Input
            icon="search"
            placeholder={`Search by ${
              searchFilters[selectedSearchKey] || "criteria"
            }`}
            value={searchValue}
            onChange={handleSearchChange}
            style={{ flexGrow: 1 }}
          />
        </div>
      )}

      {filteredData.length === 0 ? (
        <Message warning>
          <Message.Header>No Records Found</Message.Header>
          {showSearch ? <p>Please try different search criteria.</p> : <p>{errorMessage}</p>}
        </Message>
      ) : (
        <div style={{ paddingLeft: "0.5rem", paddingRight: "0.5rem" }}>
          <Table celled selectable={selectable} singleLine style={selectable ? cursorCss: {}}>
            <Table.Header>
              <Table.Row>
                {headers.map((header, index) => (
                  <Table.HeaderCell key={index}>
                    {headerLabels[header]}
                  </Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {filteredData.map((item, rowIndex) => (
                <Table.Row
                  key={rowIndex}
                  style={{
                    backgroundColor:
                      rowColors[rowIndex % rowColors.length] || "#fff",
                  }}
                  onClick={() => onRowClick(item)} // Pass full item including hidden fields
                >
                  {headers.map((header, cellIndex) => {
                    const cellValue = valueTransformers[header]
                      ? valueTransformers[header](item[header])
                      : item[header];

                    return (
                      <Table.Cell
                        style={{
                          paddingTop: "0.2rem",
                          paddingBottom: "0.2rem",
                        }}
                        key={cellIndex}
                      >
                        {cellValue}
                      </Table.Cell>
                    );
                  })}
                </Table.Row>
              ))}
            </Table.Body>
          </Table>

          {/* Pagination Component */}
          {totalPages !== 1 && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "1rem",
                marginBottom: "1rem",
              }}
            >
              <Pagination
                activePage={activePage}
                onPageChange={(e, { activePage }) => onPageChange(activePage)}
                totalPages={totalPages}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default memo(GenericTable);
