import React, { useEffect, useState } from "react";
import {
  Table,
  // Button,
  Icon,
  Input,
  Message,
  Loader,
} from "semantic-ui-react";
import { fetchCounsellors } from "./api"; // Import the fetchCounsellors function from api.js

const CounsellorsTable = () => {
  const [counsellors, setCounsellors] = useState([]);
  const [filteredCounsellors, setFilteredCounsellors] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchCounsellors();
        setCounsellors(data);
        setFilteredCounsellors(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching counsellors:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const filteredData = counsellors.filter((counsellor) => {
      return counsellor.counsellor.name
        .toLowerCase()
        .includes(searchQuery.toLowerCase());
    });
    setFilteredCounsellors(filteredData);
  }, [searchQuery, counsellors]);

  return (
    <div>
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}
      >
        <Input
          icon="search"
          placeholder="Search by Name"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{ flex: 1, marginRight: "10px" }}
        />
      </div>
      {loading ? (
        <Loader active inline="centered" />
      ) : (
        <>
          {filteredCounsellors.length > 0 ? (
            <Table compact>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">Status</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {filteredCounsellors.map((counsellor) => (
                  <Table.Row key={counsellor.id}>
                    <Table.Cell>{counsellor.counsellor.name}</Table.Cell>
                    <Table.Cell textAlign="center">
                      {counsellor.is_active ? (
                        <Icon name="check circle" color="green" />
                      ) : (
                        <Icon name="times circle" color="red" />
                      )}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          ) : (
            <Message warning content="No data available" />
          )}
        </>
      )}
    </div>
  );
};

export default CounsellorsTable;
