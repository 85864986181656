import React, { useEffect, useState } from "react";
import { Form, Button, Message, Modal } from "semantic-ui-react";
import { addCounselee, getActiveCounsellors } from "./api";

const AddCounselee = ({ setActiveItem }) => {
  const [counsellors, setCounsellors] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    roll_no: "",
    email:"",
    counsellor: "",
  });
  const [isDropdownDisabled, setIsDropdownDisabled] = useState(false);
  const [selectedCounsellorName, setSelectedCounsellorName] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const psy_counsellor_id = localStorage.getItem("psy_counsellor_id");
    const psy_admin_id = localStorage.getItem("psy_admin_id");

    if (!psy_admin_id && psy_counsellor_id) {
      setFormData((prevData) => ({
        ...prevData,
        counsellor: psy_counsellor_id,
      }));
      setIsDropdownDisabled(true);
    }

    getActiveCounsellors()
      .then((response) => response.json())
      .then((data) => {
        const counsellorOptions = data.map((item) => ({
          key: item.id,
          text: item.counsellor.name,
          value: item.id,
        }));
        setCounsellors(counsellorOptions);

        if (psy_counsellor_id) {
          const selectedCounsellor = data.find(
            (item) => item.id === parseInt(psy_counsellor_id)
          );
          if (selectedCounsellor) {
            setSelectedCounsellorName(selectedCounsellor.counsellor.name);
          }
        }
      })
      .catch((error) => console.error("Error fetching counsellors:", error));
  }, []);

  const handleChange = (e, { name, value }) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    let formErrors = {};
    if (!formData.counsellor && !isDropdownDisabled) {
      formErrors.counsellor = "Please select a counsellor";
    }

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    const formBody = new FormData();
    formBody.append("name", formData.name);
    formBody.append("roll_no", formData.roll_no);
    formBody.append("counsellor", formData.counsellor);
    formBody.append("email", formData.email);

    addCounselee(formBody)
      .then((response) => {
        if (response.status === 201) {
          // throw new Error("Something went wrong");
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setSuccessMessage(`Counselee Code: ${data.code}`);
        setShowModal(true);
        setFormData({
          name: "",
          roll_no: "",
          counsellor: isDropdownDisabled ? formData.counsellor : "",
        });
        if (isDropdownDisabled) {
          const psy_counsellor_id = localStorage.getItem("psy_counsellor_id");
          setSelectedCounsellorName(
            counsellors.find(
              (counselor) => counselor.key === parseInt(psy_counsellor_id)
            )?.text
          );
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setErrorMessage("Something went wrong");
      });
  };

  const handleCloseModal = () => {
    setShowModal(false);
    window.location.reload(); // Refresh the page
  };

  return (
    <>
      <Modal open={showModal} size="small">
        <Modal.Header>Success</Modal.Header>
        <Modal.Content>
          <Message
            success
            header="Successfully added counselee."
            content={
              <strong>
                {successMessage}
              </strong>
            }
          />
          <strong>*Please note this code, and provide it to the counselee.</strong>
        </Modal.Content>
        <Modal.Actions>
          <Button primary onClick={handleCloseModal}>Continue</Button>
        </Modal.Actions>
      </Modal>
      <Form onSubmit={handleSubmit}>
        <Form.Input
          label="Name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
        />
        <Form.Input
          label="Roll No."
          name="roll_no"
          value={formData.roll_no}
          onChange={handleChange}
          required
        />
        <Form.Input
          label="Email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />

        <Form.Field required={true}>
          <label>Counsellor</label>
          {isDropdownDisabled ? (
            <input
              required
              type="text"
              value={selectedCounsellorName}
              readOnly
            />
          ) : (
            <Form.Dropdown
              placeholder="Select Counsellor"
              fluid
              selection
              options={counsellors}
              name="counsellor"
              value={formData.counsellor}
              onChange={handleChange}
              required
              error={
                errors.counsellor
                  ? { content: errors.counsellor, pointing: "above" }
                  : null
              }
            />
          )}
        </Form.Field>
        <Button type="submit" color="blue">
          Add
        </Button>
      </Form>
      {errorMessage && (
        <Message
          error
          header="Error"
          content={errorMessage}
        />
      )}
    </>
  );
};

export default AddCounselee;
