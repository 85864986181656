import React, { useState, useEffect, memo } from "react";
import logo from "../../Resources/logo.png";
import {
  Menu,
  Button,
  Image,
  Modal,
  ModalHeader,
  ModalActions,
  ModalContent,
  Sidebar,
  Icon,
  Dropdown,
} from "semantic-ui-react";

import { Link, useLocation, useNavigate } from "react-router-dom";
import "../../Css/Navbar.css";
import NavbarSearchComponent from "../NavbarSearchComponent";
import { fetchUserData } from "../api";
import LoginButton from "./LoginButton";

const NavbarWithoutLogin = ({ onLogout, setModalOpen2 }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [profileSidebarVisible, setProfileSidebarVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
  const [placeholder, setPlaceholder] = useState("");
  const [typingIndex, setTypingIndex] = useState(0);
  const [textIndex, setTextIndex] = useState(0);
  const [userData, setUserData] = useState(null);
  const defaultImage = require("../../Resources/person.png");
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  const goBack = () => {
    navigate(-1); // This will navigate back to the previous page using React Router
  };

  const returnBackButton = () => {
    if (currentPath === "/") return null; // Do not show the back button on the home page

    const left = isMobile ? "10px" : "20px";
    return (
      <div
        style={{ position: "fixed", left: left, top: "85px", zIndex: "1000" }}
      >
        <Button icon circular color="blue" onClick={goBack}>
          <Icon name="arrow left" />
        </Button>
      </div>
    );
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const placeholderTexts = [
      "search about your department...",
      "find information about holidays...",
      "look up contact details...",
      "explore the telephone directory...",
      "check notifications and inbox...",
    ];
    if (typingIndex < placeholderTexts[textIndex].length) {
      const typingTimeout = setTimeout(() => {
        setPlaceholder(
          (prev) => prev + placeholderTexts[textIndex][typingIndex]
        );
        setTypingIndex((prev) => prev + 1);
      }, 100);
      return () => clearTimeout(typingTimeout);
    } else {
      const nextTextTimeout = setTimeout(() => {
        setTypingIndex(0);
        setPlaceholder("");
        setTextIndex((prev) => (prev + 1) % placeholderTexts.length);
      }, 1500);
      return () => clearTimeout(nextTextTimeout);
    }
  }, [typingIndex, textIndex]);

  useEffect(() => {
    const person_id = localStorage.getItem("person_id");
    if (person_id) {
      fetchUserData(person_id)
        .then((data) => {
          setUserData(data.details.person);
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
        });
    }
  }, []);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 1200);
  };

  const srkMember =
    localStorage.getItem("srk_admin_id") ||
    localStorage.getItem("srk_fellow_id")
      ? true
      : false;
  const username = localStorage.getItem("name");
  const authfac_id = localStorage.getItem("authfac_id");
  const faculty = localStorage.getItem("person_t") === "FAC" ? true : false;
  const teachOrStaff =
    localStorage.getItem("person_t") !== "STU" ? true : false;
  const hostelAdminMember =
    localStorage.getItem("hostel_admin_id") ||
    localStorage.getItem("hostel_warden_id")
      ? true
      : false;
  const complaintSalaryMember =
    localStorage.getItem("complaint_salary_employee_id") ||
    localStorage.getItem("complaint_salary_admin_id")
      ? true
      : false;
  const psyCounselingMember =
    localStorage.getItem("psy_counsellor_id") ||
    localStorage.getItem("psy_admin_id")
      ? true
      : false;
  const personType = localStorage.getItem("person_t");
  const uwdUrl = process.env.REACT_APP_UWD_URL;

  return (
    <>
      {isMobile ? (
        <>
          <Menu borderless fixed="top" fluid>
            <Menu.Item as={Link} to="/" link>
              <Image src={logo} width="40" height="50" draggable="false" />
            </Menu.Item>
            <Menu.Menu position="right">
              <Menu.Item>
                <Icon
                  name="search"
                  onClick={() => setModalOpen2(true)}
                  style={{ cursor: "pointer" }}
                />
              </Menu.Item>
              <Menu.Item onClick={() => setSidebarVisible(true)}>
                <Icon name="sidebar" size="large" />
              </Menu.Item>
            </Menu.Menu>
          </Menu>
          <Sidebar
            as={Menu}
            direction="right"
            animation="overlay"
            onHide={() => setSidebarVisible(false)}
            vertical
            visible={sidebarVisible}
            width="thin"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Menu.Item
              className="centered-content"
              style={{ paddingBottom: "2rem", paddingTop: "2rem" }}
            >
              <Image
                src={logo}
                width="80"
                height="150"
                draggable="false"
                centered
                style={{ paddingBottom: "2rem" }}
              />
              <span style={{ fontSize: "1.2rem", marginBottom: "5rem" }}>
                Namaste BHU
              </span>
              <div>
                <br />
                Welcome 🙏
                <br />
                Guest
              </div>
            </Menu.Item>
            <div style={{ flex: 1 }}>
              {/* <Menu.Item
                as={Link}
                to="/notifications"
                active={currentPath === "/notifications"}
              >
                Notifications
              </Menu.Item>
              <Menu.Item
                as={Link}
                to="/contact"
                active={currentPath === "/contact"}
              >
                Contact Us
              </Menu.Item> */}
              <Menu.Item>
                <LoginButton type="btn" />
              </Menu.Item>
            </div>
          </Sidebar>
          {returnBackButton()}
        </>
      ) : (
        <>
          <Menu
            borderless
            fixed="top"
            fluid
            style={{
              margin: "0",
              height: "80px",
              paddingLeft: "1rem",
              paddingRight: "1rem",
            }}
          >
            <Menu.Item as={Link} to="/" link>
              <Image
                src={logo}
                width="40"
                height="50"
                style={{ marginRight: "1.5em" }}
              />
              <span style={{ fontSize: "1.5rem" }}>Namaste BHU</span>
            </Menu.Item>
            <Menu.Item className="centered-content">
              Welcome 🙏
              <br />
              Guest
            </Menu.Item>
            <Menu.Menu position="right">
              <Menu.Item>
                <NavbarSearchComponent />
              </Menu.Item>
              {/* <Menu.Item
                as={Link}
                to="/namaste-login"
                active={currentPath === "/notifications"}
              >
                Notifications
              </Menu.Item>
              <Menu.Item
                as={Link}
                to="/contact"
                active={currentPath === "/contact"}
              >
                Contact Us
              </Menu.Item> */}
              <Menu.Item>
                <LoginButton type="btn" />
              </Menu.Item>
            </Menu.Menu>
          </Menu>
          {returnBackButton()}
        </>
      )}
    </>
  );
};

export default memo(NavbarWithoutLogin);
