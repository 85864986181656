import React from "react";
import { Card, Icon, Statistic } from "semantic-ui-react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "../../Css/Calender-Custom.css";

export default function SrkFellowAnalysisCard({ data, name }) {
  // Filter the data based on the sender_name
  const filteredData = data.filter((item) => item.sender_name === name);

  // Get the count of filtered data
  const count = filteredData.length;

  // Extract all report dates
  const allReports = filteredData.map((item) => new Date(item.report_date));

  function reduceDatesByOne(allReports) {
    return allReports.reduce((acc, date) => {
      const newDate = new Date(date);
      newDate.setDate(newDate.getDate() - 1);
      acc.push(newDate);
      return acc;
    }, []);
  }

  // Convert dates to string in a standardized format
  const allReportDates = reduceDatesByOne(allReports).map(
    (date) => date.toISOString().split("T")[0]
  );

  // Calculate the number of reports sent this week
  const today = new Date();
  const startOfWeek = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - (today.getDay() + 6) % 7 // Get the date of the previous Monday
  );
  const endOfWeek = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + (6 - today.getDay()) // Get the date of the following Sunday
  );
  
  const reportsThisWeek = filteredData.filter((item) => {
    const reportDate = new Date(item.report_date);
    return reportDate >= startOfWeek && reportDate <= endOfWeek;
  }).length;
  
  

  const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  const reportsThisMonth = filteredData.filter((item) => {
    const reportDate = new Date(item.report_date);
    return reportDate >= startOfMonth && reportDate <= endOfMonth;
  }).length;

  return (
    <Card fluid color="green">
      <Card.Content>
        <Card.Header><Icon name="user" />{name}</Card.Header>
        <Card.Description>
          <Statistic.Group widths="three" size="small">
            <Statistic>
              <Statistic.Value>{count}</Statistic.Value>
              <Statistic.Label>Total Reports</Statistic.Label>
            </Statistic>
            <Statistic>
              <Statistic.Value>{reportsThisWeek}</Statistic.Value>
              <Statistic.Label>This Week</Statistic.Label>
            </Statistic>
            <Statistic>
              <Statistic.Value>{reportsThisMonth}</Statistic.Value>
              <Statistic.Label>This Month</Statistic.Label>
            </Statistic>
          </Statistic.Group>
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <div style={{ width: "100%" }}>
          <Calendar
            className={"full-width-calendar"}
            tileContent={({ date }) =>
              allReportDates.includes(date.toISOString().split("T")[0]) && (
                <Icon name="check circle" size="large" color="green" />
              )
            }
          />
        </div>
      </Card.Content>
    </Card>
  );
}
