import React, { useState, useEffect } from "react";
import { Grid, Table, Icon, Input, Button, Container, Dropdown } from "semantic-ui-react";
import { fetchHostelAdminReports, createComment, createCommentHostel } from "../api";
import LoaderComponent from "../LoaderComponent";
import NotificationSegment from "../Segments/NotificationSegment";
import WardenMessageTable from "../Tables/WardenMessageTable";
import WardenMessageViewCard from "../Cards/WardenMessageViewCard";
import SrkFellowAnalysisCard from "../Cards/SrkFellowAnalysisCard";
import "../../Css/SrkInbox.css";
import SrkGodModeAnalysisModal from "../Modals/SrkGodModeAnalysisModal";
import CommentModal from "../CommentModal"; // Import the CommentModal component


export function HostelAdmin(props) {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [selectedMsgUsername, setSetelectedMsgUsername] = useState();
  const [sortColumn, setSortColumn] = useState("submissionDate");
  const [sortDirection, setSortDirection] = useState("descending");
  const [searchQuery, setSearchQuery] = useState("");
  const [filterType, setFilterType] = useState("sender_name");
  const rowColors = [
    "#D1CCE6aa",
    "#F7D1C5aa",
    "#B7DFF9aa",
    "#F7D8DCaa",
    "#E8B5B8aa",
    "#EEDDF4aa",
    "#B8CDEBaa",
    "#F9C3A6aa",
    "#D4D4D4aa",
    // "#F7ECD7aa",
  ];
  const [showAnalysis, setShowAnalysis] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isCommentModalOpen, setCommentModalOpen] = useState(false);
  const [commentSubmitted, setCommentSubmitted] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const openCommentModal = () => setCommentModalOpen(true);
  const closeCommentModal = () => setCommentModalOpen(false);

  useEffect(() => {
    if (props && props.srk_admin_id) {
      setLoading(true);
      fetchHostelAdminReports(props.srk_admin_id)
        .then((response) => response.json())
        .then((data) => {
          setData(
            data.sort(
              (a, b) => new Date(b.submissionDate) - new Date(a.submissionDate)
            )
          );
          setLoading(false);
        })
        .catch((error) => {
          console.log("Failed to fetch inbox list: ", error);
          setLoading(false);
        });
    } else {
      console.log("State/State.person_id not available");
    }
  }, [props]);

  const selectMessage = (message) => {
    setSelectedMessage(message);
  };

  const toggleAnalysisView = () => {
    setShowAnalysis(!showAnalysis);
  };

  const setAnalysisView = (value) => {
    setShowAnalysis(value);
  };

  const handleSort = (clickedColumn) => () => {
    if (sortColumn !== clickedColumn) {
      setSortColumn(clickedColumn);
      setSortDirection("ascending");
      setData(data.slice().sort((a, b) => a[clickedColumn] - b[clickedColumn]));
    } else {
      setSortDirection(
        sortDirection === "ascending" ? "descending" : "ascending"
      );
      setData(data.slice().reverse());
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleFilterTypeChange = (e, { value }) => {
    setFilterType(value);
  };

  const handleCommentSubmit = (comment) => {
    const commentator = localStorage.getItem("hostel_admin_id");
    const payload = {
      report: selectedMessage,
      comment: comment,
      commentator: commentator,
    };
    createCommentHostel(payload)
      .then(() => {
        closeCommentModal();
        setCommentSubmitted(!commentSubmitted); // Toggle the commentSubmitted state to trigger a re-render
        // Add any additional actions here, like refreshing the data or showing a success message
      })
      .catch((error) => {
        console.log("Failed to submit comment: ", error);
      });
  };

  const filteredData = data.filter((item) =>
    item[filterType].toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Grid>
      {isLoading ? (
        <LoaderComponent loadingText={"Loading Notifications ..."} />
      ) : (
        <>
          <SrkGodModeAnalysisModal data={data} isModalOpen={isModalOpen} closeModal={closeModal} />
          <Grid.Column
            width={9}
            style={{ maxHeight: "80vh", overflowY: "auto", minHeight: "80vh" }}
          >
            <Grid columns={3} stackable>
              <Grid.Column width={10}>
                <Input
                  fluid
                  icon="search"
                  placeholder={`Search by ${filterType.replace('_', ' ')}...`}
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </Grid.Column>
              <Grid.Column width={5}>
                <Dropdown
                  fluid
                  selection
                  options={[
                    { key: 'sender_name', text: "Sender's Name", value: 'sender_name' },
                    { key: 'hostel_name', text: 'Hostel Name', value: 'hostel_name' },
                  ]}
                  value={filterType}
                  onChange={handleFilterTypeChange}
                />
              </Grid.Column>
              <Grid.Column width={1} style={{ paddingLeft: "0" }} textAlign="right">
                <Button icon="line graph" color="blue" onClick={openModal}/>
              </Grid.Column>
            </Grid>
            <Table selectable striped color="blue">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell
                    sorted={
                      sortColumn === "submissionDate" ? sortDirection : null
                    }
                    onClick={handleSort("submissionDate")}
                    style={{ cursor: "pointer" }}
                  >
                    Submission Date
                    {sortColumn === "submissionDate" && (
                      <Icon
                        name={
                          sortDirection === "ascending"
                            ? "sort up"
                            : "sort down"
                        }
                      />
                    )}
                  </Table.HeaderCell>
                  <Table.HeaderCell>Sender's Name</Table.HeaderCell>
                  <Table.HeaderCell>Hostel Name</Table.HeaderCell>
                  <Table.HeaderCell>Content</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {filteredData.map((item, index) => (
                  <WardenMessageTable
                    key={item.note_id}
                    item={item}
                    selectMessage={selectMessage}
                    setSetelectedMsgUsername={setSetelectedMsgUsername}
                    cardBgColor={rowColors[index % rowColors.length]}
                    from="Inbox"
                    setAnalysisView={setAnalysisView}
                  />
                ))}
              </Table.Body>
            </Table>
          </Grid.Column>
          {selectedMessage ? (
            <Grid.Column
              width={7}
              style={{
                transition: "opacity 0.5s",
                opacity: 1,
                maxHeight: "80vh",
                overflowY: "auto",
              }}
            >
              <Container fluid textAlign="right">
                {/* <Button.Group> */}
                  <Button icon onClick={toggleAnalysisView}>
                    <Icon name="calendar alternate" />
                  </Button>
                  <Button icon onClick={openCommentModal} color="blue">
                    <Icon name="add" />
                    Add Comments
                  </Button>
                {/* </Button.Group> */}
              </Container>

              {showAnalysis && <SrkFellowAnalysisCard
                data={data}
                name={selectedMsgUsername}
              />}

              <WardenMessageViewCard
                id={selectedMessage}
                commentSubmitted={commentSubmitted}
              />
            </Grid.Column>
          ) : (
            <Grid.Column
              width={7}
              style={{
                transition: "opacity 0.5s",
                opacity: 1,
                maxHeight: "80vh",
                overflowY: "auto",
              }}
            >
              <NotificationSegment />
            </Grid.Column>
          )}
        </>
      )}
      <CommentModal
        open={isCommentModalOpen}
        onClose={closeCommentModal}
        onSubmit={handleCommentSubmit}
      />
    </Grid>
  );
}
