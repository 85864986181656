import React from "react";
import { Form, Grid, Input, Button } from "semantic-ui-react";

const CustomForm = ({ formState, setFormState, onSubmit }) => {
  const handleChange = (e, { name, value }) => {
    setFormState(prevState => ({
      ...prevState,
      [name]: { ...prevState[name], value }
    }));
  };

  return (
    <Form autoComplete="off" onSubmit={onSubmit}>
      <Grid columns={1} stackable>
        <Grid.Column width={16}>
          <Form.Field>
            <label>{formState.name.label}</label>
            <Input
              name="name"
              value={formState.name.value}
              onChange={handleChange}
              placeholder="Enter name"
            />
          </Form.Field>
          <Form.Field>
            <label>{formState.department.label}</label>
            <Input
              name="department"
              value={formState.department.value}
              onChange={handleChange}
              placeholder="Enter department"
            />
          </Form.Field>
          {formState.designation && <Form.Field>
            <label>{formState.designation.label}</label>
            <Input
              name="designation"
              value={formState.designation.value}
              onChange={handleChange}
              placeholder="Enter designation"
            />
          </Form.Field>}
          {formState.phone_number && <Form.Field>
            <label>{formState.phone_number.label}</label>
            <Input
              name="phone_number"
              value={formState.phone_number.value}
              onChange={handleChange}
              placeholder="Enter phone number"
            />
          </Form.Field>}
          <Button fluid primary type="submit">
        Search
      </Button>
        </Grid.Column>
      </Grid>
    </Form>
  );
};

export default CustomForm;
