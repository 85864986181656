import { useState, useEffect } from "react";
import { useLocation, useParams, Link } from "react-router-dom";
import { fetchDepartmentDetails, fetchFacultyList } from "../Components/api";
import LoaderComponent from "../Components/LoaderComponent";
import { Container, Segment, Header, Divider, Icon } from "semantic-ui-react";
import ResultsTable from "../Components/ResultsTable";
import { formatResponseDataKey } from "../Components/utils";
import ProgramsList from "../Components/ProgramsList";
import { useAuth } from "../Context/AuthContext";
import Navbar from "../Components/Navbar";
import SearchModal from "../Components/Modals/SearchModal";
import NavbarWithoutLogin from "./WithoutLogin/NavbarWithoutLogin";

export default function DepartmentDetails(props) {
  const loggedIn = localStorage.getItem("isLoggedIn") === "true";
  const tableHeadersIns = [
    {
      key: "faculty_name",
      label: "Name",
      width: 4,
    },
    {
      key: "designation",
      label: "Designation",
      width: 4,
    },
  ];
  const [departmentDetails, setDetails] = useState();
  const [isLoading, setLoading] = useState(false);
  const [isResponseRecievedForFaculties, showResponseForFaculties] =
    useState(false);
  const [resultsForFaculties, setResultsForFaculties] = useState({
    data: [],
    msg: "",
  });
  const { state } = useLocation();

  const { departmentid } = useParams();

  const [showLessAboutData, setAboutDataVisibility] = useState(true);
  let departmentId = -1;
  const { logout } = useAuth();
  const [isModalOpen, setModalOpen] = useState(false);
  const closeModal = () => setModalOpen(false);

  useEffect(() => {
    if (state && state.departmentId) {
      departmentId = state.departmentId;
    } else if (departmentid) {
      departmentId = departmentid;
    }
    // fetch department details
    setLoading(true);
    fetchDepartmentDetails(departmentId)
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          setDetails(data);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error("Failed to get department details: ", err);
        setLoading(false);
      });
  }, [departmentid, state]);

  useEffect(() => {
    fetchFacultyList(departmentId)
      .then((res) => res.json())
      .then((data) => {
        console.log(data + "hello");
        if (data && data.length > 0) {
          // Update the results with filtered institutes
          setResultsForFaculties({
            data: data,
            msg: `${data.length} faculties found`,
          });
          showResponseForFaculties(true);
        }
      })
      .catch((err) => {
        console.error("Failed to fetch institutes list: ", err);
      });
  }, []);
  const renderMoreDetailsComponentForFaculty = (data) => {
    return (
      <Link
        to={"/person/" + data.id + "/"}
        state={{ facultyDetails: data ? data : null }}
      >
        Click here
      </Link>
    );
  };

  const renderFormattedAboutData = (data) => {
    // format the about data into readable one
    // replace '\r\n' part of text with newline
    let formattedDataArr = data.split("\r\n");
    let truncatedData = data.substr(0, 199);
    return showLessAboutData ? (
      <p>
        {truncatedData}{" "}
        <span
          style={{ cursor: "pointer", color: "#4183c4" }}
          onClick={() => setAboutDataVisibility(false)}
        >
          ...See more
        </span>
      </p>
    ) : (
      <Segment basic>
        {formattedDataArr.map((txt) => {
          return (
            <p>
              {txt} {"\n"}
            </p>
          );
        })}
        <span
          style={{ cursor: "pointer", color: "#4183c4" }}
          onClick={() => setAboutDataVisibility(true)}
        >
          See less
        </span>
      </Segment>
    );
  };

  const renderDepartmentDetails = () => {
    if (departmentDetails) {
      return (
        <Segment basic>
          {Object.keys(departmentDetails).map((key) => {
            if (
              key === "id" ||
              key === "name" ||
              key === "programs" ||
              key === "pretty" ||
              key === "parent_id" ||
              key === "parent" ||
              key === "dept_email" ||
              key === "head" ||
              key === "dept_type"
            )
              return null; // don't show these data

            return (
              <p key={key}>
                <strong>
                  {key === "yoe"
                    ? "Year of Establishment"
                    : formatResponseDataKey(key)}
                </strong>{" "}
                -{" "}
                <span>
                  {departmentDetails[key] ? (
                    key === "about" ? (
                      renderFormattedAboutData(departmentDetails[key])
                    ) : key === "web_url" ? (
                      <a
                        href={departmentDetails[key]}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Click here
                      </a>
                    ) : key === "coordinates" ? (
                      <a
                        href={`${departmentDetails[key]}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View on Map
                      </a>
                    ) : (
                      departmentDetails[key]
                    )
                  ) : (
                    "Not Available "
                  )}
                </span>
              </p>
            );
          })}
        </Segment>
      );
    } else return <h3>Department data not found</h3>;
  };
  if (isLoading) {
    return <LoaderComponent loadingText="Loading department details ... " />;
  }
  return (
    <>
      {loggedIn ? (
        <Navbar onLogout={logout} setModalOpen2={setModalOpen} />
      ) : (
        <NavbarWithoutLogin />
      )}
      <SearchModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <Container>
        <div style={{ paddingTop: "90px" }}>
          <Segment basic padded>
            <Header as="h1" className="heading-font">
              <Icon name="university" />
              <Header.Content>
                {departmentDetails && departmentDetails.name
                  ? departmentDetails.name + " details"
                  : "Department details"}
              </Header.Content>
            </Header>
            <Divider />

            {renderDepartmentDetails()}
            {departmentDetails &&
            departmentDetails.programs &&
            departmentDetails.programs.length > 0 ? (
              <ProgramsList list={departmentDetails.programs} />
            ) : (
              <Header as="h3">No programs found</Header>
            )}
            {isResponseRecievedForFaculties ? (
              <>
                <Header as="h1" className="heading-font">
                  <Icon name="user" />
                  <Header.Content>Faculty List</Header.Content>
                </Header>
                <Divider />
                <ResultsTable
                  results={resultsForFaculties}
                  setResults={setResultsForFaculties}
                  tableHeaders={tableHeadersIns}
                  extraDetailsHdrTxt="Faculty Details"
                  extraDetailsComponent={renderMoreDetailsComponentForFaculty}
                />
              </>
            ) : null}
          </Segment>
        </div>
      </Container>
    </>
  );
}
