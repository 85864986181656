import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Dropdown,
  Message,
  TextArea,
} from "semantic-ui-react";

import { createNotification, fetchGroupsListForAuthFac } from "../api";

export function CreateForm() {
  const authfac_id = localStorage.getItem('authfac_id');
  const [groups, setGroups] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formError, setFormError] = useState(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    const title = event.target.title.value;
    const message = event.target.message.value;
    const file = event.target.file.files[0];

    if (!selectedGroups.length || !title || !message) {
      setFormError("* All required fields must be filled out.");
      return;
    }

    setIsSubmitting(true);
    setFormError(null);

    const formData = new FormData();
    formData.append('title', title);
    formData.append('body', message);
    formData.append('groups', JSON.stringify(selectedGroups));
    if (file) {
      formData.append('attachment', file);
    }
    formData.append('sender', authfac_id);

    createNotification(formData)
      .then((response) => response.json())
      .then((data) => {
        if (data && data["detail"]) {
          setSubmissionStatus("error");
        } else {
          setSubmissionStatus("success");
          event.target.reset();
          setSelectedGroups([]);
        }
      }).catch((error) => {
        setSubmissionStatus("error");
      }).finally(() => {
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    fetchGroupsListForAuthFac(authfac_id)
      .then((response) => response.json())
      .then((data) => {
        const groupOptions = data.map(group => ({
          key: group.id,
          text: group.name,
          value: group.id,
        }));
        setGroups(groupOptions);
      });
  }, [authfac_id]);

  const handleChange = (e, { value }) => setSelectedGroups(value);

  return (
    <Form success={submissionStatus === "success"} error={!!formError || submissionStatus === "error"} onSubmit={handleSubmit}>
      <Form.Field required={true}>
        <label>Group</label>
        <Dropdown
          placeholder="Select Group"
          fluid
          multiple
          search
          selection
          options={groups}
          onChange={handleChange}
          value={selectedGroups}
          name="groups" />
      </Form.Field>
      <Form.Field required={true}>
        <label>Subject</label>
        <Input placeholder="Subject of the notice" name="title" />
      </Form.Field>
      <Form.Field required={true}>
    <label>Message of the notice</label>
      <TextArea placeholder="Write your message here..." name="message" />
      </Form.Field>
      <Form.Field>
        <label>File Upload</label>
        <Input type="file" accept=".pdf,image/*" name="file" />
      </Form.Field>
      {formError && (
        <Message
          error
          content={formError}
        />
      )}
      {submissionStatus === "success" && (
        <Message
          success
          header="Notification Sent"
          content="Your notification has been sent successfully."
        />
      )}
      {submissionStatus === "error" && (
        <Message
          error
          header="Failed to Send Notification"
          content="There was an error sending your notification. Please try again. If the error remains, please contact the admin."
        />
      )}
      <Button type="submit" loading={isSubmitting} primary>
        Submit
      </Button>
    </Form>
  );
}
