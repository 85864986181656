import React, { useState, useEffect } from "react";
import { Grid, Table } from "semantic-ui-react";
import { fetchSentNotification } from "../api";
import LoaderComponent from "../LoaderComponent";
import MessageViewCard from "../Cards/MessageViewCard";
import NotificationStatsModal from "../Modals/NotificationStatsModal";
import NotificationSegment from "../Segments/NotificationSegment";
import MessageTable from "../Tables/MessageTable";

export function Sent(props) {
  const [data, setData] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isLoading, setLoading] = useState(false); // managing the loading state
  const rowColors = [
    "#D1CCE6aa",
    "#F7D1C5aa",
    "#B7DFF9aa",
    "#F7D8DCaa",
    "#E8B5B8aa",
    "#EEDDF4aa",
    "#B8CDEBaa",
    "#F9C3A6aa",
    "#D4D4D4aa",
    // "#F7ECD7aa",
  ];


  useEffect(() => {
    if (props && props.authfac_id) {
      setLoading(true);
      fetchSentNotification()
        .then((response) => response.json())
        .then((data) => {
          setData(data);
          setLoading(false);
        })
        .catch((error) => {
          console.log("Failed to fetch inbox list: ", error);
          setLoading(false);
        });
    } else {
      console.log("State/State.person_id not available");
    }
  }, [props]);

  const selectMessage = (message) => {
    setSelectedMessage(message);
  };

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  return (
    <Grid>
      {isLoading ? (
        <LoaderComponent loadingText={"Loading Sent Notifications ..."}/>
      ) : (
        <>
          <Grid.Column
            width={9}
            style={{ maxHeight: "80vh", overflowY: "auto", minHeight:"80vh"}}
          >
            {isLoading ? (
              <LoaderComponent loadingText={"Loading Sent Notifications ..."} />
            ) : 
            <Table selectable striped>
            <Table.Body>
              {data.map((item, index) => (
                <MessageTable
                  key={item.note_id}
                  item={item}
                  selectMessage={selectMessage}
                  cardBgColor={rowColors[index % rowColors.length]}
                />
              ))}
            </Table.Body>
          </Table>
            // (
            //   data.map((item) => (
            //     <MessageCard
            //       item={item}
            //       cardColor={"blue"}
            //       cardBgColor={"aliceblue"}
            //       selectMessage={selectMessage}
            //     />
            //   ))
            // )
            
            }
          </Grid.Column>
          {/* Selected message view */}
          {selectedMessage ? (
            <Grid.Column
              width={7}
              style={{ maxHeight: "80vh", overflowY: "auto" }}
            >
              <MessageViewCard
                selectedMessage={selectedMessage}
                type="Sent"
                openModal={openModal}
              />
            </Grid.Column>
          ) : (
            <Grid.Column
              width={7}
              style={{ maxHeight: "80vh", overflowY: "auto" }}
            >
              <NotificationSegment />
            </Grid.Column>
          )}

          {/* Modal for displaying stats */}
          <NotificationStatsModal
            isModalOpen={isModalOpen}
            closeModal={closeModal}
            selectedMessage={selectedMessage}
          />
        </>
      )}
    </Grid>
  );
}
