import React, { useState, useEffect } from "react";
import { Form, Input, Button, Dropdown, Message } from "semantic-ui-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { createNotification, createSalaryComplaint, fetchGroupsListForAuthFac, fetchSectionForSalaryComplaints } from "../api";

export function SalaryComplaintForm() {
  const authfac_id = localStorage.getItem("authfac_id");
  const [sectionOptions, setGroups] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formData, setFormData] = useState({
    section: "",
    name: "",
    applicationId: "",
    projectId: "",
    salaryPendingYears: "",
    salaryPendingMonths: "",
    extra: "",
    salaryPending:""
  });

  const [selectedDate, setSelectedDate] = useState(null);

  const handleChange = (e, { name, value }) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Validation check
    if (!formData.section || !formData.name || !formData.projectId || !formData.salaryPending) {
      setSubmissionStatus("validation_error");
      return;
    }

    setIsSubmitting(true);
    const sender = localStorage.getItem("complaint_salary_employee_id");
    const body = `
      \nName: ${formData.name}
      Project Name: ${formData.projectId}
      Salary Pending Period: ${formData.salaryPending}
      ${ formData.extra ? "Any Other Information:" + formData.extra : ""}
    `;
    const finalData = new FormData();
    finalData.append('sender', sender);
    finalData.append('body', body);
    finalData.append('section', formData.section);
    
    console.log(finalData);
    
    createSalaryComplaint(finalData)
    .then((response) => response.json())
    .then((data) => {
      if (data && data["detail"]) {
        setSubmissionStatus("error");
      } else {
        setSubmissionStatus("success");
        event.target.reset();
        setSelectedGroups([]);
        setFormData({
          section: "",
          name: "",
          applicationId: "",
          projectId: "",
          salaryPendingYears: "",
          salaryPendingMonths: "",
          extra: "",
        });
        setSelectedDate(null);
      }
    }).catch((error) => {
      setSubmissionStatus("error");
    }).finally(() => {
      setIsSubmitting(false);
    });
  };

  useEffect(() => {
    fetchSectionForSalaryComplaints(authfac_id)
      .then((response) => response.json())
      .then((data) => {
        const groupOptions = data.map((group) => ({
          key: group.id,
          text: group.name,
          value: group.id,
        }));
        setGroups(groupOptions);
      });
  }, [authfac_id]);

  const currentDate = new Date();
  const endOfCurrentMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

  return (
    <Form success error onSubmit={handleSubmit}>
      <Form.Field required>
        <label>Section</label>
        <Dropdown
          placeholder="Select Section"
          fluid
          selection
          options={sectionOptions}
          name="section"
          value={formData.section}
          onChange={handleChange}
        />
      </Form.Field>
      <Form.Field required>
        <label>Name(s) of Employee(s)</label>
        <Form.Input
          placeholder="Name(s)"
          name="name"
          value={formData.name}
          onChange={handleChange}
        />
      </Form.Field>
      <Form.Field required>
        <label>Project Name</label>
        <Form.Input
          placeholder="Project Name"
          name="projectId"
          value={formData.projectId}
          onChange={handleChange}
        />
      </Form.Field>
      <Form.Field required>
        <label>Salary Pending Period</label>
        <Form.Input
          placeholder="Salary Pending Period"
          name="salaryPending"
          value={formData.salaryPending}
          onChange={handleChange}
        />
      </Form.Field>
      {/* <Form.Field required>
        <label>Salary Pending Since</label>
        <DatePicker
          selected={selectedDate}
          onChange={date => setSelectedDate(date)}
          dateFormat="MM/yyyy"
          showMonthYearPicker
          placeholderText="Select Month and Year"
          className="react-datepicker__input-container"
          maxDate={endOfCurrentMonth}
        />
      </Form.Field> */}
      <Form.Field>
        <label>Any Other Information</label>
        <Form.TextArea
          placeholder="Any Other Information"
          name="extra"
          value={formData.extra}
          onChange={handleChange}
        />
      </Form.Field>
      {submissionStatus === "success" && (
        <Message
          success
          header="Complaint Sent"
          content="Your Complaint has been sent successfully."
        />
      )}
      {submissionStatus === "error" && (
        <Message
          error
          header="Failed to Send Complaint"
          content="There was an error sending your complaint. Please try again. If the error remains, please contact the admin."
        />
      )}
      {submissionStatus === "validation_error" && (
        <Message
          error
          header="Missing Required Fields"
          content="Please fill out all required fields before submitting."
        />
      )}
      <Button type="submit" loading={isSubmitting} primary>
        Submit
      </Button>
    </Form>
  );
}
