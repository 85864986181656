import React from "react";
import { createRoot } from "react-dom/client"; // Import createRoot
import App from "./App";
import "semantic-ui-css/semantic.min.css";
import { AuthProvider } from "./Context/AuthContext";

const container = document.getElementById("root");
const root = createRoot(container); // Create a root.

root.render(
  <AuthProvider>
    {/* <React.StrictMode> */}
      <App />
    {/* </React.StrictMode> */}
  </AuthProvider>
);
