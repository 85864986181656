import React, { useState } from "react";
import { useAuth } from "../Context/AuthContext";
import Navbar from "../Components/Navbar";
import { Container, Header, Menu, Segment } from "semantic-ui-react";
import "../Css/Inbox.css";

// import { Inbox } from "../Components/Notification/Inbox";
// import { Sent } from "../Components/Notification/Sent";
import { HostelAdmin, SrkInbox2 } from "../Components/SRK2/HostelAdmin";
import SearchModal from "../Components/Modals/SearchModal";
import { HostelWarden } from "../Components/SRK2/HostelWarden";
// import { CreateForm } from "../Components/Notification/CreateForm";

export default function Warden() {
  const { logout } = useAuth();
  const handleItemClick = (name) => setActiveItem(name);
  const srk_fellow_id = localStorage.getItem("srk_fellow_id");
  const srk_admin_id = localStorage.getItem("srk_admin_id");
  const [isModalOpen, setModalOpen] = useState(false);
  const closeModal = () => setModalOpen(false);
  const hostel_admin_id = localStorage.getItem("hostel_admin_id");
  const hostel_warden_id = localStorage.getItem("hostel_warden_id");
  const [activeItem, setActiveItem] = useState(hostel_warden_id ? "sent" : "all-reports");

  return (
    <>
      <Navbar onLogout={logout} setModalOpen2={setModalOpen} />
      <SearchModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <Container fluid style={{ paddingLeft: "10vw", paddingRight: "10vw" }}>
        <div style={{ paddingTop: "90px" }}>
        <Header as={"h2"} style={{fontFamily: "Times New Roman, Georgia, serif"}}>Wardens</Header>
          <Menu
            pointing
            // style={srk_fellow_id ? { display: "flex" } : { display: "none" }}
          >
            {hostel_warden_id && (
              <Menu.Item
                name="sent"
                active={activeItem === "sent"}
                onClick={() => handleItemClick("sent")}
              />
            )}

            {hostel_admin_id && (
              <Menu.Item
                name="all-reports"
                active={activeItem === "all-reports"}
                onClick={() => handleItemClick("all-reports")}
              />
            )}
            {/* <Menu.Item
              name="create"
              active={activeItem === "create"}
              onClick={() => handleItemClick("create")}
            /> */}
          </Menu>

          <Segment>
            {activeItem === "sent" && (
              <HostelWarden srk_admin_id={hostel_warden_id} />
            )}
            {activeItem === "all-reports" && (
              <HostelAdmin srk_admin_id={hostel_admin_id} />
            )}

            {/* {authfac_id && activeItem === "sent" && <Sent authfac_id={authfac_id} />}  */}

            {/* {authfac_id && activeItem === "create" && <CreateForm />} */}
          </Segment>
        </div>
      </Container>
    </>
  );
}
