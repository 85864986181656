import React, { useState, memo } from "react";
import { useAuth } from "../Context/AuthContext";
import {
  Container,
  Grid,
  Segment,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import Navbar from "./Navbar";
import UpcomingHolidays from "./UpcomingHolidays";
import SearchModal from "./Modals/SearchModal";
import DashPannelOne from "./DashPannelOne";
import HomePageNotification from "./Notification/HomePageNotification";
import WeatherComponent from "./Weather/WeatherComponent";

function Home() {
  const { logout } = useAuth();
  const [isModalOpen, setModalOpen] = useState(false);
  const closeModal = () => setModalOpen(false);

  return (
    <>
      <Navbar onLogout={logout} setModalOpen2={setModalOpen} />
      <SearchModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <div style={{ paddingTop: "100px" }}>
        <Container>
          <DashPannelOne />
          <Grid stackable columns={1} style={{ marginTop: "20px" }}>
            <Grid.Column>
              <Grid columns={2} centered stackable>
                <Grid.Column width={7}>
                  <WeatherComponent />
                </Grid.Column>
                <Grid.Column width={9}>
                  <h2
                    style={{
                      paddingBottom: "0.8rem",
                      color: "black",
                      textAlign: "center",
                    }}
                  >
                    Latest Notifications
                  </h2>
                  <HomePageNotification />
                </Grid.Column>
              </Grid>
            </Grid.Column>

            <Grid.Column>
              <h3
                style={{
                  marginBottom: "10px",
                  color: "black",
                  textAlign: "center",
                }}
              >
                Upcoming Holidays & Restricted Holidays
              </h3>
              <Segment
                basic
                as={Link}
                to="/holidays"
                style={{ width: "100%", textAlign: "center" }}
              >
                {/* <HolidayList /> */}
                {/* <HolidayDropdowns /> */}
                <UpcomingHolidays />
              </Segment>
            </Grid.Column>
          </Grid>
        </Container>
      </div>
    </>
  );
}

export default memo(Home);
