import { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { fetchCoursesList } from "../Components/api";
import {
  Container,
  Segment,
  Header,
  Divider,
  Icon,
  List,
  Table,
} from "semantic-ui-react";
import LoaderComponent from "../Components/LoaderComponent";
import { useAuth } from "../Context/AuthContext";
import Navbar from "../Components/Navbar";
import SearchModal from "../Components/Modals/SearchModal";
import NavbarWithoutLogin from "./WithoutLogin/NavbarWithoutLogin";

export default function CoursesList(props) {
  const loggedIn = localStorage.getItem("isLoggedIn") === "true";
  const { state } = useLocation();
  const [coursesList, setCourses] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const { logout } = useAuth();
  const [isModalOpen, setModalOpen] = useState(false);
  const closeModal = () => setModalOpen(false);
  // const [deptId, setDept] = useState(8);

  useEffect(() => {
    // Check if the state object has courses list
    if (state && state.courses) {
      setCourses(state.courses);
    } else {
      // make API call to fetch all the courses
      setLoading(true);
      fetchCoursesList()
        .then((res) => res.json())
        .then((data) => {
          if (data && data.length > 0) {
            setCourses(data);
          } else {
            console.error("Could not find courses data in the response");
            setCourses([]);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.error("Could not fetch courses list: ", err);
          setCourses([]);
          setLoading(false);
        });
    }
  }, [state]);

  const renderAllCoursesList = () => {
    if (!coursesList || coursesList.length === 0) {
      return <h2>No courses found</h2>;
    }
    return (
      <List celled relaxed>
        {coursesList.map((crs) => {
          return (
            <Link
              key={crs.id}
              to={"/course/" + crs.id + "/"}
              state={{ /*department: deptId,*/ course: crs.id }}
            >
              <Segment>
                <List.Header as="h3">
                  <Icon name="book" />
                  {crs.name}
                </List.Header>
                <List.Content>{crs.code}</List.Content>
                <List.Content>
                  <Icon name="university" />
                  {crs.department_name}
                </List.Content>
                <List.Content>
                  <Icon name="student" />
                  {crs.program}
                </List.Content>
              </Segment>
            </Link>
          );
        })}
      </List>
    );
  };

  const renderCoursesForProgram = () => {
    if (!coursesList || coursesList.length === 0) {
      return <h2>No courses found</h2>;
    }
    //sort the courses a/c semester
    const sortedCourses = coursesList.sort((a, b) =>
      a.for_semester > b.for_semester ? 1 : -1
    );
    const sortedCoursesObj = {};

    sortedCourses.forEach((course) => {
      if (sortedCoursesObj[course.for_semester]) {
        // means we need to just add this courses in the list
        sortedCoursesObj[course.for_semester] = [
          ...sortedCoursesObj[course.for_semester],
          course,
        ];
      } else {
        sortedCoursesObj[course.for_semester] = [course];
      }
    });
    // console.log(sortedCoursesObj);
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Table
          basic
          compact
          striped
          textAlign="start"
          padded
          size="large"
          style={{ width: "70%" }}
        >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={8}>Course Name</Table.HeaderCell>
              <Table.HeaderCell width={4}>Course Code</Table.HeaderCell>
              <Table.HeaderCell width={4}>Credits</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          {Object.keys(sortedCoursesObj).map((semKey) => {
            return (
              <Table.Body key={semKey}>
                <Table.Row>
                  <Table.Cell textAlign="right">
                    <Header as="h3" className="heading-font">
                      Semester {semKey}
                    </Header>
                  </Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell>
                </Table.Row>
                {sortedCoursesObj[semKey].map((crs) => {
                  return (
                    <Table.Row key={crs.id}>
                      <Table.Cell>
                        <Link
                          to={"/course/" + crs.id + "/"}
                          state={{ course: crs.id }}
                        >
                          {crs.name}
                        </Link>
                      </Table.Cell>
                      <Table.Cell>
                        {crs.code ? crs.code : "Not Available "}
                      </Table.Cell>
                      <Table.Cell>
                        {crs.credits ? crs.credits : "Not Available "}
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            );
          })}
        </Table>
      </div>
    );
  };

  if (isLoading) {
    return <LoaderComponent loadingText="Loading courses list..." />;
  }
  return (
    <>
      {loggedIn ? (
        <Navbar onLogout={logout} setModalOpen2={setModalOpen} />
      ) : (
        <NavbarWithoutLogin />
      )}
      <SearchModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <Container>
        <div style={{ paddingTop: "90px" }}>
          <Segment basic padded>
            <Header as="h1" className="heading-font">
              <Icon name="book" />
              <Header.Content>Courses List</Header.Content>
            </Header>
            <Divider />
            {state && state.courses
              ? renderCoursesForProgram()
              : renderAllCoursesList()}
          </Segment>
        </div>
      </Container>
    </>
  );
}
