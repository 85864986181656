import React, { memo } from "react";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import { Grid, Icon, Segment, Statistic } from "semantic-ui-react";
const DashPannelOne = () => {
  function HomeComponent({ title, count, iconName, path }) {
    return (
      <Grid.Column textAlign="center" style={{ padding: "10px" }}>
        <Segment basic style={{ borderRadius: "10px" }}>
            <Link to={path} style={{ textDecoration: "none" }}>
          <Statistic size="large">
            <Icon name={iconName} size="large" />
              <Statistic.Value>
                <CountUp end={count} duration={2} />
              </Statistic.Value>
            <Statistic.Label>{title}</Statistic.Label>
          </Statistic>
            </Link>
        </Segment>
      </Grid.Column>
    );
  }
  return (
    <Grid stackable columns={4}>
      <HomeComponent
        title="Institutes"
        count={5}
        iconName="university"
        path="/institutes"
      />
      <HomeComponent
        title="Faculties"
        count={16}
        iconName="graduation cap"
        path="/faculties"
      />
      <HomeComponent
        title="Departments"
        count={192}
        iconName="building"
        path="/departments"
      />
      <HomeComponent
        title="Programs"
        count={552}
        iconName="book"
        path="/programs"
      />
    </Grid>
  );
}

export default memo(DashPannelOne);