// ScrollButton.js

import React, { useState, useEffect } from 'react';
import { Button, Icon } from 'semantic-ui-react';

const ScrollButton = () => {
  const [showTopButton, setShowTopButton] = useState(false);
  const [showBottomButton, setShowBottomButton] = useState(true);

  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;

    if (scrollTop === 0) {
      setShowTopButton(false);
      setShowBottomButton(true);
    } else if (scrollTop + clientHeight >= scrollHeight) {
      setShowTopButton(true);
      setShowBottomButton(false);
    } else {
      setShowTopButton(true);
      setShowBottomButton(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const scrollToBottom = () => {
    window.scrollTo({ top: document.documentElement.scrollHeight, behavior: 'smooth' });
  };

  return (
    <div style={{ position: 'fixed', right: '20px', bottom: '80px', zIndex: '1000' }}>
      {showTopButton && (
        <Button icon circular color='blue' onClick={scrollToTop}>
          <Icon name='arrow up' />
        </Button>
      )}
      {showBottomButton && (
        <Button icon circular color='blue' onClick={scrollToBottom}>
          <Icon name='arrow down' />
        </Button>
      )}
    </div>
  );
};

export default ScrollButton;
