import { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Container, Segment, Header, Divider, Icon } from "semantic-ui-react";
import { fetchCourseDetails } from "../Components/api";
import { formatResponseDataKey } from "../Components/utils";
import LoaderComponent from "../Components/LoaderComponent";
import { useAuth } from "../Context/AuthContext";
import Navbar from "../Components/Navbar";
import SearchModal from "../Components/Modals/SearchModal";
import NavbarWithoutLogin from "./WithoutLogin/NavbarWithoutLogin";

export default function CourseDetails(props) {
  const loggedIn = localStorage.getItem("isLoggedIn") === "true";
  const [courseDetails, setCourseDetails] = useState();
  const { state } = useLocation();
  const [isLoading, setLoading] = useState(false);

  const { courseid } = useParams();

  const [isModalOpen, setModalOpen] = useState(false);
  const closeModal = () => setModalOpen(false);
  const { logout } = useAuth();

  useEffect(() => {
    // console.log(state);
    // If state object contains course id, fetch the course details
    let courseId = -1;
    if (state && state.course) {
      courseId = state.course;
    } else if (courseid) {
      courseId = courseid;
    }
    setLoading(true);
    fetchCourseDetails(courseId)
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          setCourseDetails(data);
          console.log(data);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error("Could not fetch course details: ", err);
        setLoading(false);
      });
  }, [courseid, state]);

  const renderFormattedSyllabusData = (data) => {
    // format the syllabus data into readable one
    // replace '\r\n' and '\n' part of text with newline
    let formattedDataArr = data.split("\r\n"); //.join("").split("\n");
    const newFormattedSyllabus = formattedDataArr.join("");
    if (newFormattedSyllabus.includes("\n")) {
      formattedDataArr = newFormattedSyllabus.split("\n");
    }
    return (
      <Segment basic>
        {formattedDataArr.map((txt) => {
          return (
            <p>
              {txt} {"\n"}
            </p>
          );
        })}
      </Segment>
    );
  };
  const renderCourseDetails = () => {
    if (courseDetails) {
      return (
        <Segment basic>
          {Object.keys(courseDetails).map((key) => {
            if (
              key === "id" ||
              key === "name" ||
              key === "program_id" ||
              key === "program_type" ||
              key === "department_id" ||
              key === "web_url" ||
              key === "about" ||
              key === "program"
            )
              return null; // don't show id, name

            let valueToRender = courseDetails[key];

            // Handle objects differently
            if (typeof valueToRender === "object") {
              if (Array.isArray(valueToRender)) {
                // Render array elements' names
                valueToRender = valueToRender
                  .map((item) => item.name)
                  .join(", ");
              } else {
                // Stringify other objects
                valueToRender = JSON.stringify(valueToRender);
              }
            }

            return (
              <p key={key}>
                <strong>{formatResponseDataKey(key)}</strong> -{" "}
                <span>{valueToRender || "Not Available "}</span>
              </p>
            );
          })}
        </Segment>
      );
    } else return <h3>Course data not found</h3>;
  };

  if (isLoading) {
    return <LoaderComponent loadingText="Loading course details..." />;
  }
  return (
    <>
      {loggedIn ? (
        <Navbar onLogout={logout} setModalOpen2={setModalOpen} />
      ) : (
        <NavbarWithoutLogin />
      )}
      <SearchModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <Container>
        <div style={{ paddingTop: "90px" }}>
          <Segment basic padded>
            <Header as="h1" className="heading-font">
              <Icon name="book" />
              <Header.Content>
                {courseDetails && courseDetails.name
                  ? courseDetails.name
                  : "Course Dashboard"}
              </Header.Content>
            </Header>
            <Divider />

            {renderCourseDetails()}
          </Segment>
        </div>
      </Container>
    </>
  );
}
