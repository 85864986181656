export const generateCToken = async (person_id, key_text) => {
  const CryptoJS = require("crypto-js");
  const key = CryptoJS.enc.Utf8.parse(key_text); //bytes
  const epoch_time = Math.floor(Date.now() / 1000);
  const plaintext_data = `${person_id}${epoch_time}`;
  const encrypted = CryptoJS.RC4.encrypt(plaintext_data, key);
  const encrypted_data_base64 = encrypted.toString();

  //   console.log(`Encrypted data (Base64): ${encrypted_data_base64}`);
  return encrypted_data_base64;
};
