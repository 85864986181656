import { Dimmer, Loader } from "semantic-ui-react";

export default function LoaderComponent({ loadingText, dimmer = false }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
        // zIndex: 1005,
      }}
    >
      {dimmer ?
      <Dimmer active={dimmer}>
        <Loader active size="large">
          {loadingText}
        </Loader>
      </Dimmer>
      :
      <Loader active size="large">
        {loadingText}
      </Loader>}
    </div>
  );
}
