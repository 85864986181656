import React from 'react';
import { Button, Container } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';

const LoginButton = ({ type }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/login-namaste');
  };

  if (type === 'btn') {
    return (
      <Button onClick={handleClick} primary>
        Login
      </Button>
    );
  }
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Container textAlign='center'>
        <Button onClick={handleClick} primary>
          Login
        </Button>
      </Container>
    </div>
  );
};

export default LoginButton;