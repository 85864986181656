import React, { useState, useEffect } from "react";
import {
  Dropdown,
  Form,
  Button,
  Message,
  Input,
  Icon,
} from "semantic-ui-react";
import CryptoJS from "crypto-js";
import {
  getActiveCounsellors,
  getCounseleeData,
  getCounseleeList,
  updateCasesheet,
  updateCounsellor,
} from "./api";

const ChangeCounsellor = () => {
  const [counselees, setCounselees] = useState([]);
  const [counsellors, setCounsellors] = useState([]);
  const [selectedCounselee, setSelectedCounselee] = useState("");
  const [selectedCounsellor, setSelectedCounsellor] = useState("");
  const [counseleeData, setCounseleeData] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [caseSheetPassword, setCaseSheetPassword] = useState("");
  const [newCaseSheetPassword, setNewCaseSheetPassword] = useState("");
  const [confirmNewCaseSheetPassword, setConfirmNewCaseSheetPassword] =
    useState("");
  const [caseSheetPasswordVisible, setCaseSheetPasswordVisible] =
    useState(false);
  const [newCaseSheetPasswordVisible, setNewCaseSheetPasswordVisible] =
    useState(false);
  const [
    confirmNewCaseSheetPasswordVisible,
    setConfirmNewCaseSheetPasswordVisible,
  ] = useState(false);
  const [passwordMismatchError, setPasswordMismatchError] = useState(false);
  const [oldPasswordError, setOldPasswordError] = useState(false);

  useEffect(() => {
    getCounseleeList()
      .then((response) => response.json())
      .then((data) => {
        const counseleeOptions = data.map((item) => ({
          key: item.id,
          text: item.name,
          value: item.id,
        }));
        setCounselees(counseleeOptions);
      })
      .catch((error) => console.error("Error fetching counselees:", error));

    getActiveCounsellors()
      .then((response) => response.json())
      .then((data) => {
        const counsellorOptions = data.map((item) => ({
          key: item.id,
          text: item.counsellor.name,
          value: item.id,
        }));
        setCounsellors(counsellorOptions);
      })
      .catch((error) => console.error("Error fetching counsellors:", error));
  }, []);

  const handleChangeCounselee = (e, { value }) => {
    setSelectedCounselee(value);
    getCounseleeData(value)
      .then((response) => response.json())
      .then((data) => {
        setCounseleeData(data);
      })
      .catch((error) => console.error("Error fetching counselee data:", error));
  };

  const decryptData = (encryptedData, password) => {
    try {
      const bytes = CryptoJS.AES.decrypt(encryptedData, password);
      const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
      return decryptedData;
    } catch (error) {
      return null;
    }
  };

  const encryptData = (data, password) => {
    return CryptoJS.AES.encrypt(data, password).toString();
  };

  const handleSubmit = () => {
    setPasswordMismatchError(false);
    setOldPasswordError(false);

    if (newCaseSheetPassword !== confirmNewCaseSheetPassword) {
      setPasswordMismatchError(true);
      return;
    }

    const caseSheet = counseleeData.case_sheets[0];
    const decryptedData = decryptData(caseSheet.body, caseSheetPassword);

    if (decryptedData) {
      const encryptedData = encryptData(decryptedData, newCaseSheetPassword);
      updateCasesheet(caseSheet.id, encryptedData)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Failed to update casesheet");
          }
        })
        .then(() => {
          updateCounsellor(selectedCounselee, selectedCounsellor) 
            .then((response) => {
              if (response.ok) {
                return response.json();
              } else {
                throw new Error("Failed to update counsellor");
              }
            })
            .then(() => {
              setSuccessMessage("Counsellor updated successfully");
              setSelectedCounselee("");
              setSelectedCounsellor("");
              setCounseleeData(null);
              setCaseSheetPassword("");
              setNewCaseSheetPassword("");
              setConfirmNewCaseSheetPassword("");
              setTimeout(() => setSuccessMessage(""), 3000);
            })
            .catch((error) => {
              setErrorMessage("Error updating counsellor");
              setTimeout(() => setErrorMessage(""), 3000);
            });
        })
        .catch((error) => {
          setErrorMessage("Error updating casesheet");
          setTimeout(() => setErrorMessage(""), 3000);
        });
    } else {
      setOldPasswordError(true);
    }
  };

  return (
    <div>
      <Form>
        <Form.Field required>
          <label>Select Counselee</label>
          <Dropdown
            placeholder="Select Counselee"
            fluid
            selection
            options={counselees}
            value={selectedCounselee}
            onChange={handleChangeCounselee}
          />
        </Form.Field>
        <Form.Field required>
          <label>Select Counsellor</label>
          <Dropdown
            placeholder="Select Counsellor"
            fluid
            selection
            options={counsellors}
            value={selectedCounsellor}
            onChange={(e, { value }) => setSelectedCounsellor(value)}
          />
        </Form.Field>
        <>
          <Form.Field required>
            <label>Old Case Sheet Password</label>
            <Input
              type={caseSheetPasswordVisible ? "text" : "password"}
              icon={
                <Icon
                  name={caseSheetPasswordVisible ? "eye slash" : "eye"}
                  link
                  onClick={() =>
                    setCaseSheetPasswordVisible(!caseSheetPasswordVisible)
                  }
                />
              }
              value={caseSheetPassword}
              onChange={(e) => setCaseSheetPassword(e.target.value)}
            />
          </Form.Field>
          <Form.Field required>
            <label>New Case Sheet Password</label>
            <Input
              type={newCaseSheetPasswordVisible ? "text" : "password"}
              icon={
                <Icon
                  name={newCaseSheetPasswordVisible ? "eye slash" : "eye"}
                  link
                  onClick={() =>
                    setNewCaseSheetPasswordVisible(!newCaseSheetPasswordVisible)
                  }
                />
              }
              value={newCaseSheetPassword}
              onChange={(e) => setNewCaseSheetPassword(e.target.value)}
            />
          </Form.Field>
          <Form.Field required>
            <label>Confirm New Case Sheet Password</label>
            <Input
              type={confirmNewCaseSheetPasswordVisible ? "text" : "password"}
              icon={
                <Icon
                  name={
                    confirmNewCaseSheetPasswordVisible ? "eye slash" : "eye"
                  }
                  link
                  onClick={() =>
                    setConfirmNewCaseSheetPasswordVisible(
                      !confirmNewCaseSheetPasswordVisible
                    )
                  }
                />
              }
              value={confirmNewCaseSheetPassword}
              onChange={(e) => setConfirmNewCaseSheetPassword(e.target.value)}
            />
          </Form.Field>
        </>

        <Button
          type="button"
          onClick={handleSubmit}
          disabled={
            !selectedCounselee ||
            !selectedCounsellor ||
            !caseSheetPassword ||
            !newCaseSheetPassword ||
            !confirmNewCaseSheetPassword
          }
        >
          Submit
        </Button>
      </Form>
      {successMessage && (
        <Message success header="Success" content={successMessage} />
      )}
      {errorMessage && <Message error header="Error" content={errorMessage} />}
      {passwordMismatchError && (
        <Message error header="Error" content="New passwords do not match" />
      )}
      {oldPasswordError && (
        <Message error header="Error" content="Incorrect old password" />
      )}
    </div>
  );
};

export default ChangeCounsellor;
