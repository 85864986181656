import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Form,
  Input,
  Radio,
  Select,
  TextArea,
  Divider,
  Header,
  Segment,
  Message,
} from "semantic-ui-react";
import { uploadCaseSheetData } from "./api";

const casteOptions = [
  { key: "gen", text: "Gen", value: "gen" },
  { key: "obc", text: "OBC", value: "obc" },
  { key: "sc", text: "SC", value: "sc" },
  { key: "st", text: "ST", value: "st" },
  { key: "ews", text: "EWS", value: "ews" },
];

const maritalStatusOptions = [
  { key: "single", text: "Single", value: "single" },
  { key: "married", text: "Married", value: "married" },
  { key: "other", text: "Other", value: "other" },
];

const reasonsOptions = [
  { key: "examAnxiety", text: "Exam Anxiety", value: "examAnxiety" },
  {
    key: "academicDemotivation",
    text: "Academic Demotivation",
    value: "academicDemotivation",
  },
  {
    key: "acceptanceOfCourse",
    text: "Acceptance of Course",
    value: "acceptanceOfCourse",
  },
  {
    key: "issuesWithLanguage",
    text: "Issues with Language of Studying",
    value: "issuesWithLanguage",
  },
  {
    key: "academicDisinterest",
    text: "Academic Disinterest",
    value: "academicDisinterest",
  },
  {
    key: "adjustmentIssues",
    text: "Adjustment Issues",
    value: "adjustmentIssues",
  },
  {
    key: "relationshipIssues",
    text: "Relationship Issues",
    value: "relationshipIssues",
  },
  { key: "familyIssues", text: "Family Issues", value: "familyIssues" },
  {
    key: "confidenceIssues",
    text: "Confidence Issues",
    value: "confidenceIssues",
  },
  {
    key: "issuesWithEnglish",
    text: "Issues with Spoken English",
    value: "issuesWithEnglish",
  },
  {
    key: "financialIssues",
    text: "Financial Issues",
    value: "financialIssues",
  },
  {
    key: "disciplinaryIssues",
    text: "Disciplinary Issues",
    value: "disciplinaryIssues",
  },
  {
    key: "bullyingTeasing",
    text: "Bullying and Eve Teasing",
    value: "bullyingTeasing",
  },
  { key: "ragging", text: "Ragging", value: "ragging" },
  { key: "selfHarm", text: "Self - Harm", value: "selfHarm" },
  {
    key: "abuse",
    text: "Abuse (Sexual / Physical/ Emotional)",
    value: "abuse",
  },
  { key: "substanceAbuse", text: "Substance Abuse", value: "substanceAbuse" },
  {
    key: "diversityInclusion",
    text: "Diversity & Inclusion",
    value: "diversityInclusion",
  },
  {
    key: "issuesWithFaculty",
    text: "Issues with Faculty members",
    value: "issuesWithFaculty",
  },
  { key: "issuesAtHostel", text: "Issues at Hostel", value: "issuesAtHostel" },
  { key: "others", text: "Others", value: "others" },
];

const IdentificationForm = ({ caseSheetId, counselee_id }) => {
  const [formData, setFormData] = useState({
    dateOfBirth: "",
    age: "",
    gender: "",
    reasons: [],
  });
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const handleDisclaimerCheckboxChange = () => {
    setIsCheckboxChecked(!isCheckboxChecked);
  };

  const calculateAge = (dob) => {
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };

  const handleChange = (e, { name, value }) => {
    if (name === "dateOfBirth") {
      const age = calculateAge(value);
      setFormData({ ...formData, [name]: value, age });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async () => {
    handleDisclaimerCheckboxChange();
    setLoading(true);
    setSuccessMessage(""); // Reset success message

    const jsonString = JSON.stringify(formData);
    const formBodyFinal = new FormData();
    formBodyFinal.append("counselee", counselee_id);
    formBodyFinal.append(
      "last_updated",
      new Date().toISOString().split("T")[0]
    );
    formBodyFinal.append("info", jsonString); // Add the JSON string to the info field

    const apiEndpoint = caseSheetId
      ? `https://namaste.bhu.edu.in/pwbs/api/casesheet/${caseSheetId}/`
      : "https://namaste.bhu.edu.in/pwbs/api/casesheet/";

    try {
      const data = await uploadCaseSheetData(
        apiEndpoint,
        formBodyFinal,
        caseSheetId
      );
      setSuccessMessage("Data submitted successfully!");
      setTimeout(() => {
        window.location.reload(); // Refresh the page after 2 seconds
      }, 2000);
    } catch (error) {
      console.error("Error submitting data:", error);
      setSuccessMessage("Error submitting data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleCheckboxChange = (e, { name, value }) => {
    const updatedReasons = formData.reasons.includes(value)
      ? formData.reasons.filter((reason) => reason !== value)
      : [...formData.reasons, value];

    setFormData({
      ...formData,
      [name]: updatedReasons,
    });
  };

  const handleReasonDetailChange = (index, e) => {
    const updatedReasonsDetails = [...(formData.reasonsDetails || [])];
    updatedReasonsDetails[index] = e.target.value;

    setFormData({
      ...formData,
      reasonsDetails: updatedReasonsDetails,
    });
  };

  return (
    <Segment color="green">
      <Form onSubmit={handleSubmit}>
        <Header as="h3">Identification Information</Header>
        <Header as="h5" color="red"></Header>
        <Header as="h5">
          <h4>Instructions:</h4>
          <span style={{ color: "red" }}>
          1. This form should be filled by the Counselee.
          </span>
          <br />
          2. This form is not mandatory, but it is encouraged to complete it for
          better support.
          <br />
          3 .Please fill in the information to the best of your knowledge and be
          as accurate as possible.
          <br />
          4. If you are unsure about any question, leave it blank or provide
          your best estimate.
          <br />
          5. Your responses will be kept confidential and will only be used for
          the purpose of providing appropriate guidance.
        </Header>

        <Form.Group widths="equal">
          <Form.Field
            control={Input}
            label="Date of Birth"
            placeholder="Date of Birth"
            type="date"
            name="dateOfBirth"
            value={formData.dateOfBirth}
            onChange={handleChange}
          />
          <Form.Field
            control={Input}
            label="Age"
            placeholder="Age"
            type="number"
            name="age"
            value={formData.age}
            readOnly
          />
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Field
            control={Input}
            label="Religion and Caste"
            placeholder="Religion and Caste"
            name="religionAndCaste"
            onChange={handleChange}
          />
          <Form.Field
            control={Select}
            label="Category"
            options={casteOptions}
            placeholder="Select Category"
            name="category"
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group inline>
          <label>Gender</label>
          <Form.Field
            control={Radio}
            label="Male"
            value="male"
            name="gender"
            checked={formData.gender === "male"}
            onChange={handleChange}
          />
          <Form.Field
            control={Radio}
            label="Female"
            value="female"
            name="gender"
            checked={formData.gender === "female"}
            onChange={handleChange}
          />
          <Form.Field
            control={Radio}
            label="Other"
            value="other"
            name="gender"
            checked={formData.gender === "other"}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Field
          control={TextArea}
          label="Permanent Address"
          placeholder="Permanent Address"
          name="permanentAddress"
          onChange={handleChange}
        />
        <Form.Field
          control={TextArea}
          label="Local Address/Hostel Address"
          placeholder="Local Address/Hostel Address"
          name="localAddress"
          onChange={handleChange}
        />
        <Form.Field
          control={Input}
          label="Mother Tongue and Other Spoken Languages"
          placeholder="Mother Tongue and Other Spoken Languages"
          name="languages"
          onChange={handleChange}
        />
        <Form.Field
          control={Input}
          label="Class/Course"
          placeholder="Class/Course"
          name="classOrCourse"
          onChange={handleChange}
        />
        <Form.Field
          control={Input}
          label="Institute/Faculty/College/School/Others"
          placeholder="Institute/Faculty/College/School/Others"
          name="institute"
          onChange={handleChange}
        />

        <Form.Group widths="equal">
          <Form.Field
            control={Select}
            label="Marital Status"
            options={maritalStatusOptions}
            placeholder="Select Marital Status"
            name="maritalStatus"
            onChange={handleChange}
          />
          <Form.Field
            control={Input}
            label="Spouse’s Name (if married)"
            placeholder="Spouse’s Name (if married)"
            name="spouseName"
            onChange={handleChange}
          />
        </Form.Group>

        <Divider horizontal>Parents' Information</Divider>

        <Form.Field
          control={Input}
          label="Father’s Name"
          placeholder="Father’s Name"
          name="fatherName"
          onChange={handleChange}
        />
        <Form.Field
          control={Input}
          label="Father’s Education"
          placeholder="Father’s Education"
          name="fatherEducation"
          onChange={handleChange}
        />
        <Form.Field
          control={Input}
          label="Father’s Occupation"
          placeholder="Father’s Occupation"
          name="fatherOccupation"
          onChange={handleChange}
        />

        <Form.Field
          control={Input}
          label="Mother’s Name"
          placeholder="Mother’s Name"
          name="motherName"
          onChange={handleChange}
        />
        <Form.Field
          control={Input}
          label="Mother’s Education"
          placeholder="Mother’s Education"
          name="motherEducation"
          onChange={handleChange}
        />
        <Form.Field
          control={Input}
          label="Mother’s Occupation"
          placeholder="Mother’s Occupation"
          name="motherOccupation"
          onChange={handleChange}
        />

        <Form.Field
          control={Input}
          label="Personal Income/Family Income"
          placeholder="Personal Income/Family Income"
          name="income"
          onChange={handleChange}
        />

        <Divider horizontal>Additional Information</Divider>

        <Form.Group grouped>
          <label>Reasons to avail facility at PWBS</label>
          {reasonsOptions.map((option, index) => (
            <React.Fragment key={option.key}>
              <Form.Field
                control={Checkbox}
                label={option.text}
                value={option.value}
                name="reasons"
                onChange={handleCheckboxChange}
              />
              {formData.reasons.includes(option.value) && (
                <Form.Field
                  control={Input}
                  placeholder={`Please provide details for ${option.text}`}
                  onChange={(e) => handleReasonDetailChange(index, e)}
                />
              )}
            </React.Fragment>
          ))}
        </Form.Group>

        <Form.Field
          control={TextArea}
          label="Evaluation of Self – Who Am I"
          placeholder="Describe yourself..."
          name="selfEvaluation"
          onChange={handleChange}
        />

        <Checkbox
          label="The above information is to the best of my knowledge."
          checked={isCheckboxChecked}
          onChange={handleDisclaimerCheckboxChange}
          style={{ marginBottom: "1em" }}
        />
        <Form.Field
          control={Button}
          content={loading ? "Submitting..." : "Submit"}
          primary
          loading={loading}
          disabled={!isCheckboxChecked}
        />
      </Form>

      {successMessage && (
        <Message success header="Success" content={successMessage} />
      )}
    </Segment>
  );
};

export default IdentificationForm;
