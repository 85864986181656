// withNetworkStatus.js
import React, { useState, useEffect } from 'react';
import NoInternetBanner from './NoInternetBanner';

const withNetworkStatus = (WrappedComponent) => {
  return (props) => {
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [hasRefreshed, setHasRefreshed] = useState(true);

    useEffect(() => {
      const updateOnlineStatus = () => {
        const online = navigator.onLine;
        setIsOnline(online);
        if (online && !hasRefreshed) {
          setHasRefreshed(true);
          window.location.reload();
        }
        if(!online){
            setHasRefreshed(false);
        }
      };

      window.addEventListener('online', updateOnlineStatus);
      window.addEventListener('offline', updateOnlineStatus);

      // Polling to periodically check the network status
      const interval = setInterval(() => {
        const online = navigator.onLine;
        setIsOnline(online);
        if (online && !hasRefreshed) {
          setHasRefreshed(true);
          window.location.reload();
        }
        if(!online){
            setHasRefreshed(false);
        }
      }, 5000); // Check every 5 seconds

      return () => {
        window.removeEventListener('online', updateOnlineStatus);
        window.removeEventListener('offline', updateOnlineStatus);
        clearInterval(interval);
      };
    }, [hasRefreshed]);

    return (
      <>
        {!isOnline && <NoInternetBanner />}
        <WrappedComponent {...props} />
      </>
    );
  };
};

export default withNetworkStatus;
