import React, { useState, useEffect } from "react";
import GenericTable from "./Tables/GenericTable";
import { convertDateTimestamp } from "./utils";
import { getLastLoginLogs } from "./api";
import GenericStatistics from "./Statistics/GenericStatistics";
import ScrollView from "./ScrollView";
import {
  Button,
  Checkbox,
  Container,
  Grid,
  Icon,
  Popup,
  Dropdown,
} from "semantic-ui-react";
import ShowProfileModal from "./Modals/ShowProfileModel";

const LastLoginLogs = () => {
  const [data, setData] = useState([]);
  const [loginInfo, setLoginInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [selectedMessageId, setSelectedMessageId] = useState(null);
  const [autoRefresh, setAutoRefresh] = useState(false);
  const [refreshInterval, setRefreshInterval] = useState(60000); // Default to 1 minute
  const [lastUpdateTime, setLastUpdateTime] = useState(null); // To track the last update time

  const refreshOptions = [
    { key: "1min", text: "1 Minute", value: 60000 },
    { key: "2min", text: "2 Minutes", value: 120000 },
    { key: "5min", text: "5 Minutes", value: 300000 },
  ];

  // Unique key for this component's auto-refresh setting
  const LOCAL_STORAGE_KEY = "autoRefresh_lastLoginLogs";

  useEffect(() => {
    // On component mount, load the saved auto-refresh setting from localStorage
    const savedAutoRefresh = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_KEY)
    );
    if (savedAutoRefresh !== null) {
      setAutoRefresh(savedAutoRefresh);
    }

    fetchData(currentPage);
  }, [currentPage]);

  useEffect(() => {
    let interval;
    if (autoRefresh) {
      interval = setInterval(() => {
        fetchData(currentPage);
      }, refreshInterval);
    }
    return () => clearInterval(interval);
  }, [autoRefresh, currentPage, refreshInterval]);

  const rowColors = ["#FFFFFFaa", "#EAF6F6aa"];

  const fetchData = async (page) => {
    setLoading(true);
    try {
      const response = await getLastLoginLogs(page);
      const result = await response.json();
      setData(result.results);
      setLoginInfo(result.login_info);
      setTotalPages(Math.ceil(result.count / 100)); // dividing the total count by the number of results per page
      setLastUpdateTime(new Date()); // Set the last update time
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const statsData = [
    { value: loginInfo.last_30_mins, label: "Last 30 Minutes", color: "blue" },
    { value: loginInfo.last_hour, label: "Last Hour", color: "blue" },
    { value: loginInfo.last_6_hours, label: "Last 6 Hours", color: "blue" },
    { value: loginInfo.last_24_hours, label: "Last 24 Hours", color: "blue" },
  ];

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const headerLabels = {
    person_name: "Name",
    last_login: "Login Time",
  };

  const convertTimeDate = (timestamp) => {
    return convertDateTimestamp(timestamp, false, true);
  };

  const valueTransformers = {
    last_login: convertTimeDate,
  };

  const handleRowClick = (rowData) => {
    setSelectedMessageId(rowData.person_id);
    setShowProfileModal(true);
  };

  const handleRefreshClick = () => {
    fetchData(currentPage);
  };

  const handleAutoRefreshToggle = () => {
    const newAutoRefresh = !autoRefresh;
    setAutoRefresh(newAutoRefresh);
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newAutoRefresh)); // Save to localStorage
  };

  const handleIntervalChange = (e, { value }) => {
    setRefreshInterval(value);
  };

  // Format the last update time
  const formattedLastUpdateTime = lastUpdateTime
    ? convertDateTimestamp(lastUpdateTime, true, true)
    : null;

  return (
    <>
      <Container fluid textAlign="right">
        <Button
          icon
          labelPosition="left"
          onClick={handleRefreshClick}
          loading={loading}
          disabled={loading}
          size="mini"
          style={{ marginBottom: "0.5rem" }}
          primary
        >
          <Icon name="refresh" /> Refresh
        </Button>

        <Popup
          trigger={
            <Button icon size="mini">
              <Icon name="ellipsis vertical" />
            </Button>
          }
          on="click"
          position="bottom right"
          content={
            <>
              <Checkbox
                toggle
                label="Auto Refresh"
                checked={autoRefresh}
                onChange={handleAutoRefreshToggle}
                style={{ marginBottom: "1rem" }}
              />
              <Dropdown
                selection
                options={refreshOptions}
                value={refreshInterval}
                onChange={handleIntervalChange}
                placeholder="Choose refresh interval"
              />
            </>
          }
        />
      </Container>
      
      <Container fluid textAlign="right" style={{ marginBottom: "0.2rem" }}>
        {formattedLastUpdateTime && (
          <p style={{ color: "grey", fontSize: "0.75rem" }}>
            {autoRefresh && lastUpdateTime
              ? `Auto refresh is enabled. Last updated on ${formattedLastUpdateTime}.`
              : `Last updated on ${formattedLastUpdateTime}.`}
          </p>
        )}
      </Container>

      <Grid columns={2} stackable>
        <Grid.Row stretched verticalAlign="middle">
          <Grid.Column width={3}>
            <GenericStatistics
              stats={statsData}
              columns={1}
              stackable={true}
              rowStyle={{ margin: "0.75rem 0" }}
              columnStyle={{ textAlign: "center" }}
            />
          </Grid.Column>
          <Grid.Column width={13}>
            <ScrollView maxHeight="70vh">
              <GenericTable
                headerLabels={headerLabels}
                valueTransformers={valueTransformers}
                data={data}
                loading={loading}
                rowColors={rowColors}
                totalPages={totalPages}
                activePage={currentPage} // Pass the current page as activePage
                onPageChange={handlePageChange}
                onRowClick={handleRowClick}
                selectable={true}
              />
            </ScrollView>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <ShowProfileModal
        person_id={selectedMessageId}
        open={showProfileModal}
        setOpen={setShowProfileModal}
      />
    </>
  );
};

export default LastLoginLogs;
