import React, { useState } from "react";
import { Segment, Header, Grid } from "semantic-ui-react";
import { telePhoneSearch } from "../Components/api";
import PhoneSkin from "../Components/PhoneSkin";
import CustomForm from "../Components/CustomForm";
import LoaderComponent from "../Components/LoaderComponent";
import { cropTitle } from "../Components/utils";
import { MailLink } from "../Components/MailLink";
import GenericTable from "../Components/Tables/GenericTable";
import ScrollView from "../Components/ScrollView";
import NavbarWrapper from "../Components/Template/NavbarWrapper";

export default function TelePhoneSearch() {
  const [formState, setFormState] = useState({
    name: { label: "Name", value: "", type: "text" },
    department: { label: "Department", value: "", type: "text" },
    designation: { label: "Designation", value: "", type: "text" },
    phone_number: { label: "Phone Number", value: "", type: "text" },
  });

  const [isRequestMade, setLoadingRequest] = useState(false);
  const [isResponseReceived, showResponse] = useState(false);
  const [results, setResults] = useState({ data: [], msg: "" });

  const headerLabels = {
    name: "Name",
    department: "Department",
    designation: "Designation",
    phone_number: "Phone Number",
    email: "Email",
  };

  const returnMailLink = (data) => {
    return <MailLink data={data} />;
  };

  const valueTransformers = {
    name: cropTitle,
    department: cropTitle,
    designation: cropTitle,
    email: returnMailLink,
  };

  const handleSubmit = () => {
    showResponse(false);
    const { name, department, designation, phone_number } = formState;

    if (
      !name.value &&
      !department.value &&
      !designation.value &&
      !phone_number.value
    ) {
      setResults({ data: [], msg: "Enter some data in the form" });
      showResponse(true);
      return;
    }

    setLoadingRequest(true);
    telePhoneSearch(
      name.value,
      department.value,
      designation.value,
      phone_number.value
    )
      .then((res) => res.json())
      .then((data) => {
        setLoadingRequest(false);
        if (data && data.length > 0) {
          setResults({ data, msg: `${data.length} faculties found` });
        } else {
          setResults({ data, msg: "No faculties found" });
        }
        showResponse(true);
      })
      .catch((err) => {
        console.error("Failed to search for faculty(s): ", err);
        setLoadingRequest(false);
        setResults({
          data: [],
          msg: "Something went wrong while fetching faculty data",
        });
        showResponse(true);
      });
  };

  return (
    <>
      <NavbarWrapper containerFluid={true} paddingLeft="2vw" paddingRight="2vw">
        {isRequestMade ? (
          <LoaderComponent loadingText="Loading results..." />
        ) : (
          <Grid stackable columns={2}>
            <Grid.Column width={4}>
              <PhoneSkin>
                <Segment basic padded>
                  <Header
                    as={"h2"}
                    style={{ fontFamily: "Times New Roman, Georgia, serif" }}
                  >
                    Telephone Directory
                  </Header>
                  <CustomForm
                    formState={formState}
                    setFormState={setFormState}
                    onSubmit={handleSubmit}
                  />
                </Segment>
              </PhoneSkin>
            </Grid.Column>
            <Grid.Column width={12}>
              {isResponseReceived && (
                <>
                  {/* {console.log(results)}
                    <CustomTable
                      results={results}
                      onRowClick={handleRowClick} */}
                  <ScrollView maxHeight="80vh">
                    <GenericTable
                      headerLabels={headerLabels}
                      data={results.data}
                      valueTransformers={valueTransformers}
                      errorMessage="Please use different search criteria"
                    />
                  </ScrollView>
                </>
              )}
            </Grid.Column>
          </Grid>
        )}
      </NavbarWrapper>
    </>
  );
}
