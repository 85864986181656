import React, { useState } from "react";
import { Divider, Icon, Label, Modal, Grid, Input } from "semantic-ui-react";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { Done } from "@mui/icons-material";
import { sortNames } from "../utils";
import ShowProfileModal from "./ShowProfileModel";
import { isUserAdmin } from "../api";

export default function NotificationStatsModal({
  isModalOpen,
  closeModal,
  selectedMessage,
  showStats = true,
}) {
  const [searchQuery, setSearchQuery] = useState("");
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [selectedMessageId, setSelectedMessageId] = useState(null);

  // Filter the names based on the search query
  const filteredNames = sortNames(
    selectedMessage?.notification_status?.sent_to,
    selectedMessage
  ).filter((person) =>
    person.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Handler for label click
  const handleLabelClick = (personId) => {
    const isLAdmin = isUserAdmin(localStorage.getItem("person_id"));
    isLAdmin && setSelectedMessageId(personId);
    isLAdmin && setShowProfileModal(true);
  };

  const isAdmin = isUserAdmin(localStorage.getItem("person_id"));

  return (
    <>
      <Modal
        open={isModalOpen}
        onClose={closeModal}
        closeIcon
        style={{ minHeight: "75vh" }}
      >
        <Modal.Header>Message Statistics</Modal.Header>
        <Modal.Content>
          {showStats && (
            <>
              <Grid columns={3}>
                <Grid.Row>
                  <Grid.Column>
                    {metaDataofMessage(
                      "Sent to",
                      "grey",
                      selectedMessage?.notification_status?.sent_to.length,
                      <Done />
                    )}
                  </Grid.Column>
                  <Grid.Column>
                    {metaDataofMessage(
                      "Delivered to",
                      "grey",
                      selectedMessage?.notification_status?.delivered_to.length,
                      <DoneAllIcon />
                    )}
                  </Grid.Column>
                  <Grid.Column>
                    {metaDataofMessage(
                      "Read by",
                      "blue",
                      selectedMessage?.notification_status?.read_by.length,
                      <DoneAllIcon style={{ color: "blue" }} />
                    )}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Divider />
            </>
          )}

          {/* Search Input */}
          <Input
            icon="search"
            placeholder="Search by name..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            fluid
            style={{ marginBottom: "1.5rem" }}
          />

          <Grid
            columns={3}
            style={{
              maxHeight: "50vh",
              overflowY: "auto",
              paddingTop: "1.5rem",
              paddingBottom: "1.5rem",
            }}
          >
            {filteredNames.map((person) => (
              <Grid.Column
                key={person.id}
                style={{
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  paddingRight: "5px",
                }}
              >
                <Label
                  color=""
                  basic
                  size="large"
                  image
                  onClick={() => handleLabelClick(person.id)} // Add onClick handler
                  style={isAdmin ? { cursor: "pointer" }: {}} // Optional: change cursor to pointer on hover
                >
                  <Icon name="user" />
                  {person.name}
                  <Label.Detail style={{ backgroundColor: "#f7f7f7" }}>
                    {selectedMessage.notification_status.delivered_to.some(
                      (del) => del.id === person.id
                    ) ? (
                      selectedMessage.notification_status.read_by.some(
                        (read) => read.id === person.id
                      ) ? (
                        <DoneAllIcon
                          style={{ color: "blue", width: "1.2rem" }}
                        />
                      ) : (
                        <DoneAllIcon style={{ width: "1.2rem" }} />
                      )
                    ) : (
                      <Done style={{ width: "1.2rem" }} />
                    )}
                  </Label.Detail>
                </Label>
              </Grid.Column>
            ))}
          </Grid>
        </Modal.Content>
      </Modal>
      {isAdmin && (
        <ShowProfileModal
          person_id={selectedMessageId}
          open={showProfileModal}
          setOpen={setShowProfileModal}
        />
      )}
    </>
  );

  function metaDataofMessage(title, color, value, icon) {
    return (
      <Label size="big" basic image>
        {title}{" "}
        <Label.Detail style={{ backgroundColor: "#f2f2f2" }}>
          {icon}
        </Label.Detail>
        <Label floating color={color}>
          {value}
        </Label>
      </Label>
    );
  }
}
