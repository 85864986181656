  import React, { useState } from "react";
  import { Icon, Table } from "semantic-ui-react";
  import { formatDate, cropTitle, cropGp } from "../utils";
  import { markNotificationAsRead } from "../api";

  export default function MessageTable(props) {
    const [isRead, setIsRead] = useState(props.item.is_read);

    // Determine whether to apply the textBold class
    const textBoldClass = (!isRead && props.from === "Inbox") ? "blackLink textBold" : "blackLink";

    return (
      <Table.Row
        key={props.item.note_id}
        onClick={() => {
          props.selectMessage(props.item);
          if (!isRead) {
            setIsRead(true);
            console.log("from messageTable",props.person_id);
            markNotificationAsRead(props.person_id, props.item.note_id);
          }
        }}
        style={{
          backgroundColor: props.cardBgColor ? props.cardBgColor : "",
          cursor: "pointer",
        }}
      >
        {props.from === "Inbox" && (
          <Table.Cell style={{paddingTop:"0.2rem", paddingBottom:"0.2rem"}}>
            {isRead ? (
              <Icon name="envelope open" color="grey" />
            ) : (
              <Icon name="envelope" color="blue" />
            )}
          </Table.Cell>
        )}
        <Table.Cell style={{paddingTop:"0.2rem", paddingBottom:"0.2rem"}}>
          <div className={textBoldClass}>
            { props.from === "Inbox" ? cropGp(props.item.group.name, false):cropGp(props.item.groups, true)}
          </div>
        </Table.Cell>
      {props.item.sender_name && <Table.Cell style={{paddingTop:"0.2rem", paddingBottom:"0.2rem"}}>
        <div className={textBoldClass}>
          {cropGp(props.item.sender_name)}
        </div>
        </Table.Cell>}
        <Table.Cell style={{paddingTop:"0.2rem", paddingBottom:"0.2rem"}}>
          <div className={textBoldClass}>
            {props.from === "Inbox" ? cropTitle(props.item.title): cropTitle(props.item.title, 70)} {props.item.attachment && <Icon name="paperclip"></Icon>}
          </div>
        </Table.Cell>
        <Table.Cell textAlign="right" style={{paddingTop:"0.2rem", paddingBottom:"0.2rem"}}>
          <div className={textBoldClass}>
            {formatDate(props.item.time_of_sending)}
          </div>
        </Table.Cell>
      </Table.Row>
    );
  }
