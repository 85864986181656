import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Icon,
  Input,
  Message,
  Loader,
  Label,
  // Popup
} from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { fetchCounseleesForCounsellor } from "./api";

const CounseleesTable = () => {
  const [counselees, setCounselees] = useState([]);
  const [filteredCounselees, setFilteredCounselees] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const psy_counsellor_id = localStorage.getItem("psy_counsellor_id");

    const fetchData = async () => {
      try {
        const data = await fetchCounseleesForCounsellor(psy_counsellor_id);
        // Sort data in descending order based on code
        const sortedData = data.sort((a, b) => b.code.localeCompare(a.code));
        setCounselees(sortedData);
        setFilteredCounselees(sortedData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching counselees:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const filteredData = counselees.filter((counselee) => {
      return counselee.code.toLowerCase().includes(searchQuery.toLowerCase());
    });
    setFilteredCounselees(filteredData);
  }, [searchQuery, counselees]);

  const handleEdit = (id, counselee) => {
    navigate(`/psy/counseling/edit/${id}`, { state: { counselee } });
  };

  // const handleView = (id) => {
  //   navigate(`/psy/counseling/view/${id}`);
  // };

  const handleProfile = (id) => {
    navigate(`/psy/counseling/profile/${id}`);
  };

  return (
    <div>
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}
      >
        <Input
          icon="search"
          placeholder="Search by Code"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{ flex: 1, marginRight: "10px" }}
        />
      </div>
      {loading ? (
        <Loader active inline="centered" />
      ) : (
        <>
          {filteredCounselees.length > 0 ? (
            <div style={{ maxHeight: "60vh", overflowY: "auto" }}>
              <Table compact>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Code</Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">
                      Casesheet
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {filteredCounselees.map((counselee) => (
                    <Table.Row key={counselee.id}>
                      <Table.Cell>
                        {counselee.code}
                        {counselee.casesheet_close && (
                          <Label tag style={{ marginLeft: "2rem" }} color="red">
                            Closed
                          </Label>
                        )}
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        <Button
                          disabled={counselee.casesheet_close}
                          onClick={() => handleEdit(counselee.id, counselee)}
                          color="blue"
                        >
                          <Icon name="edit" />
                          Edit
                        </Button>
                        <Button onClick={() => handleProfile(counselee.id)}>
                          <Icon name="user" />
                          View
                        </Button>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </div>
          ) : (
            <Message warning content="No data available" />
          )}
        </>
      )}
    </div>
  );
};

export default CounseleesTable;
