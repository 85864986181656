import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import LoaderComponent from "../Components/LoaderComponent";
import {
  Container,
  Segment,
  Header,
  Divider,
  Icon,
  Image,
} from "semantic-ui-react";
import { useAuth } from "../Context/AuthContext";
import Navbar from "../Components/Navbar";
import SearchModal from "../Components/Modals/SearchModal";


export default function PersonDetails() {
  const { state } = useLocation();
  const { personid } = useParams();
  const [isLoading, setLoading] = useState(false);
  const { logout } = useAuth();
  const [isModalOpen, setModalOpen] = useState(false);
  const closeModal = () => setModalOpen(false);
  const defaultImage = require('../Resources/person.png');


  const renderFacultyDetails = () => {
    if (state && state.facultyDetails) {
      const faculty = state.facultyDetails;
      return (
        <Segment basic>
          <div key={faculty.id}>
            <Header as="h2">{faculty.faculty_name}</Header>
            {console.log(faculty)}
            <Image
              src={
                faculty.profile_photo
                  ? "https://namaste.bhu.edu.in" + faculty.profile_photo
                  : defaultImage
              }
              // wrapped
              size="medium"
            // style={{
            //   border: "2px solid green",
            // }}
            // ui={false}
            />
            <p>
              <strong>Department:</strong> {faculty.department_name}
            </p>
            <p>
              <strong>Designation:</strong> {faculty.designation}
            </p>
            <p>
              <strong>Email:</strong> {faculty.email}
            </p>
            <p>
              <strong>Phone:</strong> {faculty.phone_no}
            </p>
            <p>
              <strong>Profile Link:</strong>{" "}
              <a href={faculty.profile_link} target="_blank" rel="noopener noreferrer">
                {faculty.profile_link}
              </a>
            </p>
            <Divider />
          </div>
        </Segment>
      );
    } else {
      return <h3>Faculty data not found</h3>;
    }
  };

  if (isLoading) {
    return <LoaderComponent loadingText="Loading institute details ... " />;
  }

  return (
    <>
      <Navbar onLogout={logout} setModalOpen2={setModalOpen} />
      <SearchModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <Container>
        <div style={{ paddingTop: "90px" }}>
          <Segment basic padded>
            <Header as="h1" className="heading-font">
              <Icon name="university" />
              <Header.Content>
                Person details
              </Header.Content>
            </Header>
            <Divider />
            {renderFacultyDetails()}
          </Segment>
        </div>
      </Container>
    </>
  );
}
