import {Table } from "semantic-ui-react";
import { formatDate, cropTitle } from "../utils";
function WardenMessageTable(props) {
  return (
    <Table.Row 
      key={props.item.id}
      onClick={() => {
        props.selectMessage(props.item.id);
        props.setSetelectedMsgUsername(props.item.sender_name); 
        props.setAnalysisView(false);
      }}
      style={{
        backgroundColor: props.cardBgColor ? props.cardBgColor : "",
        cursor: "pointer",
      }} 
    >
      <Table.Cell style={{paddingTop:"0.2rem", paddingBottom:"0.2rem"}}>
        <div className={"blackLink"}>
          {formatDate(props.item.time_of_sending)}
        </div>
      </Table.Cell>
      {props.item.sender_name && <Table.Cell style={{paddingTop:"0.2rem", paddingBottom:"0.2rem"}}>
        <div className={"blackLink"}>
          {props.item.sender_name}
        </div>
      </Table.Cell>}
      <Table.Cell style={{paddingTop:"0.2rem", paddingBottom:"0.2rem"}}>
        <div className={"blackLink"}>
          {props.item.hostel_name}
        </div>
      </Table.Cell>
      <Table.Cell style={{paddingTop:"0.2rem", paddingBottom:"0.2rem"}}>
        <div className={"blackLink"}>
          {props.item.body ? cropTitle(props.item.body): props.item.title ? cropTitle(props.item.title): "-"}
          {/* {cropTitle(props.item.title)} */}
        </div>
      </Table.Cell>
      {/* <Table.Cell textAlign="right" style={{paddingTop:"0.2rem", paddingBottom:"0.2rem"}}>
        <div className={"blackLink"}>
          {formatDate(props.item.report_date)}
        </div>
      </Table.Cell> */}
    </Table.Row>
  );
}

export default WardenMessageTable;
