import React, { useState, useEffect } from "react";
import GenericTable from "./Tables/GenericTable";
import { convertDateTimestamp, cropTitle } from "./utils";
import { getAllNotificationList, getNotificationDetails } from "./api";
import GenericStatistics from "./Statistics/GenericStatistics";
import ScrollView from "./ScrollView";
import { Button, Divider, Grid, Segment, Transition } from "semantic-ui-react";
import GenericMessageView from "./Cards/GenericMessageView";
import NotificationStatsModal from "./Modals/NotificationStatsModal";

const AdminNotes = () => {
  const [data, setData] = useState([]);
  const [noteCount, setNoteCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [messageLoading, setMessageLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [notesVisibility, setNotesVisibility] = useState(true);
  const [messageVisibility, setMessageVisibility] = useState(false);
  const [noteDeatils, setNoteDetails] = useState({});
  const [notificationStatsModalOpen, setNotificationStatsModalOpen] =
    useState(false);

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const rowColors = ["#FFFFFFaa", "#D6EEEEaa"];

  const fetchData = async (page) => {
    setLoading(true);
    try {
      const response = await getAllNotificationList(page);
      const result = await response.json();
      // console.log(result);
      setData(result.results);
      setNoteCount(result.count);
      setTotalPages(Math.ceil(result.count / 100)); // dividing the total count by the number of results per page
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const statsData = [
    { value: noteCount, label: "Total Notifications", color: "blue" },
  ];

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const toggleNoteVisibility = () => {
    setNotesVisibility(!notesVisibility);
  };

  const toggleMessageVisibility = () => {
    setMessageVisibility(!messageVisibility);
  };

  const toggleNotificationStatsModal = () => {
    setNotificationStatsModalOpen(!notificationStatsModalOpen);
  };

  const handleRowClick = async (rowData) => {
    setMessageLoading(true);
    const noteId = rowData.note_id;
    const response = await getNotificationDetails(noteId);
    const result = await response.json();
    console.log(result.notification_status);

    result && setMessageLoading(false);
    setNoteDetails(result);
    toggleNoteVisibility();
  };

  const headerLabels = {
    note_id: "note_id",
    group: "Group",
    title: "Title",
    sender_name: "Sender",
    time_of_sending: "Time of Sending",
  };

  const convertTimeDate = (timestamp) => {
    return convertDateTimestamp(timestamp, false, true);
  };

  const valueTransformers = {
    title: cropTitle,
    time_of_sending: convertTimeDate,
  };

  return (
    <>
      <Transition
        visible={notesVisibility}
        animation={"fade right"}
        duration={200}
        onHide={() => toggleMessageVisibility()}
      >
        <Grid columns={2} stackable style={{ minHeight: "75vh" }}>
          <Grid.Row stretched verticalAlign="middle">
            <Grid.Column width={2}>
              <GenericStatistics
                stats={statsData}
                columns={1}
                stackable={true}
                rowStyle={{ margin: "0.75rem 0" }}
                columnStyle={{ textAlign: "center" }}
              />
            </Grid.Column>
            <Grid.Column width={14}>
              <div key={"visibleTable"}>
                <ScrollView maxHeight="70vh">
                  <GenericTable
                    headerLabels={headerLabels}
                    valueTransformers={valueTransformers}
                    data={data}
                    loading={loading}
                    rowColors={rowColors}
                    totalPages={totalPages}
                    activePage={currentPage}
                    onPageChange={handlePageChange}
                    onRowClick={handleRowClick} // Pass the onRowClick function
                    hiddenColumns={["note_id"]} // Hide note_id column
                    selectable={true}
                  />
                </ScrollView>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Transition>
      <Transition
        visible={messageVisibility}
        animation={"fade left"}
        duration={200}
        onHide={() => toggleNoteVisibility()}
      >
        <div hiddenTable>
          <Grid>
            <Grid.Row>
              <Grid.Column width={8} textAlign="left">
                <Button
                  icon="arrow left"
                  content="Back"
                  onClick={toggleMessageVisibility}
                />
              </Grid.Column>
              <Grid.Column width={8} textAlign="right">
                <Button
                  primary
                  icon="sticky note outline"
                  content="Message Statistics"
                  onClick={toggleNotificationStatsModal}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <GenericMessageView
            title={noteDeatils?.title}
            groups={noteDeatils?.groups}
            body={noteDeatils?.body}
            attachment={noteDeatils?.attachment}
            sender_name={noteDeatils?.sender_name}
            sender_designation={noteDeatils?.sender_designation}
            time_of_sending={noteDeatils?.time_of_sending}
            loading={messageLoading}
            deliveredToCount={
              noteDeatils?.notification_status?.delivered_to.length
            }
            readByCount={noteDeatils?.notification_status?.read_by.length}
            sendToCount={noteDeatils?.notification_status?.sent_to.length}
          />
        </div>
      </Transition>
      <NotificationStatsModal
        selectedMessage={noteDeatils}
        isModalOpen={notificationStatsModalOpen}
        closeModal={toggleNotificationStatsModal}
        showStats={true}
      />
    </>
  );
};

export default AdminNotes;
