// NoInternetBanner.js
import React from "react";
import "./NoInternetBanner.css";
import { SignalWifiConnectedNoInternet4 } from "@mui/icons-material";

const NoInternetBanner = () => {
  return (
    <div className="no-internet-overlay">
      <div className="no-internet-content">
        <SignalWifiConnectedNoInternet4 style={{ fontSize: 100, color: 'white' }} />
        <h2>No Internet Connection</h2>
      </div>
    </div>
  );
};

export default NoInternetBanner;
